import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import Carousel from "react-multi-carousel";
import CarouselRef from "react-multi-carousel";
import steelBazaarLogo from "../../../assets/images/steelBazaarLogo.svg";
import onboarding1 from '../../../assets/images/onboarding1.svg'
import onboarding2 from '../../../assets/images/onboarding2.svg'
import onboarding3 from '../../../assets/images/onboarding3.svg'

const useStyles = createUseStyles((theme: any) => ({
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        },
        "& .react-multi-carousel-list": {
            borderRadius: "8px",
        },
        maxWidth: "344px",
    },
    carouselBackground: {
        background: theme.palette.background.neutral.neutral1000,
        maxWidth: "332px",
        minHeight: "600px",
        padding: "70px 38px 140px 38px",
        borderRadius: "16px"
    },
    card:{
        height: "200px"
    },
    textWeb: {
        color: theme.palette.text.primary.primary1000,
        lineHeight: '25px',
        marginTop: '28px',
    },
    textMobile: {
        display: "none"
    },
    "@media (max-width: 767px)": {
        carouselContainer: {
            "& .react-multiple-carousel__arrow--right": {
                right: "0 !important"
            },
            "& .react-multiple-carousel__arrow--left": {
                left: "0 !important"
            },
            "& .react-multi-carousel-list": {
                borderRadius: "8px",
            },
            maxWidth: "250px",
        },
        carouselBackground: {
            maxWidth: "100%",
            minHeight: "300.28px",
            padding: "10px 20px 20px 20px",
            borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`,
            borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`,
            borderRadius: "0px"
        },
        card:{
            height: "193px"
        },
        textWeb: {
            display: "none",
        },
        textMobile: {
            display: "grid",
            color: theme.palette.text.primary.primary1000,
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: '20px',
            marginTop: '20px',
            "& span":{
                color: "black",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: '16px',
                marginTop: '5px',
            }
        },
    }
}));

const carouselItems = [
    {
        title: 'Secure Payments',
        image: onboarding1
    },
    {
        title: 'Transparent Process',
        image: onboarding2
    },
    {
        title: '100% Original Products',
        image: onboarding3
    },
];

const OnBoardingCarouselTemplate: React.FC = () => {
    const classes = useStyles()
    const carouselRef = useRef<CarouselRef>(null);
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        tablet: {
            breakpoint: { max: 1280, min: 980 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        mobile: {
            breakpoint: { max: 980, min: 670 },
            items: 1,
            partialVisibilityGutter: 10,
        },
        "sm-mobile": {
            breakpoint: { max: 670, min: 0 },
            items: 1,
        },
    };

    return (
        <div className={`${classes.carouselBackground} items-center`}>
            <div className=''>
                <div className={`${classes.carouselContainer} rounded-lg mx-auto `}>
                    <Carousel
                        responsive={responsive}
                        infinite={true}
                        partialVisible={false}
                        ref={carouselRef}
                        removeArrowOnDeviceType={["desktop", "tablet", "mobile", "sm-mobile"]}
                        autoPlay={true}
                        autoPlaySpeed={2000}
                        slidesToSlide={1}
                        showDots={true}
                    >
                        {carouselItems.map((item) => (
                            <div className={classes.card}>
                                <img className='flex justify-center' src={item.image} alt="" />
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className={`${classes.textWeb} text-center font-semibold text-lg `}>Welcome to SteelBazaar: <br /> Unlock Big Savings in Steel  <br />  Procurement with Smart  <br /> Digital Solutions, Log in Now</div>
                <div className={`${classes.textMobile} text-center`}>Welcome to SteelBazaar<span>Unlock Big Savings in Steel Procurement with Smart Digital Solutions, Log in Now</span></div>

            </div>
        </div>
    );
};

export default OnBoardingCarouselTemplate;