import React from "react";
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, SelectProps as MuiSelectProps } from "@mui/material";
import { createUseStyles } from "react-jss";

interface Option {
    value: string | number;
    label: string;
}

interface SelectProps extends Omit<MuiSelectProps, "label"> {
    options: Option[];
    label: string;
}

const useStyles = createUseStyles((theme: any) => ({
    input: {
        border: "1px solid",
        borderColor: theme.palette.border.neutral.neutral200,
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: "12px",
        color: theme.palette.text.neutral.neutral800,
        "&:hover": {
            borderColor: theme.palette.border.primary.primary300,
            background: "white",
            borderWidth: "1px",
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: theme.palette.border.primary.primary300,
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `${theme.palette.border.primary.primary300} !important`,
            borderWidth: 2,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `${theme.palette.border.primary.primary300} !important`,
        },
        "& .MuiInputBase-input": {
            padding: "13px 12px !important",
        },
    },
    "@media (max-width: 767px)": {
        input: {
            border: "1px solid",
            borderColor: theme.palette.border.neutral.neutral200,
            fontSize: "14px",
            fontWeight: 400,
            borderRadius: "6px",
            color: theme.palette.text.neutral.neutral800,
            "&:hover": {
                borderColor: theme.palette.border.primary.primary300,
                borderWidth: 1,
                outline: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.border.primary.primary300,
            },
            "&:focus-within": {
                outline: "none",
                borderColor: `${theme.palette.border.primary.primary300} !important`,
                borderWidth: "2px !important",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: `${theme.palette.border.primary.primary300} !important`,
            },
            "& .MuiInputBase-input": {
                padding: "12px 12px !important",
            },
        },
    },
}));

const Select: React.FC<SelectProps> = ({ options, label, ...props }) => {
    const classes = useStyles()
    return (
        <FormControl fullWidth className={classes.input} >
            <InputLabel id={`dropdown-label-${label}`}>{label}</InputLabel>
            <MuiSelect id={`select-${label}`} label={label} {...props}>
                {options.map(option => (
                    <MenuItem className="capitalize" key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
};

export default Select;