import React, { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useCatalogueService } from '../../../services/useCatalogueService';
import { CLASS_STATES } from "../../../utils/types";
import { WishlistContext } from '../../../contexts/WishlistContext';
import CatalogueGridItem from '../../template/CatalogueList/CatalogueGridItem.template';
import { Catalogue } from '../../template/CatalogueList/CatalogueList.template';
import Button from '../../atoms/Button/Button';
import { Payload } from '../../pages/Wishlist/Wishlist.page';
import noproduct from '../../../assets/icons/myProductsEmptyState.svg'
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    header: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
        padding: "16px 24px"
    },
    mainHeading: {
        color: theme.palette.text.neutral.neutral970
    },
    description: {
        color: theme.palette.text.neutral.neutral600
    },
    title: {
        color: theme.palette.text.neutral.neutral800
    },
    subContainer: {
        padding: '16px 24px 24px 24px'
    }
}));

const MyProductsTemplate: React.FC = () => {

    const classes = useStyles();
    const catalogueService = useCatalogueService();
    const navigate = useNavigate(); 
    const wishlistContextData = useContext(WishlistContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [catalogues, setCataloguesTo] = useState<Catalogue[]>([]);
    const [secondaryCatalogues, setSecondaryCataloguesTo] = useState<Catalogue[]>([]);
    const [combinedCatalogues, setCombinedCatalogues] = useState<Catalogue[]>([]);

    const fetchCatalogues = async () => {
        try {
            if (wishlistContextData?.favourites?.catalogueIds.length === 0) {
                setCataloguesTo([]);
                return;
            }
            setLoading(true);
            const payload: Payload = {
                catalogueIds: wishlistContextData?.favourites?.catalogueIds,
                classes: [CLASS_STATES.STANDARD, CLASS_STATES.NONSTANDARD]
            };
            const cataloguesResponse = await catalogueService.getAllCatalogues(
                {
                    page: 0,
                    size: 100,
                    sort: "createdAt,desc",
                },
                payload
            );
            if (cataloguesResponse?.data?.data?.content?.length) {
                const { content } = cataloguesResponse?.data?.data;
                setCataloguesTo(content);
                setLoading(false);
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setCataloguesTo([]);
            setLoading(false);
        }
    };

    const fetchSecondaryCatalogues = async () => {
        try {
            if (wishlistContextData?.favourites?.secondaryCatalogueIds.length === 0) {
                setSecondaryCataloguesTo([]);
                return;
            }
            setLoading(true);
            const payload: Payload = {
                secondaryCatalogueIds: wishlistContextData?.favourites?.secondaryCatalogueIds,
                classes: [CLASS_STATES.SECONDARY]
            };
            const cataloguesResponse = await catalogueService.getAllCatalogues(
                {
                    page: 0,
                    size: 100,
                    sort: "createdAt,desc",
                },
                payload
            );
            if (cataloguesResponse?.data?.data?.content?.length) {
                const { content } = cataloguesResponse?.data?.data;
                setSecondaryCataloguesTo(content);
                setLoading(false);
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setSecondaryCataloguesTo([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (wishlistContextData?.favourites?.catalogueIds)
            fetchCatalogues();
    }, [wishlistContextData?.favourites?.catalogueIds]);

    useEffect(() => {
        if (wishlistContextData?.favourites?.secondaryCatalogueIds)
            fetchSecondaryCatalogues();
    }, [wishlistContextData?.favourites?.secondaryCatalogueIds]);

    useEffect(() => {
        if (catalogues.length >= 0 || secondaryCatalogues.length >= 0) {
            setCombinedCatalogues([...catalogues, ...secondaryCatalogues]);
            setLoading(false)
        }
    }, [catalogues, secondaryCatalogues]);

    return (
        <div className={`rounded-2xl ${classes.container}`}>
            <div className={`flex justify-between ${classes.header}`}>
                <span className={`font-medium ${classes.mainHeading}`}>My Products</span>
                {combinedCatalogues.length > 0 && (
                    <Button size='small' variant="secondaryText" label="View Details"  onClick={() => navigate("/wishlist")}/>
                )}            </div>
            <div className={classes.subContainer}>
                {combinedCatalogues.length > 0 ? (
                    <div className="overflow-x-auto overflow-hidden w-[1040px]">
                        <div className="flex gap-x-6 w-max">
                            {combinedCatalogues.map((item: any, index) => (
                                <div key={item?.id || index}  className='w-[240px]'>
                                    <CatalogueGridItem data={item} label="wishlist" />
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col items-center m-0 ">
                        <img src={noproduct} alt="popup-icon" />
                        <span className={`text-lg text-center font-semibold ${classes.title}`}>
                            No products to show
                        </span>
                        <span className={`text-lg text-center ${classes.description}`}>
                            Your wishlist items will be displayed here once added
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default MyProductsTemplate;