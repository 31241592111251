import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import { STATUS } from '../../../utils/constant';
import { IPagination } from '../../../utils/types';
import { useBuyerService } from '../../../services/useBuyerService';
import EnquiryItemTemplate from './EnquiryItem.template';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    header: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
        padding: "16px 24px"
    },
    mainHeading: {
        color: theme.palette.text.neutral.neutral970
    },
    tabTitle: {
        color: theme.palette.text.neutral.neutral500,
    },
    activeTabTitle: {
        color: theme.palette.text.primary.primary400,
        borderBottom: `2px solid ${theme.palette.text.primary.primary400}`
    },
    subContainer: {
        padding: '16px 24px 24px 24px'
    }
}));

export interface Enquiry {
	enquiryId: number;
	enquiryStatus: string;
	createdDate: string;
	totalPrice: number;
	productsDetails: string[];
    quantity: number
}

const MyEnquiryTemplate: React.FC = () => {

    const classes = useStyles();
    const buyerService = useBuyerService();
    const navigate = useNavigate(); 

    const [enquiry, setEnquiry] = useState<Enquiry[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [activeTabIndex, setActiveTabIndexTo] = useState<number>(0);

    const orderStatusTabs = [
        {
            label: "All Enquiry",
            status: ""
        },
        {
            label: "Ongoing",
            status: STATUS.ONGOING
        },
        {
            label: "Accepted",
            status: STATUS.ACCEPTED
        },
        {
            label: "Rejected",
            status: STATUS.REJECTED
        },
    ];

    const refreshEnquiry = async () => {
        try {
            setLoading(true);
            const params = {
                enquiryStatus: orderStatusTabs[activeTabIndex].status,
            };
            const ordersResponse = await buyerService.getEnquiryDetailsByStatus(params);
            if (ordersResponse?.data?.data) {
                setEnquiry(ordersResponse.data.data);
                setLoading(false);
            } else {
                throw new Error("Enquiries fetch failed");
            }
        } catch (error) {
            setEnquiry([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshEnquiry();
    }, [activeTabIndex]);

    const handleTabChange = (currentTabIndex: number) => () => {
        setActiveTabIndexTo(currentTabIndex);
    }

    const handleOnClick = () => {
        navigate("/dashboard/lead");
    }

    return (
        <div className={`rounded-2xl ${classes.container}`}>
            <div className={`flex justify-between ${classes.header}`}>
                <span className={`font-medium ${classes.mainHeading}`}>My Enquiry</span>
                <Button size='small' variant="secondaryText" label={"View Details"} onClick={() => navigate("/dashboard/lead")}/>
            </div>
            <div className={`${classes.subContainer} flex flex-col`}>
                <div className="flex gap-x-5 border-b-2 w-fit">
                    {orderStatusTabs.map((orderStatus, index) => (
                        <span
                            key={orderStatus.status}
                            onClick={handleTabChange(index)}
                            className={`cursor-pointer ${activeTabIndex === index ? `${classes.activeTabTitle} pb-3` : `${classes.tabTitle}`}`} >
                            {orderStatus.label}
                        </span>
                    ))}
                </div>
                <div className='overflow-x-auto overflow-hidden w-[1040px] pt-4'>
                    <div className='flex gap-x-6 w-max'>
                        {
                            enquiry.map((enquiry: Enquiry) => (
                                <EnquiryItemTemplate
                                    key={enquiry.enquiryId}
                                    enquiry={enquiry}
                                    title={'ENQ'}
                                    onClick={handleOnClick}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MyEnquiryTemplate;