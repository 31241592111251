import { Button } from '@mui/joy';
import React from 'react';
import { createUseStyles } from 'react-jss';
import contactBanner from '../../../assets/images/contactUsBanner.png';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import contactMap from '../../../assets/images/contactMap.png';
import locationPin from '../../../assets/icons/location pin.svg';
import mobile from '../../../assets/icons/mobile.svg';
import mailIcon from '../../../assets/icons/mailIcon.svg';
import instaIcon from '../../../assets/icons/instaIcon1.svg';
import fbIcon from '../../../assets/icons/fbIcon1.svg';
import linkedInIcon from '../../../assets/icons/linkedInIcon1.svg';
import xIcon from '../../../assets/icons/xIcon1.svg';
import whatsappIcon from '../../../assets/icons/whatsappIcon1.svg';
import youtubeIcon from '../../../assets/icons/youtubeIcon1.svg';
import contactUsBackground from '../../../assets/images/contactUsBackground.png';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${contactBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    mapContainer: {
        width: '100%',
        height: '384px',
        backgroundImage: `url(${contactMap})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    bannerSellContainer: {
        "& h2": {
            fontSize: '56px',
            lineHeight: '66px',
            color: '#fff',
        },
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: theme.palette.text.warning.warning400,
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600,
    },
    bannerDescription: {
        color: theme.palette.text.primary.primary50,
        lineHeight: '28px',
    },
    sectionHeading: {
        fontSize: '54px',

    },
    inquiryContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        "& h4": {
            lineHeight: '58px',
            "& span": {
            },
        },
    },
    btn: {
        color: "#fff",
        background: theme.palette.background.primary.primary500,
        padding: '10px 20px',
        width: '166px',
        marginTop: '16px',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '20px',
        position: 'relative !important',
        left: '171px',
        right: '0px',
    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '100%',
        marginLeft: '10px',
    },
    mapSecondaryContiner: {
        width: '639px',
        height: '204px',
        background: '#FFB7B7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& p": {
            fontSize: '28px',
            lineHeight: '34px',
        },
    },
    primaryinqContainer: {
        backgroundImage: `url(${contactUsBackground})`,
        backgroundSize: 'cover',
        width: '760px',
        "& h5": {
            color: '#fff',
        },
    },
    primaryinContainer: {
        background: theme.palette.background.primary.primary500,
        "& h5": {
            color: '#fff',
        },
    },

    textFeildlabel: {
        "& span": {
            color: theme.palette.text.secondary.secondary400,
            fontSize: '18px',
        }
    },
    text: {
        color: '#fff',
    },
    input: {
        height: "100px !important",
    },
    secondaryHeading: {
        "& span": {
            color: theme.palette.text.primary.primary500,

        },
    },
    mapOuter: {
        position: "relative",
        textAlign: "right",
        width: "100%",
        height: "384px",
    },
    gmapCanvas: {
        overflow: "hidden",
        background: "none !important",
        width: "100%",
        height: "384px",
    },
    gmapIframe: {
        width: "100% !important",
        height: "384px !important",
        border: 0,
    },
}));


const ContactUsTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className={`${classes.heading} font-semibold mb-[28px]`}>Connect, Collaborate,<br />Succeed</h1>
                    <h2 className='font-semibold'>Reach Out to <br />Steelbazaar Today</h2>
                </div>
            </div>
            <div className={`${classes.container} py-[90px]`}>
                <h3 className={`${classes.sectionHeading} text-center font-semibold`}>Let's keep in touch</h3>
                <p className={`${classes.subHeading} mb-10 text-center text-2xl`}>Feel free to reach out to us using the options below, <br />
                    and our dedicated team will respond to your inquiries promptly.</p>
                <div className='flex gap-10 items-center'>
                    <div className={`${classes.inquiryContainer} py-8 px-4 rounded-lg`}>
                        <h4 className={`${classes.secondaryHeading} text-5xl font-semibold`}>Let’s <span>talk!</span></h4>
                        <p>Please use a business email to connect with us.</p>
                        <div>
                            <div className={`${classes.textFeildlabel} font-semibold mt-4 mb-1`}>Name<span>*</span></div>
                            <TextFieldV2 />
                            <div className={`${classes.textFeildlabel} font-semibold mt-4 mb-1`}>Mobile<span>*</span></div>
                            <TextFieldV2 placeholder='+91' />
                            <div className={`${classes.textFeildlabel} font-semibold mt-4 mb-1`}>Email<span>*</span></div>
                            <TextFieldV2 />
                            <div className={`${classes.textFeildlabel} font-semibold mt-4 mb-1`}>Message<span>*</span></div>
                            <TextFieldV2 className={classes.input} />
                            <Button type='submit' className={`${classes.btn} text-base leading-5 font-bold`} >Send a Message</Button>
                        </div>
                    </div>
                    <div>
                        <h4 className={`${classes.secondaryHeading} text-5xl font-semibold mb-7`}>We are <span>here</span></h4>
                        <div className={`${classes.primaryinqContainer} p-4 rounded-xl`}>
                            <h5 className='text-2xl font-semibold leading-7 mb-4'>Corporate Office</h5>
                            <div className='flex gap-2 mb-4'>
                                <img src={locationPin} alt="" />
                                <p className={`${classes.text} text-lg font-semibold leading-[22px] `}>C-196, 2nd Floor, Sec-63, Noida, <br />Uttar Pradesh (India)</p>
                            </div>
                            <div className='flex gap-2 mb-4'>
                                <img src={mobile} alt="" />
                                <p className={`${classes.text} text-lg font-semibold leading-[22px] `}>+91 9654 5555 40,</p>
                            </div>
                            <div className='flex gap-2 mb-4'>
                                <img src={mailIcon} alt="" />
                                <p className={`${classes.text} text-lg font-semibold leading-[22px] `}>info@steelbazaar.com</p>
                            </div>
                            <div className='flex gap-5 mt-7'>
                                <a href=""><img src={instaIcon} alt="" /></a>
                                <a href=""><img src={fbIcon} alt="" /></a>
                                <a href=""><img src={linkedInIcon} alt="" /></a>
                                <a href=""><img src={xIcon} alt="" /></a>
                                <a href=""><img src={whatsappIcon} alt="" /></a>
                                <a href=""><img src={youtubeIcon} alt="" /></a>
                            </div>
                        </div>
                        <div className={`${classes.primaryinContainer} p-4 rounded-xl mt-10`}>
                            <h5 className='text-2xl font-semibold leading-7 mb-4'>Regional Offices</h5>
                            <div className='flex gap-2 mb-4'>
                                <img src={locationPin} alt="" />
                                <p className={`${classes.text} text-lg font-semibold leading-[22px] `}>A-327, Silver Spring, MIDC Taloja Industrial Area, <br /> Dist. Raigad, Maharashtra -410208</p>
                            </div>
                            <div className='flex justify-between'>
                                <div className='flex gap-2 mb-4'>
                                    <img src={mobile} alt="" />
                                    <p className={`${classes.text} text-lg font-semibold leading-[22px] `}>+91 9654 5555 10</p>
                                </div>
                                <div className='flex gap-2 mb-4'>
                                    <img src={mailIcon} alt="" />
                                    <p className={`${classes.text} text-lg font-semibold leading-[22px] `}>mumbairo@steelbazaar.com</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.mapOuter}>
                <div className={classes.gmapCanvas}>
                    <iframe
                        className={classes.gmapIframe}
                        scrolling="no"
                        marginHeight={0}
                        marginWidth={0}
                        src="https://maps.google.com/maps?width=1300&amp;height=384&amp;hl=en&amp;q=svamwork%20co&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        title="Google Map - Svamwork"
                    ></iframe>
                </div>
            </div>
        </>
    );
};

export default ContactUsTemplate;
