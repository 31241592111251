import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import { CART_LIFE_CYCLE, CLASS_TYPES, CUSTOMER_ROUTES } from "../../../utils/constant";
import noImage from "../../../assets/images/noImage.jpg"
import { formatUOM, makeRoute, sortAttributes } from "../../../utils/helper";
import { CARALOGUE_OWNER_TYPE, CATALOGUE_TYPE_STATES, CLASS_STATES, HTTP_STATUS } from "../../../utils/types";
import useMetaDataService from "../../../hooks/useMetaDataService";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { WishlistContext } from "../../../contexts/WishlistContext";
import { useWishlistService } from "../../../services/useWishlistService";

interface GridItemProps {
    data: any;
    isImageVisible?: boolean;
    styles?: string;
    label?: string
}
export interface IStyle {
    [key: string]: string
}
const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
            boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.08)"
        },
        borderRadius: "16px"
    },
    imageContainer: {
        height: "185px",
        "& img": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px"
        }
    },
    iconContainer: {
        top: "12px",
        right: "12px",
        backgroundColor: theme.palette.background.secondary.secondary50,
        borderRadius: "4px",
        color: theme.palette.text.secondary.secondary700,
        lineHeight: "14px",
        border: `1px solid ${theme.palette.border.secondary.secondary100}`,
    },
    detailContainer: {
        rowGap: "12px",
        padding: "12px",
        marginTop: "-8px"
    },
    headingContainer: {
        rowGap: "8px",
    },
    catalogueName: {
        color: theme.palette.text.primary.primary900,
        height: "34px",
        lineHeight: "16.8px",
        letterSpacing: "0.14px",
        fontSize: "14px",
        fontWeight: 500
    },
    attributeTitle: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "13.8px",
        letterSpacing: "0.12px",
        fontSize: "12px",
        fontWeight: 500
    },
    price: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "13.8px",
        letterSpacing: "0.12px"
    },
    priceValue: {
        color: theme.palette.text.primary.primary800,
        lineHeight: "20.7px",
        letterSpacing: "-0.36px"
    },
    mt: {
        color: theme.palette.text.neutral.neutral300,
        lineHeight: "13.8px",
        letterSpacing: "0.012px"
    },
    moq: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "13.8px",
        letterSpacing: "0.12px",
        fontSize: '12px',
        fontWeight: 500
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    shadow: {
        background: "linear-gradient(0deg, rgba(10, 18, 41, 0.00) 0%, rgba(10, 18, 41, 0.40) 100%)",
    },
    priceWebContainer: {
        display: "grid",
        rowGap: "8px",
    },
    priceMobileContainer: {
        display: "none",
    },
    "@media (max-width: 767px)": {
        container: {
            borderRadius: "8px"
        },
        imageContainer: {
            height: "113px",
            "& img": {
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px"
            }
        },
        detailContainer: {
            rowGap: "8px",
            padding: "12px 4px 4px",
        },
        headingContainer: {
            rowGap: "4px",
        },
        catalogueName: {
            height: "28px",
            lineHeight: "14px",
            fontSize: "12px",
            fontWeight: 600
        },
        attributeTitle: {
            lineHeight: "12px",
            fontSize: "10px",
            fontWeight: 400
        },
        moq: {
            lineHeight: "12px",
            fontSize: '10px',
            fontWeight: 400
        },
        priceWebContainer: {
            display: "none",
  
        },
        priceMobileContainer: {
            display: "grid",
        },
        mt: {
            color: theme.palette.text.primary.primary500,
            lineHeight: "6px",
            letterSpacing: "0.012px",
            fontSize: "8px",
            alignItems: "flex-start",
            marginTop: "2px"
        },
        priceValue: {
            lineHeight: "14px",
        },
    }
}));

const CatalogueGridItem: React.FC<GridItemProps> = ({ data, isImageVisible = true, styles, label }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { metaData: filterData } = useMetaDataService();

    const onViewCatalogue = (event: any) => {
        if (event.target.closest("#child")) return;
        if (data.classType === CLASS_STATES.STANDARD || data.classType === CLASS_STATES.NONSTANDARD) {
            if (data.catalogueType === CATALOGUE_TYPE_STATES.CURRENT_INVENTORY) {
                if (data?.catalogueOwnerType === CARALOGUE_OWNER_TYPE.AVIGHNA) {
                    navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION, { query: { "catalogueId": data.id } }))
                }
                else if (data?.catalogueOwnerType === CARALOGUE_OWNER_TYPE.VENDOR) {
                    navigate(makeRoute(CUSTOMER_ROUTES.VENDOR_CATALOGUE_DESCRIPTION, { query: { "catalogueId": data.id } }))
                }
            }
            else if (data.catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
                navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_MTO_VIEW, { query: { "catalogueId": data.id } }));
            }
        }
        else if (data.classType === CLASS_STATES.SECONDARY) {
            if (data.catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
                navigate(makeRoute(CUSTOMER_ROUTES.SECONDARY_CATALOGUE_LOT_VIEW, { query: { "secondaryCatalogueId": data.id } }));
            }
            else {
                navigate(makeRoute(CUSTOMER_ROUTES.SECONDARY_CATALOGUE_DESCRIPTION, { query: { "secondaryCatalogueId": data.id } }));
            }
        }
        else if (data.classType === CLASS_STATES.BUNDLE) {
            navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_BUNDLE, { query: { "catalogueId": data.id } }));
        }
    }

    const sortedAttributes = sortAttributes(data.attributes, filterData.attributes);
    const { user } = useAuthenticatedUser();
    const wishlistContextData = useContext(WishlistContext);
    const wishlistService = useWishlistService();
    const handleFavoriteClick = () => (event: any) => {
        if (event.target.closest("#child")) {
            updateWishlist();
        }
    };

    const updateWishlist = async () => {
        try {
            const payload: any = {
                userId: user?.id,
                favourites: {
                    catalogueIds: [...wishlistContextData.favourites.catalogueIds],
                    secondaryCatalogueIds: [...wishlistContextData.favourites.secondaryCatalogueIds]
                }
            };
            if (data.classType === CLASS_STATES.STANDARD || data.classType === CLASS_STATES.NONSTANDARD) {
                payload.favourites.catalogueIds = payload.favourites.catalogueIds.filter((cId: number) => cId !== data.id);
            } else if (data.classType === CLASS_STATES.SECONDARY) {
                payload.favourites.secondaryCatalogueIds = payload.favourites.secondaryCatalogueIds.filter((cId: number) => cId !== data.id);
            }
            const wishlistResponse = await wishlistService.updateWishlist(payload);
            if (wishlistResponse?.status === HTTP_STATUS.OK) {
                await wishlistContextData.syncWishlist(CART_LIFE_CYCLE.REMOVE_PRODUCT);
                return true;
            } else {
                throw new Error("Wishlist Details fetch failed");
            }
        } catch (error) {
            return false;
        }
    };

    const formatPrice = (minPrice: number, maxPrice: number, inStock: boolean): string => {
        if (!inStock) {
            return "-";
        }
        if (minPrice === maxPrice) {
            return `₹${minPrice.toLocaleString()}`;
        }
        return `₹${minPrice.toLocaleString()}-₹${maxPrice.toLocaleString()}`;
    };

    const [hovered, setHovered] = useState<boolean>(false)

    return (
        <div className={`grid bg-white ${classes.container} ${styles} relative cursor-pointer`} onClick={onViewCatalogue} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            {isImageVisible && (
                <div className={`${classes.imageContainer} relative mt-0 my-auto z-0 `}>
                    <img className={`w-full h-full ${label === "wishlist" && hovered ? classes.shadow : ''}`} src={data.catalogueImagePath[0] || noImage} alt="CatalogueImage" />
                    {label === "wishlist" && hovered &&
                        <div className={`${classes.iconContainer} flex px-2 py-1 absolute z-20 cursor-pointer text-xs font-medium`} id="child" onClick={handleFavoriteClick()} >Remove</div>
                    }
                </div>
            )}
            <div className={`${classes.borderTop} grid rounded-lg ${classes.detailContainer}`}>
                <div className={`${classes.headingContainer} grid`}>
                    <div className={`text-wrap truncate ${classes.catalogueName}`}>{data.name.length > 55 ? data.name.substring(0, 55) + "..." : data.name}</div>
                    <div className="flex">
                        {sortedAttributes.splice(0, 1).map(({ key, value }) => {
                            let [min, max, uom] = value as string[];
                            min = min.replace(/\.0*$/, '');
                            max = max.replace(/\.0*$/, '');
                            const isRangeZero = min === max || max === "";
                            return (
                                <div className={`${classes.attributeTitle}`}>{key}: {formatUOM(min, uom, isRangeZero ? (min) : `${min}-${max}`)}</div>
                            );
                        })}
                    </div>
                </div>

                <div className={`${classes.priceWebContainer} `}>
                    <div className={`${classes.borderTop} grid pt-3`}>
                        <div className={`${classes.priceValue} text-xs font-medium`}>Price</div>
                        <div className="flex gap-x-1">
                            <div className={`${classes.priceValue} text-lg font-bold`}>
                                {formatPrice(data.minimumPrice, data.maximumPrice, data.stockAvaliable)}
                            </div>
                            <div className={`${classes.mt} text-xs font-normal flex justify-end items-end`}>/{data.uom}</div>
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <div className={`${classes.moq}`}>MOQ: {data.stockAvaliable ? data.moq : "0"} {data.uom}</div>
                        {data.count &&
                            <div className={`${classes.moq}`}>Bundle Product: {data.count}</div>
                        }
                    </div>
                </div>

                <div className={`${classes.priceMobileContainer} `}>
                    <div className={`${classes.borderTop} grid pt-2`}>
                        <div className="flex gap-x-1">
                            <div className={`${classes.priceValue} text-xs font-semibold`}>
                                {formatPrice(data.minimumPrice, data.maximumPrice, data.stockAvaliable)}
                            </div>
                            <div className={`${classes.mt} font-bold flex justify-end`}>/{data.uom}</div>
                        </div>
                    </div>

                    <div className="flex justify-between mt-1">
                        <div className={`${classes.moq}`}>MOQ: {data.stockAvaliable ? data.moq : "0"} {data.uom}</div>
                        {data.count &&
                            <div className={`${classes.moq}`}>Bundle Product: {data.count}</div>
                        }
                    </div>
                </div>

                <div>
                    <Button className={`w-full`} size="medium" label="View More" variant="tertiaryContained"/>
                </div>
            </div>
        </div>
    );
};

export default CatalogueGridItem;