import React from 'react'
import { createUseStyles } from 'react-jss';

interface AlertBarProps {
    text: string
    className: string;
    textColor: string;
    icon: string;
}

const useStyles = createUseStyles({
    text: (props: AlertBarProps) => ({
        color: props.textColor || 'red',
    }),
});

const AlertBar: React.FC<AlertBarProps> = ({ text, className, textColor, icon }) => {
    const classes = useStyles({ className, textColor, text, icon });

    return (
        <div className={`p-3 flex gap-x-3 rounded-lg items-center ${className}`}>
            <div>
                <img src={icon} alt="popup-icon" className='max-w-max' />
            </div>
            <div className={`text-sm ${classes.text}`}>
                {text}
            </div>
        </div>
    );
}

export default AlertBar