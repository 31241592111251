import React from 'react'
import { Enquiry } from './MyEnquiry.template';
import { createUseStyles } from "react-jss";
import Button from '../../atoms/Button/Button';
import moment from 'moment';
import calender from '../../../assets/icons/calender.svg'
import { STATUS } from '../../../utils/constant';

interface EnquiryItemTemplateProps {
    enquiry: Enquiry;
    onClick?: any;
    title?: string;
};

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    slide: {
        width: '254px',
        borderRadius: "12px",
        "&:hover": {
            boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.08)"
        },
    },
    product: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral960}`
    },
    productTitle: {
        fontSize: "12px"
    },
    quantity: {
        color: "#999"
    },
    price: {
        color: theme.palette.text.neutral.neutral970
    },
    amount: {
        color: "#331400"
    },
    ongoing: {
        color: theme.palette.text.warning.warning600
    },
    accepted: {
        color: "#24A147"
    },
    rejected: {
        color: theme.palette.text.secondary.secondary600
    }
}));

const EnquiryItemTemplate: React.FC<EnquiryItemTemplateProps> = ({ enquiry, onClick, title }) => {

    const classes = useStyles();

    const getStatusClass = (status: string | undefined, classes: Record<string, string>) => {
        switch (status) {
          case STATUS.ACCEPTED:
            return classes.accepted;
          case STATUS.REJECTED:
            return classes.rejected;
            case STATUS.ONGOING:
                return classes.ongoing;
        }
      };

    return (
        <div className={` ${classes.slide} h-full border p-4 grid`} key={enquiry.enquiryId}>
            <div className='flex justify-between items-center pb-4'>
                <div className='flex flex-col'>
                    <span className='text-sm font-semibold'>{title} ID {enquiry?.enquiryId}</span>
                    <span className={`${classes.productTitle} ${getStatusClass(enquiry?.enquiryStatus, classes)}`}>ENQ {enquiry?.enquiryStatus}</span>
                </div>
                <div className='flex flex-col'>
                    <span className={`text-sm flex gap-2 ${classes.quantity}`}> <img src={calender} alt="icon" />RFQ Date </span>
                    <span className='text-sm'>{moment(enquiry?.createdDate).format("DD-MM-YYYY")}</span>
                </div>
            </div>
            <div className={`pt-4  ${classes.product}`}>
                <span className={classes.quantity}>Products</span>
                <div className={`${classes.productTitle} text-ellipsis line-clamp-1 pt-1 pb-4 font-semibold`}>
                    <span className='pr-1'>{enquiry.productsDetails[0].substring(0, 28)}</span>
                    <span className='underline'>{enquiry.productsDetails.length > 1 ? ` +${enquiry.productsDetails.length - 1}` : ''}</span>
                </div>
                <div className='grid gap-5'>
                    <div className={`flex justify-between pt-4 ${classes.product}`}>
                        <span className={`text-sm ${classes.quantity} `}>Total Quantity (MT) </span>
                        <span className={`text-sm font-semibold ${classes.amount}`}>
                            {enquiry.quantity}
                        </span>
                    </div>
                    <Button
                        variant="secondaryContained"
                        label="View Details"
                        size='medium'
                        onClick={onClick}
                    />
                </div>
            </div>
        </div>
    )
}
export default EnquiryItemTemplate