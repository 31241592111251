import React, { useEffect, useState } from "react";
import { AUTH_STATES, AUTHENTICATION_TYPE, HTTP_STATUS, LOGIN_TYPES } from "../../../utils/types";
import { FormHelperText, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import { ILoginTypeParam, useAuthService } from "../../../services/useAuthService";
import Button from "../../atoms/Button/Button";
import { createUseStyles } from "react-jss";
import * as Yup from "yup";
import { CUSTOMER_ROUTES, REGEX, STATIC_PAGES_ROUTES } from "../../../utils/constant";
import { useFormik } from "formik";
import { Checkbox } from "@mui/joy";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useGstinService } from "../../../services/useGstService";
import { IncognitoUser } from "./Authentication.template";
import { Link } from 'react-router-dom';
import TextFieldV2 from "../../atoms/Input/TextFieldV2";

interface SignUpWithMobileTemplateProps {
    setAuthState: (state: AUTH_STATES) => void;
    updateUserDetails: (name: string, value: string | boolean) => void;
    userDetails: IncognitoUser;
    setActiveTab?: (toggleState: AUTHENTICATION_TYPE) => void;

}

const useStyles = createUseStyles((theme: any) => ({
    formContainer:{
        rowGap: "24px"
    },
    sectionText: {
        color: theme.palette.text.primary.primary500,
        marginLeft: '5px',
        marginRight: '5px',
        textDecoration: "underline",
        fontSize: "14px",
        fontWeight: 500
    },
    dullText: {
        color: theme.palette.text.primary.primary800,
    },
    success: {
        color: theme.palette.text.primary.primary500,
        marginLeft: '5px',
        marginRight: '5px',
        textDecoration: "underline",
        fontSize: "14px",
        fontWeight: 500
    },
    content: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "16px"
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    mobile: {
        "& .MuiTypography-root": {
            color: theme.palette.text.neutral.neutral400
        }
    },
    "@media (max-width: 767px)": {
        formContainer: {
            rowGap: "10px"
        },
    }
}));

const SignUpWithMobileTemplate: React.FC<SignUpWithMobileTemplateProps> = ({ setAuthState, updateUserDetails, userDetails, setActiveTab}) => {
    const classes = useStyles();
    const [isGstVerified, setIsGstVerified] = useState<boolean>(userDetails.isGstinVerified ?? false);
    const [gstLoading, setGstLoading] = useState<boolean>(false);
    const authService = useAuthService();
    const gstService = useGstinService();

    const initialValues = {
        fullName: userDetails.fullName ?? '',
        mobileNumber: userDetails.mobileNumber ?? '',
        gstin: userDetails.gstin ?? '',
        agreeToTerms: ''
    }

    const validationSchema = Yup.object().shape({
        fullName: Yup.string()
            .required('Please enter your full name')
            .matches(REGEX.FULL_NAME_V2, 'Please enter a valid name'),
        gstin: Yup.string()
            .required('Please enter your GSTIN')
            .matches(REGEX.GSTIN, 'Please provide a valid GSTIN'),
        mobileNumber: Yup.string()
            .required('Please enter your mobile number')
            .matches(REGEX.PHONE_NUMBER, 'Please enter a valid mobile number'),
        agreeToTerms: Yup.boolean()
            .required('You must agree to User Agreement and Privacy Policy')
            .oneOf([true], 'You must agree to User Agreement and Privacy Policy')
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting, setFieldError }) => {
            if (!isGstVerified) {
                formik.setFieldError('gstin', 'Please verify your GSTIN by clicking on the \'Verify\' button');
                return;
            }
            setSubmitting(true);
            let params: ILoginTypeParam = {
                loginType: LOGIN_TYPES.MANUAL,
            };
            authService.signUp(values, params)
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK) {
                        if (res?.data?.data?.otpStatus) {
                            updateUserDetails('mobileNumber', values.mobileNumber);
                            updateUserDetails('fullName', values.fullName);
                            updateUserDetails('gstin', values.gstin);
                            updateUserDetails('isGstinVerified', isGstVerified)
                            formik.resetForm();
                            setAuthState(AUTH_STATES.OTP_VERIFICATION);
                        } else {
                            if (!res?.data?.data?.isMobileNumberValid)
                                setFieldError('mobileNumber', "This mobile number is already registered");
                        }
                    } else if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                        console.error("User details form:", res?.data?.statusMessage);
                    }
                }).catch(error => {
                    console.error("User details form:", error)
                }).finally(() => setSubmitting(false));
        },
    });

    const setAuthStateTo = (authState: AUTH_STATES) => () => {
        setActiveTab?.(AUTHENTICATION_TYPE.LOGIN);
        setAuthState(authState);
    }

    const hanldleGstVerification = async () => {
        if (isGstVerified) return;
        setGstLoading(true);
        gstService.searchGstin({ gstin: formik.values.gstin })
            .then(res => {
                if (res.data.data.valid) {
                    if (res.data.data.gstStatus === "ACTIVE") {
                        formik.setFieldError('gstin', '');
                        setIsGstVerified(true);
                    }
                    else formik.setFieldError('gstin', 'Please provide a active GSTIN');
                } else {
                    setIsGstVerified(false);
                    formik.setFieldError('gstin', res.data.data.message);
                }
            }).catch(err => {
                formik.setFieldError('gstin', 'Please provide a valid GSTIN');
            }).finally(() => setGstLoading(false))
    }

    return (

        <div className="grid gap-y-3 w-full">
            <form className={` grid ${classes.formContainer}`} onSubmit={formik.handleSubmit}>
               
                    <div className="">
                        <TextFieldV2
                            label="Full Name"
                            type="text"
                            placeholder="Enter Here"
                            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                            {...formik.getFieldProps("fullName")}
                            fullWidth
                            autoComplete="false"
                        />
                        {formik.touched.fullName && formik.errors.fullName &&
                            <FormHelperText error className='top-half '>{formik.errors.fullName}</FormHelperText>
                        }
                    </div>
                    <div className="">
                        <TextFieldV2
                            label="GSTIN"
                            type="text"
                            placeholder="Enter Here"
                            error={formik.touched.gstin && Boolean(formik.errors.gstin)}
                            {...formik.getFieldProps("gstin")}
                            value={formik.values.gstin.trim()}
                            fullWidth
                            autoComplete="false"
                            disabled={isGstVerified}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={hanldleGstVerification}
                                        edge="end"
                                    >
                                        {isGstVerified ? <span className={`flex items-center ${classes.success}`}>Verified</span>
                                            : gstLoading ? <span className={`flex items-center gap-x-1 ${classes.sectionText}`}><span>Verifying</span></span>
                                                : <span className={`${classes.sectionText}`}>Verify</span>}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                        {formik.touched.gstin && formik.errors.gstin &&
                            <FormHelperText error className=' top-full'>{formik.errors.gstin}</FormHelperText>
                        }
                    </div>
                    <div className="">
                        <TextFieldV2
                            label="Phone Number"
                            type="text"
                            placeholder="Enter Here"
                            error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                            {...formik.getFieldProps("mobileNumber")}
                            fullWidth
                            autoComplete="false"
                            InputProps={{
                                startAdornment: <InputAdornment position="start" className={`${classes.mobile} text-base font-normal`}>+91</InputAdornment>,
                            }}
                        />
                        {formik.touched.mobileNumber && formik.errors.mobileNumber &&
                            <FormHelperText error className=' top-full'>{formik.errors.mobileNumber}</FormHelperText>
                        }
                    </div>

                    <div className="flex flex-col justify-center">
                        <div className="flex items-center gap-2">
                            <Checkbox {...formik.getFieldProps("agreeToTerms")} />
                            <span className={`${classes.dullText} text-sm`}>I agree to
                                <Link to={STATIC_PAGES_ROUTES.TERMS_AND_CONDITIONS} target="_blank" className={`${classes.sectionText}`}>
                                    User Agreement
                                </Link>
                                <span className={`${classes.dullText} text-sm`}> and </span>
                                <Link to={STATIC_PAGES_ROUTES.PRIVACY_POLICY} target="_blank" className={`${classes.sectionText}`}>
                                    Privacy Policy
                                </Link>
                            </span>
                        </div>
                        {formik.touched.agreeToTerms && formik.errors.agreeToTerms &&
                            <FormHelperText error className=' top-full'>{formik.errors.agreeToTerms}</FormHelperText>
                        }
                    </div>
           
                <Button variant="primaryContained" label="Get OTP" type="submit" isLoading={formik.isSubmitting} />
            </form>
            <div className={`flex justify-center`}>
                <Button label={"Log In via Phone Number"} variant="secondaryText" size="small" onClick={setAuthStateTo(AUTH_STATES.LOGIN_WITH_EMAIL)} />
            </div>
        </div>
    );
}

export default SignUpWithMobileTemplate;