import React, { useState } from 'react';
import { createUseStyles } from "react-jss";
import CloseIcon from '@mui/icons-material/Close';
import AccordionV2 from '../../molecules/AccordionV2/AccordionV2';
import { STATIC_PAGES_ROUTES } from '../../../utils/constant';

interface MenuDialogTemplateProps {
    handleDialogClose: (value: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        position: 'fixed',
        width: '100vw',
        minHeight: '70vh',
        zIndex: 9999, 
        backgroundColor: 'white', 
    },
    closeIcon: {
        color: theme.palette.text.primary.primary500
    },
    "@media (max-width: 767px)": {
        container: {
            minHeight: '0',
        },
}
}));



const MenuDialogTemplate: React.FC<MenuDialogTemplateProps> = ({ handleDialogClose }) => {
    const classes = useStyles();
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);

    
    const handleLinkClick = () => {
        setIsDropdownOpen(false);
    };

    if (!isDropdownOpen) return null; 

    const menuSections = [
        {
            title: 'About',
            items: [

                { name: 'About us', route: STATIC_PAGES_ROUTES.ABOUT_US },
                { name: 'Mission and Vision', route: STATIC_PAGES_ROUTES.ABOUT_US },
            ],
        },
        {
            title: 'Platform',
            items: [
                {
                    name: 'Marketplace',
                    route: '',
                    subSection: {
                        title: 'Marketplace',
                        items: [
                            {
                                name: 'Global Sourcing',
                                route: `${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=global-sourcing`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Product listings',
                                route: `${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=global-sourcing`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Seller Center',
                                route: `${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=global-sourcing`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Vendor Center',
                                route: `${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=global-sourcing`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Digital Market Place',
                                route: `${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=global-sourcing`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Smart Trade Features',
                                route: `${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=global-sourcing`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Industries We Serve',
                                route: `${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=global-sourcing`,
                                onClick: handleLinkClick,
                            },         
                        ],
                    },
                },
                {
                    name: 'Procurement',
                    route: '',
                    subSection: {
                        title: 'Procurement',
                        items: [
                            { name: 'SB Maxx', route: STATIC_PAGES_ROUTES.PROCUREMENT },
                            { name: 'I Procure', route: STATIC_PAGES_ROUTES.PROCUREMENT },
                            { name: 'I Procure Plus', route: STATIC_PAGES_ROUTES.PROCUREMENT },
                            { name: 'Inventory Management', route: STATIC_PAGES_ROUTES.PROCUREMENT },
                        ],
                    },
                },
                { name: 'Manufacturing', route: STATIC_PAGES_ROUTES.PROCUREMENT },
                { name: 'Trade Leads', route: STATIC_PAGES_ROUTES.PROCUREMENT },
            ],
        },
        {
            title: 'Services',
            items: [
                { name: 'Digital Services', route: STATIC_PAGES_ROUTES.STEEL_BAZAAR_SERVICES },
                { name: 'Trade Listing', route:  STATIC_PAGES_ROUTES.STEEL_BAZAAR_SERVICES },
                {
                    name: 'Auction Services', route: '',
                    subSection: {
                        title: 'Auction Services',
                        items: [
                            { name: 'Reverse Option', route:  STATIC_PAGES_ROUTES.STEEL_BAZAAR_SERVICES },
                            { name: 'Forward Sales Option', route:  STATIC_PAGES_ROUTES.STEEL_BAZAAR_SERVICES },
                        ],
                    },
                },
                { name: 'RFQs Services', route: STATIC_PAGES_ROUTES.STEEL_BAZAAR_SERVICES },
                { name: 'Supply Chain Finance', route:  STATIC_PAGES_ROUTES.STEEL_BAZAAR_SERVICES },
            ],
        },
        {
            title: 'Trade Assurance',
            items: [
                { name: 'Payments', route:  STATIC_PAGES_ROUTES.TRADE_ASSURANCE },
                { name: 'Money Refunds', route:  STATIC_PAGES_ROUTES.TRADE_ASSURANCE},
                { name: 'On Time Delivery', route:  STATIC_PAGES_ROUTES.TRADE_ASSURANCE },
                { name: 'GST Compliances', route:  STATIC_PAGES_ROUTES.TRADE_ASSURANCE },
            ],
        },
        {
            title: 'Supplier',
            items: [
                { name: 'Seller Central', route:  STATIC_PAGES_ROUTES.SELLER_CENTRAL },
                { name: 'Vendor Central', route:  STATIC_PAGES_ROUTES.VENDOR_CENTRAL },
            ],
        },
        {
            title: 'Buyer',
            items: [
                { name: 'OnBoarding', route:  STATIC_PAGES_ROUTES.CUSTOMER_BUYER },
                { name: 'Search And Find', route:  STATIC_PAGES_ROUTES.CUSTOMER_BUYER },
                { name: 'Compare the brands', route:  STATIC_PAGES_ROUTES.CUSTOMER_BUYER },
                { name: 'Lending Partners', route:  STATIC_PAGES_ROUTES.CUSTOMER_BUYER },
                { name: 'Post your Lead', route:  STATIC_PAGES_ROUTES.CUSTOMER_BUYER },

            ],
        },
        {
            title: 'Contact Us',
            items: [
                { name: 'Contact Us', route: STATIC_PAGES_ROUTES.CONTACT_US },
                { name: 'New Centre', route: STATIC_PAGES_ROUTES.CONTACT_US },
            ],
        },
        {
            title: 'Help Center',
            items: [
                { name: 'Sell on Steel Bazaar', route: STATIC_PAGES_ROUTES.HELP_CENTER },
                { name: 'Quote Management', route: STATIC_PAGES_ROUTES.HELP_CENTER },
                { name: 'Buyer Support', route: STATIC_PAGES_ROUTES.HELP_CENTER },
                { name: 'Supplier Support', route: STATIC_PAGES_ROUTES.HELP_CENTER },
                { name: 'FAQs', route: STATIC_PAGES_ROUTES.HELP_CENTER },
                { name: 'Complaints', route: STATIC_PAGES_ROUTES.HELP_CENTER },
                { name: 'Live Chat', route: STATIC_PAGES_ROUTES.HELP_CENTER },

            ],
        },
    ];

    return (
        <div className={classes.container}>
            <div className=" my-1 flex justify-end" onClick={() => handleDialogClose(false)}>
                <CloseIcon className={classes.closeIcon} />
            </div>
            <div className=''>
                <AccordionV2 sections={menuSections} />
            </div>
        </div>
    );
}

export default MenuDialogTemplate;