import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import closeIcon from "../../../assets/icons/closeRoundIcon.svg";

interface DisplayImageV2Props {
    path: string
    imageDialogOpen: boolean
    setImageDialogOpenTo: (value: false)=>void;
    showCloseIcon?: boolean;
}

const DisplayImageV2: React.FC<DisplayImageV2Props> = ({ path, imageDialogOpen, setImageDialogOpenTo, showCloseIcon=false }) => {
    const handleCloseDialog = () => {
        setImageDialogOpenTo(false);
    };
    return (
        <Dialog
            open={imageDialogOpen}
            onClose={handleCloseDialog}
            maxWidth="sm"
            fullWidth
        >
            {showCloseIcon && <div className={`flex justify-end p-2`} >
                <img src={closeIcon} alt="closeIcon" onClick={handleCloseDialog} />
            </div>}
            <ClickAwayListener onClickAway={handleCloseDialog}>
                <DialogContent>
                    <img className="max-w-full max-h-full" alt="selectedImage" src={path} />
                </DialogContent>
            </ClickAwayListener>
        </Dialog>
    )
}

export default DisplayImageV2