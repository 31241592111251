import React from "react";
import { Route, Routes } from "react-router-dom";
import { Construction } from "@mui/icons-material";
import PrivacyPolicy from "../Policies/PrivacyPolicy.page";
// import SellOnSteelBazaarTemplate from "./SellOnSteelBazaar";
import SteelBazaarServices from "./SteelBazaarServices";
import ContactUsTemplate from "./ContactUs";
import SupportCareTemplate from "./SupportCare.template";
import TermsAndConditions from "../Policies/TermsAndConditions.page";
import AboutUsTemplate from "../AboutUs/AboutUs";
import HelpCenter from "./HelpCenter";
import TradeAssurance from "./TradeAssurance";
import RequestForQuotationPage from "../../template/Home/RequestForQuotation.page";
import B2BMarketplace from "./B2BMarketplace";
import SellerCentral from "./SellerCentral";
import ProcurementTemplate from "./Procurment";
import VendorCentralTemplate from "./VendorCentral.template";
import { STATIC_PAGES_ROUTES } from "../../../utils/constant";
import CustomerBuyer from "./CustomerBuyer";
import SupplyChainFinance from "./SupplyChainFinance";
import AcutionTemplate from "./Auction.template";
import DigiStockTemplate from "./DigiStock";
import DigiStoreTemplate from "./DigiStore";
import DigitalCatalogTemplate from "./DigitalCatalog.template";
import Partnership from "./Partnership";
import TrustAssurance from "./TrustAssurance";
import CareerTemplate from "./Career";

const StaticPagesRoutes: React.FunctionComponent = () => {
    return (
        <Routes>
            {/* <Route path={STATIC_PAGES_ROUTES.BUY_ON_STEELBAZAAR} element={<BuyOnSteelBazaar />} /> */}
            {/* <Route path={STATIC_PAGES_ROUTES.SELL_ON_STEELBAZAAR} element={<SellOnSteelBazaarTemplate />} /> */}
            <Route path={STATIC_PAGES_ROUTES.ABOUT_US} element={<AboutUsTemplate />} />
            <Route path={STATIC_PAGES_ROUTES.STEEL_BAZAAR_SERVICES} element={<SteelBazaarServices />} />
            <Route path={STATIC_PAGES_ROUTES.CONTACT_US} element={<ContactUsTemplate />} />
            <Route path={STATIC_PAGES_ROUTES.SUPPORT_CARE} element={<SupportCareTemplate />} />
            <Route path={STATIC_PAGES_ROUTES.HELP_CENTER} element={<HelpCenter />} />
            <Route path={STATIC_PAGES_ROUTES.TRADE_ASSURANCE} element={<TradeAssurance />} />
            <Route path={STATIC_PAGES_ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={STATIC_PAGES_ROUTES.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
            <Route path={"/rfq"} element={<RequestForQuotationPage />} />
            <Route path={STATIC_PAGES_ROUTES.B2B_MARKETPLACE} element={<B2BMarketplace />} />
            <Route path={STATIC_PAGES_ROUTES.SELLER_CENTRAL} element={<SellerCentral />} />
            <Route path={STATIC_PAGES_ROUTES.PROCUREMENT} element={<ProcurementTemplate />} />
            <Route path={STATIC_PAGES_ROUTES.VENDOR_CENTRAL} element={<VendorCentralTemplate />} />
            <Route path={STATIC_PAGES_ROUTES.CUSTOMER_BUYER} element={<CustomerBuyer />} />
            <Route path={STATIC_PAGES_ROUTES.SUPPLYCHAIN_FINANCE} element={<SupplyChainFinance />} />
            <Route path={STATIC_PAGES_ROUTES.AUCTION} element={<AcutionTemplate />} />
            <Route path={STATIC_PAGES_ROUTES.DIGI_STOCK} element={<DigiStockTemplate />} />
            <Route path={STATIC_PAGES_ROUTES.DIGISTORE} element={<DigiStoreTemplate />} />
            <Route path={STATIC_PAGES_ROUTES.DIGITAL_CATALOG} element={<DigitalCatalogTemplate />} />
            <Route path={STATIC_PAGES_ROUTES.PARTNERSHIP} element={<Partnership />} />
            <Route path={STATIC_PAGES_ROUTES.TRUST_ASSURANCE} element={<TrustAssurance />} />
            <Route path={STATIC_PAGES_ROUTES.CAREER} element={<CareerTemplate />} />
        </Routes>
    );
};

export default StaticPagesRoutes;