import React, { useContext, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Breadcrumbs from '../../atoms/BreadCrumbs/BreadCrumbs';
import { useCatalogueService } from '../../../services/useCatalogueService';
import { CLASS_STATES } from "../../../utils/types";
import { WishlistContext } from '../../../contexts/WishlistContext';
import CatalogueGridItem from '../../template/CatalogueList/CatalogueGridItem.template';
import CatalogueGridItemSkeleton from '../../template/CatalogueList/CatalogueGridItemSkeleton.template';
import { Catalogue } from '../../template/CatalogueList/CatalogueList.template';
import NoProduct from '../../molecules/NoProduct/NoProduct';
import { useNavigate } from 'react-router-dom';

export interface Payload {
    catalogueIds?: number[];
    secondaryCatalogueIds?: number[];
    classes?: string[];
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        rowGap: "32px",
        padding: "0px 70px 108px 70px"
    },
    heading: {
        color: theme.palette.text.primary.primary900
    },
    items: {
        color: theme.palette.text.neutral.neutral700
    },
    catalogueGrid: {
        display: "grid",
        gridGap: "16px",
        gridTemplateColumns: "repeat(5, 1fr)",
        "@media (max-width: 1300px)": {
            gridTemplateColumns: "repeat(4, 1fr)",
        },
        "@media (max-width: 767px)": {
            gridGap: "10px",
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        "@media (max-width: 510px)": {
            gridGap: "10px",
            gridTemplateColumns: "repeat(2, 1fr)",
        },
    },
    "@media (max-width: 767px)": {
        container: {
            rowGap: "24px",
            padding: "0px 12px"
        },
    },
}));

const WishlistPage: React.FC = () => {
    const classes = useStyles()
    const navigate = useNavigate();
    const catalogueService = useCatalogueService();
    const [catalogues, setCataloguesTo] = useState<Catalogue[]>([]);
    const [secondaryCatalogues, setSecondaryCataloguesTo] = useState<Catalogue[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const wishlistContextData = useContext(WishlistContext);

    const fetchCatalogues = async () => {
        try {
            if (wishlistContextData?.favourites?.catalogueIds.length === 0) {
                setCataloguesTo([]);
                return;
            }
            setLoading(true);
            const payload: Payload = {
                catalogueIds: wishlistContextData?.favourites?.catalogueIds,
                classes: [CLASS_STATES.STANDARD, CLASS_STATES.NONSTANDARD]
            };
            const cataloguesResponse = await catalogueService.getAllCatalogues(
                {
                    page: 0,
                    size: 100,
                    sort: "createdAt,desc",
                },
                payload
            );
            if (cataloguesResponse?.data?.data?.content?.length) {
                const { content } = cataloguesResponse?.data?.data;
                setCataloguesTo(content);
                setLoading(false);
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setCataloguesTo([]);
            setLoading(false);
        }
    };

    const fetchSecondaryCatalogues = async () => {
        try {
            if (wishlistContextData?.favourites?.secondaryCatalogueIds.length === 0) {
                setSecondaryCataloguesTo([]);
                return;
            }
            setLoading(true);
            const payload: Payload = {
                secondaryCatalogueIds: wishlistContextData?.favourites?.secondaryCatalogueIds,
                classes: [CLASS_STATES.SECONDARY]
            };
            const cataloguesResponse = await catalogueService.getAllCatalogues(
                {
                    page: 0,
                    size: 100,
                    sort: "createdAt,desc",
                },
                payload
            );
            if (cataloguesResponse?.data?.data?.content?.length) {
                const { content } = cataloguesResponse?.data?.data;
                setSecondaryCataloguesTo(content);
                setLoading(false);
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setSecondaryCataloguesTo([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (wishlistContextData?.favourites?.catalogueIds)
            fetchCatalogues();
    }, [wishlistContextData?.favourites?.catalogueIds]);

    useEffect(() => {
        if (wishlistContextData?.favourites?.secondaryCatalogueIds)
            fetchSecondaryCatalogues();
    }, [wishlistContextData?.favourites?.secondaryCatalogueIds]);

    const [combinedCatalogues, setCombinedCatalogues] = useState<Catalogue[]>([]);

    useEffect(() => {
        if (catalogues.length >= 0 || secondaryCatalogues.length >= 0) {
            setCombinedCatalogues([...catalogues, ...secondaryCatalogues]);
            setLoading(false)
        }
    }, [catalogues, secondaryCatalogues]);

    return (
        <div className={`grid ${classes.container} m-auto max-container`} >
            <Breadcrumbs />
            <div className='grid gap-y-1'>
                <div className={`${classes.heading} text-2xl font-semibold`}>My Wishlist</div>
                <div className={`${classes.items} text-sm font-medium`}>
                    {combinedCatalogues?.length} {combinedCatalogues?.length < 2 ? 'Item' : 'Items'}
                </div>
            </div>
            <div className="grid">
                {loading ? (
                    <div className={classes.catalogueGrid}>
                        {[null, null, null, null, null].map((item: any, index) => (
                            <div key={index}>
                                <CatalogueGridItemSkeleton />
                            </div>
                        ))}
                    </div>
                ) : combinedCatalogues.length > 0 ? (
                    <div className={classes.catalogueGrid}>
                        {combinedCatalogues.map((item: any, index) => (
                            <div key={item?.id || index}>
                                <CatalogueGridItem data={item} label='wishlist' />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="mt-4">
                        <NoProduct text="Your Wishlist is Empty" variant="Wishlist" label='Browse Product' onClick={() => navigate("/")} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default WishlistPage;