import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import chatWhatsappIcon from '../../../assets/icons/chatWhatsappIcon.svg';
import chatIcon from '../../../assets/icons/chatIcon.svg';
import chatCrossIcon from '../../../assets/icons/chatCrossIcon.svg';
import whatsappQR from '../../../assets/images/whatsappQR.svg';
import whatsappLogo from '../../../assets/images/whatsappLogo.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '250px',
        margin: '0 auto',
        boxShadow:
            '1px 1px 4px 0px rgba(0, 0, 0, 0.20)',
        borderRadius: '16px 16px 0px 0px',
        position: 'fixed',
        bottom: '100px',
        right: '40px',
        backgroundColor: '#fff',
        zIndex: 1000,
        display: 'none',
        overflow: 'hidden',
    },
    containerMain: {
        display: 'block',
    },
    visible: {
        display: 'block',
        animation: '$slideDown 0.5s ease forwards',
    },
    hidden: {
        animation: '$slideUp 0.5s ease forwards',
    },
    iconButton: {
        position: 'fixed',
        bottom: '20px',
        right: '40px',
        cursor: 'pointer',
        zIndex: 1001,
    },
    buttonContainer: {
        marginTop: '10px',
        textAlign: 'center',
    },
    text: {
        color: theme.palette.text.primary.primary950,
        lineHeight: '20px',
    },
    chatButton: {
        backgroundColor: theme.palette.background.primary.primary500,
        color: '#fff',
        border: 'none',
        borderRadius: '8px',
        padding: '8px 12px',
        fontWeight: '600',
        width: '100%',
        fontSize: '12px',
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'inline-block',
        lineHeight: '20px',
    },
    '@keyframes slideDown': {
        from: { transform: 'translateY(20px)', opacity: 0 },
        to: { transform: 'translateY(0)', opacity: 1 },
    },
    '@keyframes slideUp': {
        from: { transform: 'translateY(0)', opacity: 1 },
        to: { transform: 'translateY(20px)', opacity: 0 },
    },
    button: {

    },
    secondaryContainer: {
        border: '1px solid #000',
        height: '1px',
        width: '100%',
    },
    mobileContainer: {
        display: 'none',
    },
    "@media (max-width: 768px)": {
        button: {
            width: "40px"
        },
        iconButton: {
            bottom: '12px',
            right: '12px',
        },
        containerMain: {
            display: 'none',
        },
        mobileContainer: {
            display: 'block',
        },
    },
    header: {
        background: theme.palette.background.primary.primary500,
    },
    headerText: {
        color: '#fff',
        fontSize: '12px',
        lineHeight: '14px',
    },
}));

const ChatBot: React.FC = () => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const toggleChatBox = () => {
        if (isOpen && !isAnimating) {
            setIsAnimating(true);
            setIsOpen(false);
            setTimeout(() => {
                setIsAnimating(false);
            }, 100);
        } else if (!isOpen && !isAnimating) {
            setIsAnimating(true);
            setIsOpen(true);
            setTimeout(() => {
                setIsAnimating(false);
            }, 500);
        }
    };

    return (
        <>
            <div className={classes.containerMain}>
                <div className={`${classes.container} ${isOpen ? classes.visible : ''}`} style={{ display: isOpen || isAnimating ? 'block' : 'none' }}>
                    <a href="https://api.whatsapp.com/send?phone=919654555531&text=Hi" target="_blank" rel="noopener noreferrer">
                        <div className={`${classes.header} flex gap-[10px] px-4 py-2 items-center`}>
                            <img className={`${classes.button}`} src={whatsappLogo} alt="Chat Icon" />
                            <p className={`${classes.headerText}`}>Scan this QR code on your smartphone to chat with us</p>
                        </div>
                        <div className='flex flex-col items-center p-4'>
                            <img src={whatsappQR} alt="" />
                            <div className='flex items-center w-full mt-3 gap-[2px] px-2'>
                                <div className={`${classes.secondaryContainer}`}></div><p className='text-[12px] leading-[14px]'>or</p><div className={`${classes.secondaryContainer}`}></div>
                            </div>
                            <div className={classes.buttonContainer}>
                                <a href="https://api.whatsapp.com/send?phone=919654555531&text=Hi" target="_blank" rel="noopener noreferrer" className={classes.chatButton} >Chat with us on WhatsApp web</a>
                            </div>
                        </div>
                    </a>
                </div>
                <div className={classes.iconButton} onClick={toggleChatBox}>
                    {isOpen ? (
                        <img className={`${classes.button}`} src={chatCrossIcon} alt="Close Chat" />
                    ) : (
                        <img className={`${classes.button}`} src={chatWhatsappIcon} alt="Open Chat" />
                    )}
                </div>
            </div>
            <div className={`${classes.mobileContainer} ${classes.iconButton}`}>
                <a href="https://api.whatsapp.com/send?phone=919654555531&text=Hi" target="_blank"><img src={chatWhatsappIcon} alt="" /></a>
            </div>
        </>
    );
};

export default ChatBot;
