import React from 'react';
import { createUseStyles } from 'react-jss';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/joy';
import source_card_icon1 from '../../../assets/icons/source_card_icon1.jpg';
import source_card_icon2 from '../../../assets/icons/source_card_icon2.jpg';
import source_card_icon3 from '../../../assets/icons/source_card_icon3.jpg';
import source_card_icon4 from '../../../assets/icons/source_card_icon4.jpg';
import auctionSecondaryBanner from '../../../assets/images/auctionSecondaryBanner.png';
import digiBanner from '../../../assets/images/digiBanner.png';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import digiCategories from '../../../assets/images/digiCategories.png';
import digiBidding from '../../../assets/images/digiBidding.png';
import digiExpert from '../../../assets/images/digiExpert.png';
import DigitalSecondaryBanner from '../../../assets/images/digitalSecondaryBanner.png';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${digiBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    bannerSellContainer: {
        "& h1": {
            fontSize: '56px',
            lineHeight: '66px',
            color: theme.palette.text.warning.warning400,
        },
        "& span": {
            color: '#fff',
            fontSize: '24px',
            lineHeight: '28px',
        },
        "& p": {
            color: '#fff',
            lineHeight: '66px',
            fontSize: '56px',
        },
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    text: {
        fontSize: '54px',
        color: "#000",
        lineHeight: '66px',
    },
    decContainer: {
        width: '790px',
        "& p": {
            color: "#000",
            lineHeight: '26px',
        },
    },
    headingText: {
        fontSize: '48px !important',
        "& span": {
            color: `${theme.palette.text.primary.primary500} !important`,
        },
    },
    featuresContainer: {
        "& > p": {
            color: theme.palette.text.neutral.neutral600,
        },
        // "& span": {
        //     fontSize: '40px',
        //     lineHeight: '48px',
        //     color: theme.palette.text.primary.primary700,
        // },
        "& h4": {
            color: theme.palette.text.primary.primary500,
            fontSize: '48px',
            "& span": {
                fontWeight: '600',
                color: theme.palette.text.neutral.neutral800,
            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& > p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '38px',
            fontSize: '32px',
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },

    bannerBtn: {
        background: theme.palette.background.primary.primary500,
        padding: '10px 20px',
        width: '228px',
        color: '#fff',
        margin: '0 auto',
    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '100%',
    },
    sectionHeading: {
        color: "#000",
        fontSize: '54px !important',
        lineHeight: '66px',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },

    },
    sectionSubHeading: {
        color: theme.palette.text.neutral.neutral600,
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        padding: '20px 15px',
        width: '23%',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '16px',
            lineHeight: '26px',
        },
    },
    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff',
    },
    secondaryBanner: {
        width: '100%',
        height: '480px',
        backgroundImage: `url(${DigitalSecondaryBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    secondaryContainer: {
        '& h2': {
            color: theme.palette.text.warning.warning400,
            lineHeight: '58px',
        },
        "& p": {
            color: theme.palette.text.primary.primary50,
            lineHeight: '38px',
            fontSize: '32px',
        },
    },
    btn3: {
        background: '#fff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btnText: {
        color: theme.palette.text.primary.primary500,
        fontSize: '20px',
    },
    discover1: {
        border: `1px solid ${theme.palette.border.secondary.secondary200}`,
        background: theme.palette.background.secondary.secondary50,
        width: '580px',
        height: '493px',
    },
    discover2: {
        background: theme.palette.background.primary.primary50,
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        width: '580px',
        marginTop: '130px',
        height: '493px',
    },
    discover3: {
        background: theme.palette.background.warning.warning50,
        border: `1px solid ${theme.palette.border.warning.warning200}`,
        width: '580px',
        marginTop: '-70px',
        height: '527px',
    },
    discover4: {
        background: theme.palette.background.success.success50,
        border: `1px solid ${theme.palette.border.success.success200}`,
        width: '580px',
        marginTop: '70px',
        height: '516px',
    },
    discover5: {
        background: theme.palette.background.complementary.complementary50,
        border: `1px solid ${theme.palette.border.complementary.complementary200}`,
        width: '580px',
        marginTop: '-70px',
        height: '515px',
    },
    discover6: {
        backgroundColor: '#FFEFE6',
        border: '1px solid #FEC09A',
        width: '580px',
        marginTop: '70px',
        height: '526px',
    },
    discoverCard01: {
        border: `1px solid ${theme.palette.border.secondary.secondary200}`,
    },
    discoverCard02: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
    },
    discoverCard03: {
        border: `1px solid ${theme.palette.border.warning.warning400}`,
    },
    discoverCard04: {
        border: `1px solid ${theme.palette.border.success.success200}`,
    },
    discoverCard05: {
        border: `1px solid ${theme.palette.border.complementary.complementary400}`,
    },
    discoverCard06: {
        border: '1px solid #FEC09A',
    },
    discoverCard1: {
        width: '500px',
        background: '#fff',
        position: 'relative',
        right: '-40px',
        "& h4": {
            color: '#000',
            fontSize: '22px',
        },
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontSize: '20px',
            fontWeight: '600',
        },
    },
    discoverText: {
        fontSize: '48px',
        lineHeight: '58px',
    },
    discoverContent: {
        position: 'relative',
    },
    discoverCard2: {
        width: '500px',
        marginRight: '0px',
        background: '#fff',
        "& h4": {
            color: '#000',
            fontSize: '22px',
        },
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontSize: '20px',
            fontWeight: '600',
        },
    },
    outlineCommon: {
        color: 'transparent',
        fontSize: '150px',
        fontWeight: '800',
        lineHeight: '120px',
    },
    outline: {
        color: theme.palette.text.secondary.secondary400,
    },
    outline2: {
        color: theme.palette.text.primary.primary400,
    },
    outline3: {
        color: theme.palette.text.warning.warning400,
    },
    outline4: {
        color: theme.palette.text.success.success400,
    },
    outline5: {
        color: theme.palette.text.complementary.complementary400,
    },
    outline6: {
        color: ' #FD8235',
    },
    digiCard: {
        width: '32%',
    },
    digiCard1: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        overflow: 'hidden',
    },
    digiCard2: {
        border: '1px solid #FEC09A',
        overflow: 'hidden',
    },
    digiMainHeader1: {
        background: theme.palette.background.primary.primary50,
    },
    digiHeader1: {
        border: `1px solid ${theme.palette.border.primary.primary500}`,
        color: theme.palette.text.primary.primary600,
    },
    headerText1: {
        color: theme.palette.text.primary.primary600,
        fontSize: '28px',
    },
    digiMainHeader2: {
        background: "#FEC09A",
    },
    digiHeader2: {
        border: '1px solid #FD6202',
        color: '#CA4F02',
    },
    headerText2: {
        color: '#CA4F02',
        fontSize: '28px',
    },

    digiCard3: {
        border: `1px solid ${theme.palette.border.complementary.complementary200}`,
        overflow: 'hidden',
    },
    digiMainHeader3: {
        background: theme.palette.background.complementary.complementary50,
        
    },
    digiHeader3: {
        border: `1px solid ${theme.palette.border.complementary.complementary500}`,
        color: theme.palette.text.complementary.complementary600,
    },
    headerText3: {
        color: theme.palette.text.complementary.complementary600,
        fontSize: '28px',
    },
    digiCard4: {
        border: `1px solid ${theme.palette.border.warning.warning200}`,
        overflow: 'hidden',
    },
    digiMainHeader4: {
        background: theme.palette.background.warning.warning50,
    },
    digiHeader4: {
        border: `1px solid ${theme.palette.border.warning.warning500}`,
        color: theme.palette.text.warning.warning600,
    },
    headerText4: {
        color: theme.palette.text.warning.warning600,
        fontSize: '28px',
    },
    digiCard5: {
        border: `1px solid ${theme.palette.border.secondary.secondary200}`,
        overflow: 'hidden',
    },
    digiMainHeader5: {
        background: theme.palette.background.secondary.secondary50,
    },
    digiHeader5: {
        border: `1px solid ${theme.palette.border.secondary.secondary500}`,
        color: theme.palette.text.secondary.secondary600,
    },
    headerText5: {
        color: theme.palette.text.secondary.secondary600,
        fontSize: '28px',
    },
    digiCard6: {
        border: `1px solid ${theme.palette.border.success.success200}`,
        overflow: 'hidden',

    },
    digiMainHeader6: {
        background: theme.palette.background.success.success50,
    },
    digiHeader6: {
        border: `1px solid ${theme.palette.border.success.success500}`,
        color: theme.palette.text.success.success600,
    },
    headerText6: {
        color: theme.palette.text.success.success600,
        fontSize: '28px',
    },
    digiContent: {
        "& p": {
            color: "#000",
            fontSize: "22px",
            lineHeight: '26px',
        },
        "& span": {
            lineHeight: '20px',
        },
    },
}));

const cardData = [
    {
        img: source_card_icon1,
        title: "Competitive Pricing",
        description:
            "Auctions help secure the best possible pricing for both buyers and sellers.",
    },
    {
        img: source_card_icon2,
        title: "Real-Time Transparency",
        description:
            "Get live updates on bids and pricing fluctuations to make informed decisions.",
    },
    {
        img: source_card_icon3,
        title: "Large Network ",
        description:
            "Access a vast network of steel buyers and sellers for optimal market exposure.",
    },
    {
        img: source_card_icon4,
        title: "Efficient Transactions",
        description:
            "Complete transactions securely and efficiently through our platform.",
    },
];

const DigiStoreTemplate: React.FC = () => {

    const classes = useStyles();
    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className='font-semibold mb-7'>SB DigiStore</h1>
                    <p className='font-semibold mt-4 mb-4'>Turn Your Steel <br />Inventory into<br />A Virtual Digital<br />Showroom</p>
                    <span>Showcase products, streamline orders, and <br />
                        grow your business seamlessly.</span>
                </div>
            </div>
            <div className={`${classes.intoContainer} `}>
                <div className={`${classes.container} flex items-center gap-10 py-14 justify-between`}>
                    <div className=''>
                        <p className={`${classes.text} font-semibold mb-2`}>Your Digital
                            <br />Steel Hub</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-lg mb-4'>Steelbazaar’s SB DigiStore is a cutting-edge platform that empowers steel sellers with a personalised digital storefront. Showcase your standard and non-standard products, scrap inventory, and services in one centralised space. With tools for dynamic pricing, catalogue sharing, and seamless communication, SB DigiStore ensures an efficient buyer connection.</p>
                        <p className='text-lg mb-4'>Boost your visibility, streamline your processes, and unlock new sales opportunities in the competitive steel market. SB DigiStore is designed to simplify digital trade while enhancing seller credibility and customer trust.</p>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} py-[90px]`}>
                <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Why Steel Sellers Need <span>SB DigiStore ?</span> </h2>
                <h3 className={`${classes.sectionSubHeading} text-2xl font-medium mt-1 text-center mb-10`}>Benefits of SB DigiStore</h3>
                <div className='flex flex-wrap gap-4'>
                    <div className={`${classes.digiCard} ${classes.digiCard1} rounded-lg`} >
                        <div className={`${classes.digiMainHeader1} p-[15px] flex gap-[10px] items-center`}>
                            <div className={`${classes.digiHeader1} px-2 py-1 text-2xl font-semibold rounded-md`}>01</div>
                            <span className={`${classes.headerText1} font-semibold`}>Enhanced Visibility</span>
                        </div>
                        <div className={`${classes.digiContent} py-10 px-[15px]`}>
                            <p className='font-medium mb-1'>Expand Your Reach to Global Buyers Instantly</p>
                            <span className='text-base'>Showcase your products to a worldwide audience for maximum exposure.</span>
                        </div>
                    </div>
                    <div className={`${classes.digiCard} ${classes.digiCard2} rounded-lg`} >
                        <div className={`${classes.digiMainHeader2} p-[15px] flex gap-[10px] items-center`}>
                            <div className={`${classes.digiHeader2} px-2 py-1 text-2xl font-semibold rounded-md`}>02</div>
                            <span className={`${classes.headerText2} font-semibold`}>Streamlined Operations</span>
                        </div>
                        <div className={`${classes.digiContent} py-10 px-[15px]`}>
                            <p className='font-medium mb-1'>Efficiently Manage Inventory, Pricing, and Sales Offers Digitally</p>
                            <span className='text-base'>Simplify complex tasks with an all-in-one management dashboard.</span>
                        </div>
                    </div>
                    <div className={`${classes.digiCard} ${classes.digiCard3} rounded-lg`} >
                        <div className={`${classes.digiMainHeader3} p-[15px] flex gap-[10px] items-center`}>
                            <div className={`${classes.digiHeader3} px-2 py-1 text-2xl font-semibold rounded-md`}>03</div>
                            <span className={`${classes.headerText3} font-semibold`}>Boosted Credibility</span>
                        </div>
                        <div className={`${classes.digiContent} py-10 px-[15px]`}>
                            <p className='font-medium mb-1'>Build Trust Through Certifications, Reviews, & Professional Presentation</p>
                            <span className='text-base'>Enhance buyer confidence with authentic storefront details and testimonials.</span>
                        </div>
                    </div>
                    <div className={`${classes.digiCard} ${classes.digiCard4} rounded-lg`} >
                        <div className={`${classes.digiMainHeader4} p-[15px] flex gap-[10px] items-center`}>
                            <div className={`${classes.digiHeader4} px-2 py-1 text-2xl font-semibold rounded-md`}>04</div>
                            <span className={`${classes.headerText4} font-semibold`}>Faster Sales Cycle</span>
                        </div>
                        <div className={`${classes.digiContent} py-10 px-[15px]`}>
                            <p className='font-medium mb-1'>Close Deals Quickly with Real-Time Tools and Features</p>
                            <span className='text-base'>Leverage instant pricing updates and seamless purchasing processes.</span>
                        </div>
                    </div>
                    <div className={`${classes.digiCard} ${classes.digiCard5} rounded-lg`} >
                        <div className={`${classes.digiMainHeader5} p-[15px] flex gap-[9px] items-center`}>
                            <div className={`${classes.digiHeader5} px-2 py-1 text-2xl font-semibold rounded-md`}>05</div>
                            <span className={`${classes.headerText5} font-semibold`}>Cost-Effective Marketing</span>
                        </div>
                        <div className={`${classes.digiContent} py-10 px-[15px]`}>
                            <p className='font-medium mb-1'>Promote Products Without Incurring High Advertising & Distribution Costs</p>
                            <span className='text-base'>Utilise built-in marketing tools to increase visibility affordably.</span>
                        </div>
                    </div>
                    <div className={`${classes.digiCard} ${classes.digiCard6} rounded-lg`} >
                        <div className={`${classes.digiMainHeader6} p-[15px] flex gap-[10px] items-center`}>
                            <div className={`${classes.digiHeader6} px-2 py-1 text-2xl font-semibold rounded-md`}>06</div>
                            <span className={`${classes.headerText6} font-semibold`}>Scalability</span>
                        </div>
                        <div className={`${classes.digiContent} py-10 px-[15px]`}>
                            <p className='font-medium mb-1'>Easily Adapt to Growth and Changing Market Demands</p>
                            <span className='text-base'>Expand your product range and operations with minimal additional investment.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} pb-[110px]`} id='Registration'>
                <div>
                    <h4 className={`${classes.sectionHeading} font-bold text-center mb-1`}>Your DigiStore for<span> Unlimited Trade</span></h4>
                    <h3 className={`${classes.sectionSubHeading} text-2xl font-medium mt-1 text-center mb-10`}>Connect with trusted suppliers across the globe</h3>
                </div>
                <div className='flex gap-[60px] items-center'>
                    <div><img src={digiCategories} alt="" /></div>
                    <div className='w-[717px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'> 
                                <span className={`${classes.headingText} text-5xl font-bold`}>All Steel Categories in</span><br />One Place</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-semibold'>Showcase Diverse Listings</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>List standard, non-standard, commercial, and melting scraps with detailed specifications.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Provide product visuals, mill test certificates, and comprehensive descriptions for transparency.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Organise inventory for streamlined browsing and better buyer engagement.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex gap-[60px] pt-[48px] items-center'>
                    <div className='w-[717px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'><span className={`${classes.headingText} text-5xl font-bold`}>Sell Through
</span> <br /> Competitive Bidding</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-semibold'>Auction Listings</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Host real-time auctions for surplus or scrap materials to
attract competitive buyers.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Enable dynamic pricing for maximum revenue generation.
</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Track bids in real-time with automatic winner notifications.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={digiBidding} alt="" /></div>
                </div>
                <div className='flex gap-[60px] pt-[48px] items-center'>
                    <div><img src={digiExpert} alt="" /></div>
                    <div className='w-[717px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'><span className={`${classes.headingText} text-5xl font-bold`}>Highlight Your
</span><br />Expertise</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-semibold'>Seller’s Value Added Services</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Showcase value-added services such as customisation, processing, and manufacturing.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Detail service capabilities and unique seller offerings.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Build credibility with service reviews and ratings from
previous customers.</h5>
                                    </div>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-[60px] pt-[48px] items-center'>
                    <div className='w-[717px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'><span className={`${classes.headingText} text-5xl font-bold`}>Your Identity, 
</span><br />Amplified</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-semibold'>About the Sellers</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Share your business story, certifications, and key  achievements to build trust.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Add professional credentials and a brief history of  your operations.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Highlight sustainability or ethical practices to appeal to conscious buyers.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={digiExpert} alt="" /></div>
                </div>
                <div className='flex gap-[60px] pt-[48px] items-center'>
                    <div><img src={digiExpert} alt="" /></div>
                    <div className='w-[717px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'><span className={`${classes.headingText} text-5xl font-bold`}>Stay Connected <br />
with </span>Buyers</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-semibold'>Contact Page</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Provide multiple contact options like phone, email, & integrated WhatsApp messaging.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Include a form for buyer inquiries to ensure prompt responses.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Add maps or location details for enhanced accessibility.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-[60px] pt-[48px] items-center'>
                    <div className='w-[717px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'> <span className={`${classes.headingText} text-5xl font-bold`}> Expand
Reach </span><br /> Instantly</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-semibold'>Catalog Sharing on Marketplace</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Share your digital catalogues directly on Steelbazaar’s marketplace to attract more buyers.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Enable real-time updates to showcase the latest offers
& inventory.</h5>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Gain visibility among a large buyer base searching for
steel products.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={digiExpert} alt="" /></div>
                </div>
            </div>
            <div className={classes.intoContainer}>
                <div className={`${classes.container} py-[90px]`} id='features'>
                    <h2 className={`${classes.sectionHeading} font-semibold text-center`}><span>Smart Tools</span> for <span>Smarter Sales</span> </h2>
                    <h3 className={`${classes.sectionSubHeading} text-2xl font-medium mt-1 text-center mb-10`}>Exclusive Tools for Sellers</h3>
                    <div className='flex gap-[86px]'>
                        <div className={`${classes.discover1} p-5 flex flex-col gap-5 rounded-lg items-end`}>
                            <div className='flex justify-between w-full '>
                                <h4 className={`${classes.discoverText} font-bold`}>Live <br />Price<br />Updates</h4>
                                <h1 className={`${classes.outline} ${classes.outlineCommon}`}>01</h1>
                            </div>
                            <div className={`${classes.discoverContent} flex flex-col gap-4 w-max`}>
                                <div className={`${classes.discoverCard1} ${classes.discoverCard01} rounded-lg p-[15px]`}>
                                    <p className=''>Dynamically update prices based on market conditions.</p>
                                </div>
                                <div className={`${classes.discoverCard2} ${classes.discoverCard01} rounded-lg p-[15px]`}>
                                    <p className=''>Offer competitive rates that adjust in real time.</p>
                                </div>
                                <div className={`${classes.discoverCard1} ${classes.discoverCard01} rounded-lg p-[15px]`}>
                                    <p className=''>Ensure transparency and trust in pricing.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.discover2} p-5 flex flex-col gap-5 rounded-lg items-end`}>
                            <div className='flex justify-between w-full' >
                                <h4 className={`${classes.discoverText} font-bold`}>Inventory <br />Management<br />Tools</h4>
                                <h1 className={`${classes.outline2} ${classes.outlineCommon}`}>02</h1>
                            </div>
                            <div className={`${classes.discoverContent} flex flex-col gap-4`}>
                                <div className={`${classes.discoverCard1} ${classes.discoverCard02} rounded-lg p-[15px]`}>
                                    <p className=''>Update inventory levels seamlessly after
                                        every sale.</p>
                                </div>
                                <div className={`${classes.discoverCard2} ${classes.discoverCard02} rounded-lg p-[15px]`}>
                                    <p className=''>Automate stock alerts for low
                                        inventory.</p>
                                </div>
                                <div className={`${classes.discoverCard1} ${classes.discoverCard02} rounded-lg p-[15px]`}>
                                    <p className=''>Maintain accurate stock records for smooth operations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-[86px]'>
                        <div className={`${classes.discover3} p-5 flex flex-col gap-5 rounded-lg items-end`}>
                            <div className=' flex justify-between w-full' >
                                <h4 className={`${classes.discoverText} font-bold`}>Integrated <br />
                                    Digital <br />
                                    Cart</h4>
                                <h1 className={`${classes.outline3} ${classes.outlineCommon}`}>03</h1>
                            </div>
                            <div className={`${classes.discoverContent} flex flex-col gap-4`}>
                                <div className={`${classes.discoverCard1} ${classes.discoverCard03} rounded-lg p-[15px]`}>
                                    <p className='font-semibold mb-1'>Allow buyers to add multiple products for one-click checkout.</p>
                                </div>
                                <div className={`${classes.discoverCard2} ${classes.discoverCard03} rounded-lg p-[15px]`}>
                                    <p className='font-semibold mb-1'>Provide an intuitive interface for a hassle-free purchasing journey.</p>
                                </div>
                                <div className={`${classes.discoverCard1} ${classes.discoverCard03} rounded-lg p-[15px]`}>
                                    <p className='font-semibold mb-1'>Simplify order management with automated confirmations.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.discover4} p-5 flex flex-col gap-5 rounded-lg items-end`}>
                            <div className=' flex justify-between w-full'>
                                <h4 className={`${classes.discoverText} font-bold`}>Enquiry & <br />
                                    Quotation <br /> Integration</h4>
                                <h1 className={`${classes.outline4} ${classes.outlineCommon}`}>04</h1>
                            </div>
                            <div className={`${classes.discoverContent} flex flex-col gap-4`}>
                                <div className={`${classes.discoverCard1} ${classes.discoverCard04} rounded-lg p-[15px]`}>
                                    <p className=''>Receive and respond to inquiries directly from the storefront.</p>
                                </div>
                                <div className={`${classes.discoverCard2} ${classes.discoverCard04} rounded-lg p-[15px]`}>
                                    <p className=''>Generate and send instant quotations to interested buyers.</p>
                                </div>
                                <div className={`${classes.discoverCard1} ${classes.discoverCard04} rounded-lg p-[15px]`}>
                                    <p className=''>Integrate communication with WhatsApp for seamless updates and support.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-[86px]'>
                        <div className={`${classes.discover5} p-5 flex flex-col gap-5 rounded-lg items-end`}>
                            <div className=' flex justify-between w-full'>
                                <h4 className={`${classes.discoverText} font-bold`}>Advanced <br />
                                    Analytics <br />
                                    Tools</h4>
                                <h1 className={`${classes.outline5} ${classes.outlineCommon}`}>05</h1>
                            </div>
                            <div className={`${classes.discoverContent} flex flex-col gap-4`}>
                                <div className={`${classes.discoverCard1} ${classes.discoverCard05} rounded-lg p-[15px]`}>
                                    <p className=''>Gain insights into buyer behaviour and storefront performance.</p>
                                </div>
                                <div className={`${classes.discoverCard2} ${classes.discoverCard05} rounded-lg p-[15px]`}>
                                    <p className=''>Identify popular products to optimise marketing strategies.</p>
                                </div>
                                <div className={`${classes.discoverCard1} ${classes.discoverCard05} rounded-lg p-[15px]`}>
                                    <p className=''>Track sales data for informed business decisions.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.discover6} p-5 flex flex-col gap-5 rounded-lg items-end`}>
                            <div className='flex justify-between w-full' >
                                <h4 className={`${classes.discoverText} font-bold`}>Marketing <br />
                                    Add <br />
                                    Ons</h4>
                                <h1 className={`${classes.outline6} ${classes.outlineCommon}`}>06</h1>
                            </div>
                            <div className={`${classes.discoverContent} flex flex-col gap-4`}>
                                <div className={`${classes.discoverCard1} ${classes.discoverCard06} rounded-lg p-[15px]`}>
                                    <p className='font-semibold mb-1'>Promote your storefront via Steelbazaar’s digital marketing campaigns.</p>

                                </div>
                                <div className={`${classes.discoverCard2} ${classes.discoverCard06} rounded-lg p-[15px]`}>
                                    <p className='font-semibold mb-1'>Highlight featured products for increased visibility.</p>

                                </div>
                                <div className={`${classes.discoverCard1} ${classes.discoverCard06} rounded-lg p-[15px]`}>
                                    <p className='font-semibold mb-1'>Leverage email and SMS marketing tools to reach targeted buyers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className={`${classes.secondaryBanner}`}>
                <div className={`${classes.container} ${classes.secondaryContainer} flex flex-col justify-center h-full`}>
                    <h2 className='font-semibold text-5xl mb-[10px]'>SB DigiStore <br />
                        Efficient & Transparent Inventory Management </h2>
                    <p className='font-semibold mb-[30px]'>By adopting SB DigiStock, steel industry stakeholders can transition from traditional, error-prone methods to a streamlined, efficient, and transparent digital inventory management system, fostering growth and competitiveness in the market</p>
                    <Button className={`${classes.btn3} font-bold text-base flex items-center gap-[10px] px-5 py-[10px] w-max`}> <span className={classes.btnText}>Contact Now</span><div className={`${classes.btnArrow2} flex items-center justify-center`}><ArrowForwardIcon /></div></Button>
                </div>
            </div>
            <RequestDemoTemplate />
        </>
    );
};
export default DigiStoreTemplate;
