import React, { useEffect, useState } from 'react'
import StatusCard from '../../atoms/Cards/StatusCard';
import { HTTP_STATUS } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';
import auctions from '../../../assets/icons/auction.svg';
import order from '../../../assets/icons/order.svg'
import enquiry from '../../../assets/icons/enquiry.svg'
import { useNavigate } from 'react-router-dom';
import { useSellerService } from '../../../services/useSellerService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import DisplayImageV2 from '../../molecules/ImageUpload/DisplayImageV2';
import comingSoonLogo from '../../../assets/images/comingSoonLogo.svg';


const SellerStatusDashboardTemplate: React.FC = () => {

    const sellerService = useSellerService();
    const navigate = useNavigate(); 
    const { user } = useAuthenticatedUser();

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [comingSoon, setComingSoonTo] = useState<boolean>(false);

    const [stats, setStats] = useState({
        catalogueStatsDetailsResponseDTO: {
            singleCount: 0,
            bundleLotCount: 0,
            bundleCount: 0,
            totalCount: 0
        },
        orderStatsResponseDTO: {
            ongoing: 0,
            dispatched: 0,
            delivered: 0,
            cancelled: 0,
            confirmed: 0,
            totalCount: 0,
        },
        enquiryStatsResponseDTO: {
            ongoing: 0,
            dispatched: 0,
            cancelled: 0,
            delivered: 0,
            confirmed: 0,
            totalCount: 0
        },
    });

    const handleToggleClick = (name: string, navigate: any) => {
        const sectionMap: { [key: string]: string } = {
            "Catalog": "/dashboard/catalogue",
        };
        const sectionPath = sectionMap[name];
        if (sectionPath) {
            navigate(sectionPath);
        }
        else {
            setComingSoonTo(true);
          }
    };

    const SellerStatus = () => {
        if(user?.businessId) {
        sellerService.getSellertatsForVendor(user?.businessId)
            .then((statusResponse) => {
                if (statusResponse.status === HTTP_STATUS.OK) {
                    setStats({
                        catalogueStatsDetailsResponseDTO: statusResponse?.data?.data?.catalogueStatsDetailsResponseDTO,
                        orderStatsResponseDTO: statusResponse?.data?.data?.orderStatsResponseDTO,
                        enquiryStatsResponseDTO: statusResponse?.data?.data?.enquiryStatsResponseDTO
                    })
                }
                else {
                    setStats(stats);
                }
            })
            .catch((error) => {
                setStats(stats);
                showSnackbar('error', 'Seller stats fetch failed')
            });
        }
    }
    useEffect(() => {
        SellerStatus()
    }, [user?.businessId])

    const customStats = [
        {
            icon: enquiry,
            title: "Catalog",
            value: stats?.catalogueStatsDetailsResponseDTO?.totalCount ?? 0,
            backgroundColor: '#0A12290D',
            itemspace: 3,
            Details: [
                { label: "Single", count: stats?.catalogueStatsDetailsResponseDTO?.singleCount || 0 },
                { label: "Bundle", count: stats?.catalogueStatsDetailsResponseDTO?.bundleCount || 0 },
                { label: "Bundle Lot", count: stats?.catalogueStatsDetailsResponseDTO?.bundleLotCount || 0 },
            ],
            onclick: () => handleToggleClick("Catalog", navigate),
        },
        {
            icon: enquiry,
            title: "Enquiry",
            itemspace: 3,
            value: stats?.enquiryStatsResponseDTO?.totalCount  ?? 0,
            backgroundColor: '#FFEFE6',
            Details: [
                { label: "Ongoing", count: stats?.enquiryStatsResponseDTO?.ongoing || 0 },
                { label: "Confirmed", count: stats?.enquiryStatsResponseDTO?.confirmed || 0 },
                { label: "Cancelled", count: stats?.enquiryStatsResponseDTO?.delivered || 0 },
            ],
            onclick: () => handleToggleClick("Enquiry", navigate),
        },
        {
            icon: order,
            title: "Orders",
            value: stats?.orderStatsResponseDTO?.totalCount ?? 0,
            backgroundColor: '#FEF3CD',
            itemspace: 3,
            Details: [
                { label: "Ongoing", count: stats?.orderStatsResponseDTO?.ongoing || 0 },
                { label: "Dispatched", count: stats?.orderStatsResponseDTO?.dispatched || 0 },
                { label: "Delivered", count: stats?.orderStatsResponseDTO?.delivered || 0 },
            ],
            onclick: () => handleToggleClick("Orders", navigate),
        },
        {
            icon: auctions,
            title: "My Auctions",
            value: 0,
            itemspace: 3,
            backgroundColor: '#FFEFE6',
            Details: [
                { label: "Upcoming", count: 0 || 0 },
                { label: "Auctions", count: 0 || 0 },
                { label: "Auctions", count: 0 || 0 },
            ],
            onclick: () => handleToggleClick("My Auctions", navigate),
        },
    ];

    return (
        <div>
            <DisplayImageV2
                path={comingSoonLogo}
                setImageDialogOpenTo={setComingSoonTo}
                imageDialogOpen={comingSoon}
                showCloseIcon={true} />
            <StatusCard cards={customStats} />
        </div>
    )
}

export default SellerStatusDashboardTemplate;