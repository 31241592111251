import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Classes } from 'jss';
import { createUseStyles } from "react-jss";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";
import Button from "../../atoms/Button/Button";
import AuthDialogFooter from "../../molecules/AuthDialogFooter/AuthDialogFooter";
import { useAuthService } from "../../../services/useAuthService";
import { validatePhoneNumber } from "../../../utils/helper";
import { AUTH_STATES, HTTP_STATUS } from "../../../utils/types";
import { InputAdornment } from "@mui/material";

interface LoginWithMobileTemplateProps {
    mobileNumber?: string;
    updateUserDetails: (name: string, value: string) => void;
    setAuthState: (state: AUTH_STATES) => void;
    styles: Classes<any>;
    setShowPopup?: Dispatch<SetStateAction<boolean>>;
    showFooter?: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        rowGap: "24px"
    },
    sectionText: {
        color: theme.palette.text.primaryDarkAccent
    },
    background: {
        background: theme.palette.background.secondaryLightAccent
    },
    content: {
        color: theme.palette.button.primaryLight
    },
    phoneNumber: {
        color: theme.palette.text.neutral.neutral400,
    },
    "@media (max-width: 767px)": {
        container: {
            rowGap: "10px"
        },
    }
}));

const LoginWithMobileTemplate: React.FC<LoginWithMobileTemplateProps> = ({ setAuthState, mobileNumber, updateUserDetails, styles, setShowPopup, showFooter }) => {
    const classes = useStyles();

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const authService = useAuthService();

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value.length > 10) return;
        updateUserDetails('mobileNumber', value);
        if (!validatePhoneNumber(value))
            setErrorMessage('Please enter a valid phone number');
        else setErrorMessage('');
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (errorMessage) return;
        if (mobileNumber?.length !== 10) {
            setErrorMessage('Please enter a valid phone number');
            return;
        }
        setIsLoading(true)
        authService.login({ mobileNumber })
            .then((res => {
                if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                    setErrorMessage('Please enter a registered phone number');
                } else if (res?.status === HTTP_STATUS.OK) {
                    setAuthState(AUTH_STATES.OTP_VERIFICATION)
                }
            })).catch(error => {
                console.error("Some Error Occured:", error)
            }).finally(() => setIsLoading(false))
    };

    const setAuthStateTo = (authState: AUTH_STATES) => () => {
        setAuthState(authState);
    }

    return (
        <div >
            <form className={`${classes.container} grid`} onSubmit={handleSubmit}>
                <TextFieldV2
                    fullWidth
                    label="Phone Number"
                    type="tel"
                    placeholder="Enter Here"
                    value={mobileNumber}
                    onChange={handlePhoneNumberChange}
                    error={errorMessage !== ""}
                    helperText={errorMessage !== "" && errorMessage}
                    InputProps={{
                        className: "h-[49px] text-base ",
                        startAdornment: (
                            <InputAdornment position="start" className={``}>
                                <div
                                    className={`${classes.phoneNumber} font-normal !-mr-3 !-ml-0.5`}
                                >
                                    +91
                                </div>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button variant="primaryContained" label="Get OTP" type="submit" isLoading={isLoading} />
            </form>
            <AuthDialogFooter setAuthStateTo={setAuthStateTo} section={AUTH_STATES.USER_SIGNUP} loginType={AUTH_STATES.LOGIN_WITH_EMAIL} isLogin={true} showFooter={showFooter} />
        </div>
    );
}

export default LoginWithMobileTemplate;