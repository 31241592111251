import React from 'react';
import { createUseStyles } from 'react-jss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import digiStockBanner from '../../../assets/images/digiStockBanner.png';
import digiSku from '../../../assets/images/digiSku.png';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import primaryBlueTick from '../../../assets/icons/primaryBlueTick.jpg';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import register from '../../../assets/images/register.jpg';
import verifiedTick2 from '../../../assets/icons/verifiedTick2.svg';
import DigitalSecondaryBanner from '../../../assets/images/digitalSecondaryBanner.png';
import { Button } from '@mui/joy';
import roudTickIcon1 from '../../../assets/icons/roudTickIcon1.svg';
import roudTickIcon2 from '../../../assets/icons/roudTickIcon2.svg';
import logistics from '../../../assets/icons/logistics.svg';
import cardrupee from '../../../assets/images/cardrupee.png';
import creatingSku from '../../../assets/images/creatingSku.png';
import buildInventory from '../../../assets/images/buildInventory.png';
import infoUsn from '../../../assets/images/infoUsn.png';
import visualImages from '../../../assets/images/visualImages.png';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${digiStockBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    bannerSellContainer: {
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: '#fff',
        "& span": {
            color: theme.palette.text.warning.warning400,
        },
    },
    bannerDescription: {
        color: theme.palette.text.primary.primary50,
        lineHeight: '28px',
        "& span": {
            color: theme.palette.text.warning.warning400,
        },
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    primaryText: {
        fontSize: '54px',
        color: '#000',
        lineHeight: '66px',
    },
    decContainer: {
        width: '750px',
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontWeight: '400',
        },
    },
    sectionHeading: {
        fontSize: '54px !important',
        lineHeight: '66px',
        color: '#000',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    inventoryContainer: {
        "& h4": {
            color: theme.palette.text.neutral.neutral800,
        },
    },
    inventoryCard1: {
        border: `1px solid ${theme.palette.border.complementary.complementary400}`,
        background: theme.palette.background.complementary.complementary50,
        "& h6": {
            color: theme.palette.text.complementary.complementary600,
        },
        "& p": {
            color: theme.palette.text.complementary.complementary700,
        },
    },
    inventoryCard2: {
        border: `1px solid ${theme.palette.border.warning.warning400}`,
        background: theme.palette.background.warning.warning50,
        "& h6": {
            color: theme.palette.text.warning.warning600,
        },
        "& p": {
            color: theme.palette.text.warning.warning700,
        },
    },
    inventoryCard3: {
        border: '1px solid #FD8235',
        background: '#FFEFE6',
        "& h6": {
            color: '#CA4F02',
        },
        "& p": {
            color: '#85141B',
        },
    },
    inventoryCard4: {
        border: `1px solid ${theme.palette.border.secondary.secondary400}`,
        background: theme.palette.background.secondary.secondary50,
        "& h6": {
            color: theme.palette.text.secondary.secondary600,
        },
        "& p": {
            color: theme.palette.text.secondary.secondary700,
        },
    },
    designContainer: {
        "& > h2": {
            color: theme.palette.text.neutral.neutral800,
        },
        "& > h3": {
            color: theme.palette.text.neutral.neutral600,
        },
    },
    designCard: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff',
        width: '350px',
    },
    sellerContainer: {
        background: theme.palette.background.primary.primary50,
        position: "relative",
    },
    buyerContainer: {
        background: theme.palette.background.primary.primary500,
    },
    sellerHeading: {
        fontSize: '40px',
        lineHeight: '48px',
        color: theme.palette.text.neutral.neutral800,
        borderBottom: `1px solid ${theme.palette.border.primary.primary500}`,
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary500,
        },
    },
    benifitContent: {
        "& h5": {
            fontSize: '28px',
            lineHeight: '34px',
            color: '#000',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '22px',
            lineHeight: '26px',
        },
    },
    buyerHeading: {
        color: '#fff',
        fontSize: '40px',
        lineHeight: '48px',
        borderBottom: '1px solid #fff',
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.warning.warning500,
        },
    },
    benifitContentBuyer: {
        "& h5": {
            fontSize: '28px',
            lineHeight: '34px',
            color: '#fff',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral50,
            fontSize: '22px',
            lineHeight: '26px',
        },
    },
    benifitExtra: {
        position: 'absolute',
        top: '154px',
        right: '-25px',
    },
    extra2: {
        background: theme.palette.background.primary.primary200,
        width: '24px',
        height: '24px',
    },
    extra1: {
        background: theme.palette.background.primary.primary500,
        width: '24px',
        height: '24px',
    },
    tradeHeader: {
        background: theme.palette.background.success.success400,
        fontSize: '32px',
        color: '#fff',
        textAlign: 'right',
        width: '580px',
    },
    traderContent: {
        background: theme.palette.background.success.success50,
        borderRight: `2px solid ${theme.palette.border.success.success400}`,
        borderLeft: `2px solid ${theme.palette.border.success.success400}`,
        color: '#000',
        lineHeight: '26px',
        fontSize: '22px',
        textAlign: 'right',
        width: '580px',

    },
    aspectHeader: {
        background: '#000',
        fontSize: '28px',
        lineHeight: '38px',
        color: '#fff',
        width: '120px',
        paddingTop: '20px',
    },
    aspectContent: {
        width: '120px',
        fontSize: '18px',
        fontWeight: '600',
        lineHeight: '22px',
        borderTop: '1px solid #000',
        borderBottom: '1px solid #000',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inventoryContent: {
        background: theme.palette.background.complementary.complementary50,
        borderRight: `2px solid ${theme.palette.border.complementary.complementary400}`,
        borderLeft: `2px solid ${theme.palette.border.complementary.complementary400}`,
        color: '#000',
        lineHeight: '26px',
        fontSize: '22px',
        textAlign: 'left',
        width: '580px',
    },
    inventoryHeader: {
        background: theme.palette.background.complementary.complementary400,
        fontSize: '32px',
        color: '#fff',
        textAlign: 'left',
        width: '580px',
        paddingTop: '17px',
    },
    methods: {
        borderRadius: '32px',
        background: theme.palette.background.warning.warning400,
        color: '#000',
        fontSize: '40px',
        lineHeight: '48px',
        width: '64px',
        height: '64px',
        padding: '8px',
        margin: '0 auto',
    },
    featuresContainer: {
        "& > p": {
            color: theme.palette.text.neutral.neutral600,
        },
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary700,
        },
        "& h4": {
            color: theme.palette.text.neutral.neutral800,
            "& span": {
                color: theme.palette.text.primary.primary500,
                fontWeight: '600',
            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    headingText: {
        fontSize: '54px !important',
        "& span": {
            color: `${theme.palette.text.primary.primary500} !important`,
        },
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '28px',
    },

    digiCard: {
        width: '405px',
        borderRadius: '16px',
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        overflow: 'hidden',
    },
    digiCardHeader: {
        background: '#fff',
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`,
    },
    cardIcon: {
        borderRadius: '16px',
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff !important',
 
    },
    cardText: {
        "& h6": {
            color: '#000',
            lineHeight: '26px',
        },
        "& p": {
            color: '#000',
            lineHeight: '20px',
        },
    },
    secondaryBanner: {
        width: '100%',
        height: '480px',
        backgroundImage: `url(${DigitalSecondaryBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    secondaryContainer: {
        '& h2': {
            fontSize: '40px',
            color: theme.palette.text.warning.warning400,
            lineHeight: '48px',
        },
        "& p": {
            color: theme.palette.text.primary.primary50,
            lineHeight: '28px',
        },
    },
    btn3: {
        background: '#fff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btnText: {
        color: theme.palette.text.primary.primary500,
        fontSize: '20px',
    },
    cardsContainer: {
        background: '#fff',
        "& p": {
            fontSize: '28px',
        },
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        padding: '20px 15px',
        width: '24%',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '16px',
            lineHeight: '20px',
        },
    },
    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#Fff',
    },

}));
const cardData = [
    {
        img: logistics,
        title: "Improved Quality Assurance",
        description:
            "Detailed digital records help buyers verify material specifications, certifications, and supplier performance before placing orders.",
    },
    {
        img: logistics,
        title: `Real-Time`,
        title2: 'Visibility',
        description:
            "Instant stock level updates enable informed decision-making and quick response to demand fluctuations.",
    },
    {
        img: logistics,
        title: "Improved Supplier",
        title2: 'Coordination',
        description:
            "Real-time inventory data fosters transparent communication with suppliers, ensuring timely restocks and stable supply relationships.",
    },
    {
        img: logistics,
        title: "Faster Replenishment",
        title2: 'Cycles',
        description:
            "Automated reordering triggers ensure materials are delivered just in time, shortening lead times and improving production flow.",
    },
    {
        img: logistics,
        title: "Optimising Stock",
        title2: 'Levels',
        description:
            "Ensuring the right amount of inventory is maintained to meet demand without excess.",
    },
    {
        img: logistics,
        title: "Improving Cash",
        title2: 'Flow',
        description:
            "Reducing capital tied up in unsold stock, allowing for better financial flexibility.",
    },
    {
        img: logistics,
        title: "Enhancing Customer",
        title2: 'Satisfaction',
        description:
            "Enabling timely order fulfillment through accurate stock tracking.",
    },
    {
        img: logistics,
        title: "Enhanced Forecast",
        title2: 'Accuracy',
        description:
            "Historical data & advanced analytics improve demand forecasting, allowing more accurate & strategic procurement planning.",
    },
];

const DigiStockTemplate: React.FC = () => {
    const classes = useStyles();

    return (

        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className={`${classes.heading} font-semibold mb-[28px]`}><span>SB DigiStock</span></h1>
                    <h1 className={`${classes.heading} font-semibold`}>Transparent Quality, <br />Real-Time Updates,<br />No Overstocks & <br />Shortages</h1>
                </div>
            </div>
            <div className={`${classes.intoContainer}`}>
                <div className={`${classes.container} flex items-center justify-between gap-[60px] py-14`}>
                    <div className=''>
                        <p className={`${classes.primaryText} font-medium`}>Digitise Your <br />
                            Steel Stocks for <br /> Operational <br /> Success</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-lg mb-4'>Digitising inventory on Steelbazaar offers transformative benefits to both buyers and sellers. For buyers, real-time updates, predictive analytics, and detailed material records enable informed decision-making, transparent quality assurance, and cost optimisation by maintaining ideal stock levels. Sellers gain enhanced visibility into market demand, efficient stock management, and the ability to showcase verified products to a broader audience, boosting credibility and sales.</p>
                        <p className='text-lg'>Building inventory on Steelbazaar is seamless, with user-friendly tools that simplify listing, tracking, & updating stock. The platform fosters collaboration, reduces inefficiencies, & ensures a smooth, data-driven procurement process for all stakeholders in the steel ecosystem.</p>
                    </div>
                </div>
            </div>
            <div className={classes.cardsContainer} id='features'>
                <div className={`${classes.container} pt-[110px] pb-[110px]`}>
                    <div>
                        <h4 className={`${classes.sectionHeading} font-bold text-center`}><span>Digital Inventory </span>Management is Essential</h4>
                    </div>
                    <div className="flex gap-4 flex-wrap mt-[36px]">
                        {cardData.map((card, index) => (
                            <div key={index} className={`${classes.card} rounded-lg`}>
                                <div
                                    className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}
                                >
                                    <img src={card.img} alt={card.title} />
                                </div>
                                <h4 className="mt-[10px] font-semibold">{card.title}</h4>
                                <h4 className="font-semibold mb-3">{card.title2}</h4>
                                <p className="text-base leading-5">{card.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={`${classes.intoContainer} py-[90px]`}>
                <div className={`${classes.container}`}>
                    <h2 className={`${classes.sectionHeading} font-semibold mb-20 text-center`}>How to <span>Digitize Your Steel Stocks </span>on Steelbazaar</h2>
                    <div className={`${classes.inventoryContainer}`}>
                        <h4 className='font-semibold text-[40px] leading-[48px] my-10'>Digitizing All Type of Steel Inventories</h4>
                        <div className='flex gap-4'>
                            <div className={`${classes.inventoryCard1} rounded-lg py-10 px-5`}>
                                <h6 className='text-[22px] font-semibold leading-[26px] mb-2'>Standard Inventory</h6>
                                <p className='leading-5 text-base'>Regular steel products adhering to industry standards.</p>
                            </div>
                            <div className={`${classes.inventoryCard2} rounded-lg py-10 px-5`}>
                                <h6 className='text-[22px] font-semibold leading-[26px] mb-2'>Non-Standard Inventory</h6>
                                <p className='leading-5 text-base'>Custom or unique steel products with specific specifications.</p>
                            </div>
                            <div className={`${classes.inventoryCard3} rounded-lg py-10 px-5`}>
                                <h6 className='text-[22px] font-semibold leading-[26px] mb-2'>Commercial Scraps</h6>
                                <p className='leading-5 text-base'>Usable scrap materials generated during production.</p>
                            </div>
                            <div className={`${classes.inventoryCard4} rounded-lg py-10 px-5`}>
                                <h6 className='text-[22px] font-semibold leading-[26px] mb-2'>Melting Scraps</h6>
                                <p className='leading-5 text-base'>Scrap materials designated for re-melting and recycling.</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.designContainer} pt-[90px]`}>
                        <h2 className='text-[40px] leading-12 font-semibold mb-1'>Specially Designed SKUs for The Steel Industry</h2>
                        <h3 className='text-2xl font-medium leading-[28px] mb-10'>Specific attributes to ensure precise identification and standardisation</h3>
                        <div className='flex gap-[15px] items-center justify-between'>
                            <div className='flex flex-col gap-4'>
                                <div className={`${classes.designCard} py-5 px-[15px] rounded-lg`}>
                                    <span className='text-[22px] font-medium leading-[26px]'>Product Category</span>
                                    <p className='text-base leading-5 pt-3'>Classification of the steel product (e.g., coil, sheet, rod).</p>
                                </div>
                                <div className={`${classes.designCard} py-5 px-[15px] rounded-lg`}>
                                    <span className='text-[22px] font-medium leading-[26px]'>Standard</span>
                                    <p className='text-base leading-5 pt-3'>Applicable industry standards (e.g., BIS, ASTM, ISO).</p>
                                </div>
                                <div className={`${classes.designCard} py-5 px-[15px] rounded-lg`}>
                                    <span className='text-[22px] font-medium leading-[26px]'>Grades</span>
                                    <p className='text-base leading-5 pt-3'>Material grade indicating quality and composition.</p>
                                </div>
                                <div className={`${classes.designCard} py-5 px-[15px] rounded-lg`}>
                                    <span className='text-[22px] font-medium leading-[26px]'>Size Specifications</span>
                                    <p className='text-base leading-5 pt-3'>Dimensions such as thickness, width, and length.</p>
                                </div>
                            </div>
                            <div><img src={digiSku} alt="" /></div>
                            <div className='flex flex-col gap-4'>
                                <div className={`${classes.designCard} py-5 px-[15px] rounded-lg`}>
                                    <span className='text-[22px] font-medium leading-[26px]'>Coatings</span>
                                    <p className='text-base leading-5 pt-3'>Type of surface treatment or coating <br /> applied.</p>
                                </div>
                                <div className={`${classes.designCard} py-5 px-[15px] rounded-lg`}>
                                    <span className='text-[22px] font-medium leading-[26px]'>Chemical Composition</span>
                                    <p className='text-base leading-5 pt-3'>Detailed breakdown of elemental constituents.</p>
                                </div>
                                <div className={`${classes.designCard} py-5 px-[15px] rounded-lg`}>
                                    <span className='text-[22px] font-medium leading-[26px]'>Mechanical Properties</span>
                                    <p className='text-base leading-5 pt-3'>Attributes like tensile strength, yield strength, and elongation.</p>
                                </div>
                                <div className={`${classes.designCard} py-5 px-[15px] rounded-lg`}>
                                    <span className='text-[22px] font-medium leading-[26px]'>Other Properties</span>
                                    <p className='text-base leading-5 pt-3'>Additional characteristics relevant to the product's application.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} pb-[110px] pt-[90px]`} id='Registration'>
                <h2 className={`${classes.sectionHeading} font-semibold text-center !text-[53px]`}>Steps for Creating Digital Inventory on Steelbazaar</h2>
                <p className={`${classes.subHeading} text-center text-2xl font-medium mt-[10px] mb-10`}>A Standard Inventory</p>
                <div className={`${classes.subHeading} text-[40px] font-semibold leading-12`}>A) Standard Inventory</div>
                <div className='flex gap-[60px] items-center pb-[90px]'>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 01:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Selecting or<br /><span className={`${classes.headingText} text-5xl font-bold`}>Creating SKUs</span></h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Pre-defined SKUs</h5>
                                        <p className='text-lg'>Choose from existing SKUs of renowned brands like TATA, JSW, SAIL, AMNS, & JSPL, which come with standardized specifications.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Custom SKUs</h5>
                                        <p className='text-lg'>Create unique SKUs tailored to specific products, with the option to associate them with particular brands.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={creatingSku} alt="" /></div>
                </div>
                <div className='flex gap-[60px] items-center pb-[90px]'>
                    <div><img src={buildInventory} alt="" /></div>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 02:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Building Inventory for<br /><span className={`${classes.headingText} text-5xl font-bold`}>Selected SKUs</span></h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Specify Quantity</h5>
                                        <p className='text-lg'>Indicate the number of coils or bundles for the chosen SKU.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Assign Unique Serial Numbers (USNs)</h5>
                                        <p className='text-lg'>Each unit is given a USN for individual tracking.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-[60px] items-center pb-[90px]'>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 03:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Entering Detailed <br />
                                Information for <span className={`${classes.headingText} text-5xl font-bold`}>Each USN</span></h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Weight Details</h5>
                                        <p className='text-lg'>Record both gross and net weight.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Location</h5>
                                        <p className='text-lg'>Specify the storage or warehouse location.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Packaging Information</h5>
                                        <p className='text-lg'>Describe packaging type and condition.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Buyer Information</h5>
                                        <p className='text-lg'>Include any buyer-specific details or requirements.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Mill Test Certificate Availability</h5>
                                        <p className='text-lg'>Indicate if a mill test certificate is available for the unit.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Visual Documentation</h5>
                                        <p className='text-lg'>Upload images of each USN to provide a visual reference.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={infoUsn} alt="" /></div>
                </div>
                <div className='flex gap-[60px] items-center pb-[90px]'>
                    <div><img src={visualImages} alt="" /></div>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 04:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Add
                                <br /><span className={`${classes.headingText} text-5xl font-bold`}>Visual Images</span></h4>
                        </div>
                        <p>Set up your account to begin your journey with SteelBazaar. Provide basic information about <br /> your business and create a secure profile.</p>
                    </div>
                </div>
                <div className='flex gap-[60px] items-center pb-[90px]'>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-medium'>Step 05:</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Viewing and
                                <br /><span className={`${classes.headingText} text-5xl font-bold`}>Managing Inventory</span></h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Tabular Display</h5>
                                        <p className='text-lg'>Access a comprehensive table listing all SKUs and their associated USNs, complete with details like ageing and other pertinent inventory information</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={cardrupee} alt="" /></div>
                </div>
                <div className='flex gap-[30px]'>
                    <div className={`${classes.digiCard}`}>
                        <div className={`${classes.digiCardHeader} p-[15px] font-semibold text-2xl`}>
                            B) Non-Standard Inventory
                        </div>
                        <div className='flex gap-3 items-center py-10 px-[15px]'> 
                            <div className={`${classes.cardIcon} p-[10px]`}>
                                <img src={verifiedTick2} alt="" />
                            </div>
                            <div className={`${classes.cardText} flex flex-col`}>
                            <h6 className='font-semibold mb-1'>Detailed Entry</h6>
                            <p >Convert invoices into immediate cash.</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.digiCard}`}>
                        <div className={`${classes.digiCardHeader} p-[15px] font-semibold text-2xl`}>
                         C) Commercial Scraps
                        </div>
                        <div className='flex gap-3 items-center py-10 px-[15px]'> 
                            <div className={`${classes.cardIcon} p-[10px]`}>
                                <img src={verifiedTick2} alt="" />
                            </div>
                            <div className={`${classes.cardText} flex flex-col`}>
                            <h6 className='font-semibold mb-1'>Comprehensive <br /> Documentation</h6>
                            <p >Sell to pre-approved buyers for risk-free deals.</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.digiCard}`}>
                        <div className={`${classes.digiCardHeader} p-[15px] font-semibold text-2xl`}>
                        D) Melting Scraps
                        </div>
                        <div className='flex gap-3 items-center py-10 px-[15px]'> 
                            <div className={`${classes.cardIcon} p-[10px]`}>
                                <img src={verifiedTick2} alt="" />
                            </div>
                            <div className={`${classes.cardText} flex flex-col`}>
                            <h6 className='font-semibold mb-1'>Specific Details</h6>
                            <p >Provide relevant data such as <br /> material composition, weight, and <br /> intended use for recycling purposes.</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div>
                <h2 className={`${classes.sectionHeading} font-semibold mb-10 text-center my-10`}>Benefits of Digital Inventory Management</h2>
                <div className='flex'>
                    <div className={`${classes.sellerContainer} w-1/2 py-10 px-[80px]`}>
                        <div className={`${classes.sellerHeading} font-semibold`}>For <span>Sellers</span></div>
                        <div className='flex flex-col gap-8 mt-4'>
                            <div className='flex items-start gap-3'>
                                <img src={roudTickIcon1} alt="" />
                                <div className={`${classes.benifitContent}`}>
                                    <h5 className='font-semibold'>Enhanced Visibility</h5>
                                    <p>Real-time tracking of inventory levels and <br /> locations.</p>
                                </div>
                            </div>
                            <div className='flex items-start gap-3'>
                                <img src={roudTickIcon1} alt="" />
                                <div className={`${classes.benifitContent}`}>
                                    <h5 className='font-semibold'>Improved Accuracy</h5>
                                    <p>Minimization of errors associated with manual data<br />entry</p>
                                </div>
                            </div>
                            <div className='flex items-start gap-3'>
                                <img src={roudTickIcon1} alt="" />
                                <div className={`${classes.benifitContent}`}>
                                    <h5 className='font-semibold'>Operational Efficiency</h5>
                                    <p>Streamlined processes leading to faster order<br /> fulfilment.</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.benifitExtra}`}>
                            <div className='flex flex-col gap-[120px]'>
                                <div className='flex'>
                                    <div className={`${classes.extra1}`}>   
                                    </div>
                                    <div className={`${classes.extra2}`}>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <div className={`${classes.extra1}`}>
                                    </div>
                                    <div className={`${classes.extra2}`}>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={`${classes.buyerContainer} w-1/2 py-10 px-[80px]`}>
                        <div className={`${classes.buyerHeading} font-semibold`}>For <span>Buyer</span></div>
                        <div className='flex flex-col gap-8 mt-4'>
                            <div className='flex items-start gap-3'>
                                <img src={roudTickIcon2} alt="" />
                                <div className={`${classes.benifitContentBuyer}`}>
                                    <h5 className='font-semibold'>Transparency</h5>
                                    <p>Access to detailed product information,<br />specifications & certifications.</p>
                                </div>
                            </div>
                            <div className='flex items-start gap-3'>
                                <img src={roudTickIcon2} alt="" />
                                <div className={`${classes.benifitContentBuyer}`}>
                                    <h5 className='font-semibold'>Informed Purchasing Decisions</h5>
                                    <p>Ability to view product images & documentation <br />before purchase.</p>
                                </div>
                            </div>
                            <div className='flex items-start gap-3'>
                                <img src={roudTickIcon2} alt="" />
                                <div className={`${classes.benifitContentBuyer}`}>
                                    <h5 className='font-semibold'>Reliable Supply</h5>
                                    <p>Assurance of product availability and timely <br />delivery.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} py-[110px]`}>
                <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Traditional<span> Methods</span></h2>
                <div className={`${classes.methods}`}>v/s</div>
                <h2 className={`${classes.sectionHeading} font-semibold text-center mb-10`}><span>Digital </span>Inventory Management</h2>
                <div className='flex items-center'>
                    <div>
                        <div className='flex item-center'>
                            <div className={`${classes.tradeHeader} mb-3 font-semibold p-3`}>
                                Traditional Methods
                            </div>
                            <div className={`${classes.aspectHeader} mb-3 font-semibold p-3`}>
                                Aspect
                            </div>
                            <div className={`${classes.inventoryHeader} mb-3 font-semibold p-3`}>
                                Digital Inventory Management
                            </div>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <div className='flex'>
                                <div className={`${classes.traderContent} py-3 px-2`}>
                                    Prone to Errors Due to Manual Entry
                                </div>
                                <div className={`${classes.aspectContent} px-4`}>
                                    Data <br />Accuracy
                                </div>
                                <div className={`${classes.inventoryContent} py-3 px-2`}>
                                    High Precision with Automated Systems
                                </div>
                            </div>
                            <div className='flex'>
                                <div className={`${classes.traderContent} py-3 px-2`}>
                                    Cumbersome Access to Physical Records
                                </div>
                                <div className={`${classes.aspectContent} px-4`}>
                                    Accessibility
                                </div>
                                <div className={`${classes.inventoryContent} py-3 px-2`}>
                                    Instant Retrieval Through Digital Platforms
                                </div>
                            </div>
                            <div className='flex'>
                                <div className={`${classes.traderContent} py-3 px-2`}>
                                    Time-Consuming Tracking Processes
                                </div>
                                <div className={`${classes.aspectContent} px-4`}>
                                    Efficiency
                                </div>
                                <div className={`${classes.inventoryContent} py-3 px-2`}>
                                    Automated, Saving Time and Resources
                                </div>
                            </div>
                            <div className='flex'>
                                <div className={`${classes.traderContent} py-3 px-2`}>
                                    Based on Outdated or Inaccurate Data
                                </div>
                                <div className={`${classes.aspectContent} px-4`}>
                                    Decision <br />Making
                                </div>
                                <div className={`${classes.inventoryContent} py-3 px-2`}>
                                    Real-Time Data Supports Strategic Planning
                                </div>
                            </div>
                            <div className='flex'>
                                <div className={`${classes.traderContent} py-3 px-2`}>
                                    Based on Outdated or Inaccurate Data
                                </div>
                                <div className={`${classes.aspectContent} px-4`}>
                                    Customer<br />Service
                                </div>
                                <div className={`${classes.inventoryContent} py-3 px-2`}>
                                    Real-Time Data Supports Strategic Planning
                                </div>
                            </div>
                            <div className='flex'>
                                <div className={`${classes.traderContent} py-3 px-2`}>
                                    Delays Due to Lack of Real-Time Information
                                </div>
                                <div className={`${classes.aspectContent} px-4`}>
                                Customer <br />Service
                                </div>
                                <div className={`${classes.inventoryContent} py-3 px-2`}>
                                    Prompt Responses Enabled by Up-to-Date InventoryData
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.secondaryBanner}`}>
                <div className={`${classes.container} ${classes.secondaryContainer} flex flex-col justify-center h-full`}>
                    <h2 className='font-semibold mb-[10px]'>SB DigiStock <br />Efficient & Transparent Inventory Management </h2>
                    <p className='font-semibold mb-[30px] text-2xl'>By adopting SB DigiStock, steel industry stakeholders can transition from traditional,<br /> error-prone methods to a streamlined, efficient, and transparent digital inventory <br /> management system, fostering growth and competitiveness in the market</p>
                    <Button className={`${classes.btn3} font-bold text-base flex items-center gap-[10px] px-5 py-[10px] w-max`}> <span className={classes.btnText}>Contact Now</span><div className={`${classes.btnArrow2} flex items-center justify-center`}><ArrowForwardIcon /></div></Button>
                </div>
            </div>
            <RequestDemoTemplate />
        </>
    );
};
export default DigiStockTemplate;
