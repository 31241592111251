import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/joy';
import auctionBanner from '../../../assets/images/auctionBanner.jpg';
import auctionSecondaryBanner from '../../../assets/images/auctionSecondaryBanner.png';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import register from '../../../assets/images/register.jpg';
import auction1 from '../../../assets/images/auction1.png';
import auction2 from '../../../assets/images/auction2.png';
import auction3 from '../../../assets/images/auction3.png';
import auction4 from '../../../assets/images/auction4.png';
import reach from '../../../assets/icons/reach.svg';
import forwardAuction from '../../../assets/images/forwardAuction.png';
import reverseAcution from '../../../assets/images/reverseAcution.png';
import participate from '../../../assets/images/participate.png';
import bulkProcurement from '../../../assets/images/bulkProcurement.png';
import priceTool from '../../../assets/images/priceTool.png';
import source_card_icon1 from '../../../assets/icons/source_card_icon1.jpg';
import source_card_icon2 from '../../../assets/icons/source_card_icon2.jpg';
import source_card_icon3 from '../../../assets/icons/source_card_icon3.jpg';
import source_card_icon4 from '../../../assets/icons/source_card_icon4.jpg';
import { useLocation } from 'react-router-dom';


const useStyles = createUseStyles((theme: any) => ({
    container: {
        maxWidth: '1280px',
        margin: '0 auto',
    },
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${auctionBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    bannerSellContainer: {  
        width: '1280px',
        margin: '0 auto',      
        "& h1": {
            fontSize: '56px',
            lineHeight: '66px',
            color: theme.palette.text.warning.warning400,
            
        },
        "& p": {
            color: '#fff',
            lineHeight: '66px',
            fontSize: '56px',
        },
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    text: {
        fontSize: '54px',
        color: "#000",
        lineHeight: '66px',
    },
    decContainer: {
        width: '672px',
        "& p": {
            color: "#000",
            lineHeight: '26px',
        },
    },
    headingText: {
        fontSize: '48px !important',
        "& span": {
            color: `${theme.palette.text.primary.primary500} !important`,
        },
    },
    featuresContainer: {
        "& > p": {
            color: theme.palette.text.neutral.neutral600,
        },
        // "& span": {
        //     fontSize: '40px',
        //     lineHeight: '48px',
        //     color: theme.palette.text.primary.primary700,
        // },
        "& h4": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '48px',
            "& span": {
                fontWeight: '600',
            color: theme.palette.text.primary.primary500,
            fontSize: '48px',

            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& > p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '38px',
            fontSize: '32px',
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    platformHeading: {
        fontSize: '54px',
        color: '#000',
        lineHeight: '66px',
    },
    platformSubHeading: {
        color: theme.palette.text.neutral.neutral600,
        fontSize: '28px',
        lineHeight: '34px',
    },
    platformImg: {
        position: "relative"
    },
    platformText: {
        position: 'absolute',
        top: "50%",
        left: '52%',
        width: '100%',
        transform: 'translate(-50%, -50%)',
        color: '#000',
        lineHeight: '34px',
        textAlign: 'center',
        fontSize: '28px',
    },
    platfomrContainer: {
        background: '#F8F8F8',
    },
    borderDiv: {
        background: '#000',
        width: '2px',
        height: '25px',
    },
    platformSecText: {
        color: '#000',
    },
    secondaryPlatform: {
        position: 'relative',
        top: '-105px',
    },
    cardsContainer: {
        background: '#fff',
        "& p": {
            lineHeight: '26px',
            color: theme.palette.text.neutral.neutral600,
            fontSize: '28px',
        },
    },
    bannerBtn: {
        background: theme.palette.background.primary.primary500,
        padding: '10px 20px',
        width: '228px',
        color: '#fff',
        margin: '0 auto',
    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '100%',
    },
    sectionHeading: {
        color: "#000",
        fontSize: '54px',
        lineHeight: '66px',
        "& span": {
            color: "#000",
            fontSize: '54px',
            lineHeight: '66px',
        },
        "& > h4": {
            fontSize: '54px',
            lineHeight: '66px',
            color: theme.palette.text.primary.primary500,

        }
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        padding: '20px 15px',
        width: '23%',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '16px',
            lineHeight: '26px',
        },
    },
    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff',
    },
    secondaryBanner: {
        width: '100%',
        height: '480px',
        backgroundImage: `url(${auctionSecondaryBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    secondaryContainer: {
        '& h2': {
            color: theme.palette.text.warning.warning400,
            lineHeight: '58px',
        },
        "& p": {
            color: theme.palette.text.primary.primary50,
            lineHeight: '38px',
            fontSize: '32px',
        },  
    },
    btn3: {
        background: '#fff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btnText: {
        color: theme.palette.text.primary.primary500,
        fontSize: '20px',
    },
}));

const cardData = [
    {
        img: source_card_icon1,
        title: "Competitive Pricing",
        description:
            "Auctions help secure the best possible pricing for both buyers and sellers.",
    },
    {
        img: source_card_icon2,
        title: "Real-Time Transparency",
        description:
            "Get live updates on bids and pricing fluctuations to make informed decisions.",
    },
    {
        img: source_card_icon3,
        title: "Large Network ",
        description:
            "Access a vast network of steel buyers and sellers for optimal market exposure.",
    },
    {
        img: source_card_icon4,
        title: "Efficient Transactions",
        description:
            "Complete transactions securely and efficiently through our platform.",
    },
];

const AcutionTemplate: React.FC = () => {

    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);
    
    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.bannerSellContainer}`}>
                    <h1 className='font-semibold mb-16'>SB BidHub</h1>
                    <p className='font-semibold mt-4 mb-4'>Buy Smarter,<br />Sell Faster<br />Your Auction Marketplace</p>
                </div>
            </div>
            <div className={`${classes.intoContainer} mb-20`}>
                <div className={`${classes.container} flex items-center gap-10 py-14 justify-between`}>
                    <div className=''>
                        <p className={`${classes.text} font-semibold mb-2`}>Real-Time<br />Transparent Biddings</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-lg mb-4'>SteelBazaar introduces a cutting-edge auction service designed for both buyers and sellers of steel raw materials and finished products. Whether you're looking to procure steel at competitive prices through reverse procurement or sell your inventory through forward auctions, SteelBazaar provides a streamlined platform for real-time, transparent bidding.</p>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} pb-[110px]`} id='Registration'>
                <div className={classes.sectionHeading}>
                    <h4 className='mt-[6px] mb-12 font-bold text-center'>SB BidHub <span> Auction Services</span></h4>
                </div>
                <div className='flex gap-[60px] items-center' id='forward-sales-auction'>
                    <div><img src={forwardAuction} alt="" /></div>
                    <div className='w-[717px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Forward Sales <span className={`${classes.headingText} text-5xl font-bold`}>Auctions</span></h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-semibold'>Sell Your Steel Products Through<br />Forward Auctions</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Wider Market Access</h5>
                                        <p className='text-lg'>Tap into a broad buyer network.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Real-Time Bidding</h5>
                                        <p className='text-lg'>Get live updates on bids and price movements.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Maximize Revenue</h5>
                                        <p className='text-lg'>Competitive pricing that ensures optimal returns.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Seamless Listing</h5>
                                        <p className='text-lg'>Easily list your products with detailed specifications.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex gap-[60px] pt-[48px] items-center' id='reverse-buy-auction'>
                    <div className='w-[717px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Reverse Procurement <span className={`${classes.headingText} text-5xl font-bold`}>Auctions</span></h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <p className='font-semibold'>Procure Steel Materials at Competitive Prices</p>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Competitive Bidding</h5>
                                        <p className='text-lg'>Vendors compete to offer the best prices.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Cost Reduction</h5>
                                        <p className='text-lg'>Drive down procurement costs through reverse bidding.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Transparency</h5>
                                        <p className='text-lg'>Monitor bids in real-time and select the best offers.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Customizable Requirements</h5>
                                        <p className='text-lg'>Define your steel needs and receive bids that match your criteria.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={reverseAcution} alt="" /></div>
                </div>
            </div>
            <div className={`${classes.container} pb-[110px]`} id='Registration'>
                <div className={classes.sectionHeading}>
                    <h4 className='mt-[6px] mb-[10px]  font-bold text-center'><span>For Buyers,</span> Revolutionizing Steel Procurement</h4>
                    <p className={`${classes.platformSubHeading} font-semibold text-2xl text-center mb-[48px]`}>Easy setup, real-time bidding, and secure transactions</p>
                </div>
                
                <div className='flex gap-[60px] items-center'>
                    <div className='w-[717px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[40px] text-5xl font-bold'>Participate in Forward Sales Auctions and Get Bargains</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Participate in Live Auctions</h5>
                                        <p className='text-lg'>Bid for the best prices on materials.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Access Diverse Inventory</h5>
                                        <p className='text-lg'>Choose from prime, surplus, or scrap steel.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Real-Time Bidding</h5>
                                        <p className='text-lg'>Enjoy transparency and instant deal closures.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Save Costs</h5>
                                        <p className='text-lg'>Competitive bidding reduces procurement expenses.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Flexible Quantities</h5>
                                        <p className='text-lg'>Procure as per business requirements.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={participate} alt="" /></div>
                </div>
                <div className='flex gap-[60px] items-center pt-12 pt-12'>
                    <div><img src={bulkProcurement} alt="" /></div>
                    <div className='w-[717px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[40px] text-5xl font-bold'>Create Reverse Procurement Auctions & Optimise Bulk Procurement</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Create Reverse Procurement Auctions</h5>
                                        <p className='text-lg'>Invite sellers to compete for supply orders.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Bulk Buying Made Easy</h5>
                                        <p className='text-lg'>Streamline large-volume purchases effortlessly.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Cost Optimization</h5>
                                        <p className='text-lg'>Leverage competition to secure the lowest rates.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Customized Procurement</h5>
                                        <p className='text-lg'>Tailor auctions to meet specific material needs.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Regular Supply</h5>
                                        <p className='text-lg'>Ensure consistent materials for operational efficiency.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} pb-[110px]`} id='Registration'>
                <div className={classes.sectionHeading}>
                    <h4 className='mt-[6px] mb-[10px] text-5xl font-bold text-center'><span>For Sellers,</span> Maximize Market Opportunities</h4>
                    <p className={`${classes.platformSubHeading} font-semibold text-2xl text-center mb-[48px]`}>Sell Smarter and Secure Supply Orders</p>
                </div>
                <div className='flex gap-[60px] items-center'>
                    <div className='w-[717px]'>
                    <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[40px] text-5xl font-bold'>Create<span className={`${classes.headingText} text-5xl font-bold`}> Forward Sales Auction</span> for Surplus, Defective, Idle, or Scrap Materials</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Find Active Buyers</h5>
                                        <p className='text-lg'>Connect with interested buyers effortlessly.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Achieve Fair Pricing</h5>
                                        <p className='text-lg'>Secure competitive rates through open bidding.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Quick Liquidity</h5>
                                        <p className='text-lg'>Convert unused materials into cash promptly.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Enhanced Market Visibility</h5>
                                        <p className='text-lg'>Showcase offerings to a broad audience.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Streamlined Selling</h5>
                                        <p className='text-lg'>Simplify sales with transparent auction processes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={priceTool} alt="" /></div>
                </div>
                <div className='flex gap-[60px] items-center'>
                    <div><img src={priceTool} alt="" /></div>
                    <div className='w-[717px]'>
                        <div  className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[40px] text-5xl font-bold'>Participate in <span className={`${classes.headingText} text-5xl font-bold`}>Reverse Procurement Auctions </span>to get secure supply orders</h4>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Secure Supply Orders</h5>
                                        <p className='text-lg'>Compete for enterprise buyer requirements.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Build Buyer Relationships</h5>
                                        <p className='text-lg'>Collaborate with verified, repeat clients.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Improve Inventory Turnover</h5>
                                        <p className='text-lg'>Align production with buyer needs.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Tailored Offers</h5>
                                        <p className='text-lg'>Customize bids to match specific procurement demands.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Expand Market Reach</h5>
                                        <p className='text-lg'>Tap into a diverse pool of enterprise buyers.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.platfomrContainer} py-10`}>
            <div className={`${classes.container}`}>
                <h2 className={`${classes.platformHeading} font-semibold mb-1 text-center`}>How does the Auction Process Work ?</h2>
                <p className={`${classes.platformSubHeading} font-medium text-2xl text-center mb-[100px]`}>Easy setup, real-time bidding, and secure transactions</p>
                <div className='flex pt-20 justify-center pb-10'>
                    <div className='flex flex-col items-center'>
                        <div className={`${classes.platformImg}`}>
                            <img src={auction1} alt="" />
                            <div className={`${classes.platformText} font-bold`}>
                            Create Your <br />Auction Listing
                            </div>
                        </div>
                        <div className={classes.borderDiv}></div>
                        <p className={`${classes.platformSecText} font-medium text-sm text-center`}>Whether selling or buying, you can <br />
                        quickly create an auction listing by <br /> entering product specifications or <br /> your procurement needs.</p>
                    </div>
                    <div className={`${classes.secondaryPlatform} flex flex-col items-center`}>
                        <p className={`${classes.platformSecText} font-medium text-sm text-center`}>Sellers will receive bids from <br /> buyers, and buyers will get <br /> offers from vendors <br /> in real time.</p>
                        <div className={classes.borderDiv}></div>
                        <div className={`${classes.platformImg}`}>
                            <img src={auction2} alt="" />
                            <div className={`${classes.platformText} font-bold`}>
                            Start <br />Bidding
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col items-center'>
                        <div className={`${classes.platformImg}`}>
                            <img src={auction3} alt="" />
                            <div className={`${classes.platformText} font-bold`}>
                            Monitor <br />and Manage
                            </div>
                        </div>
                        <div className={classes.borderDiv}></div>
                        <p className={`${classes.platformSecText} font-medium text-sm text-center`}>Track all bidding activity through <br /> your SteelBazaar dashboard,<br />allowing you to adjust bids or<br />pricing in real-time.</p>
                    </div>
                    <div className={`${classes.secondaryPlatform} flex flex-col items-center`}>
                        <p className={`${classes.platformSecText} font-medium text-sm text-center`}>Once the auction ends, the winning <br /> bid is finalized, & you can securely <br /> complete the transaction through <br /> the platform.</p>
                        <div className={classes.borderDiv}></div>
                        <div className={`${classes.platformImg}`}>
                            <img src={auction4} alt="" />
                            <div className={`${classes.platformText} font-bold`}>
                            Finalize <br />the Deal
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className={`${classes.bannerBtn} flex gap-[10px] rounded-lg font-bold items-center justify-center text-base !w-max`}>Start Procuring Now<div className={classes.btnArrow}><ArrowForwardIcon /></div></a>
            </div>
            </div>
            <div className={classes.cardsContainer} id='features'>
                <div className={`${classes.container} pt-[110px] pb-[110px]`}>
                    <div>
                        <h2
                            className={`${classes.sectionHeading} font-semibold text-center`}
                        >
                            Why Choose SB BidHub Auctions?
                        </h2>
                        <p className='text-center font-semibold text-base'>Achieve better pricing and broader market reach</p>
                    </div>
                    <div className="flex gap-x-[30px] gap-y-[36px] flex-wrap mt-[50px]">
                        {cardData.map((card, index) => (
                            <div key={index} className={`${classes.card} rounded-lg`}>
                                <div
                                    className={`${classes.cardIconContainer} flex items-center justify-center p-[10px] w-[82px] h-[82px] rounded-2xl`}
                                >
                                    <img src={card.img} alt={card.title} />
                                </div>
                                <h4 className="mt-[10px] mb-[10px] font-semibold ">{card.title}</h4>
                                <p className="text-base leading-5">{card.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={`${classes.secondaryBanner}`}>
                <div className={`${classes.container} ${classes.secondaryContainer} flex flex-col justify-center h-full`}>
                        <h2 className='font-semibold text-5xl mb-[10px]'>Start Your Auction Journey Today</h2>
                        <p className='font-semibold mb-[30px]'>Join SteelBazaar and revolutionise your <br /> procurement and sales</p>
                        <Button className={`${classes.btn3} font-bold text-base flex items-center gap-[10px] px-5 py-[10px] w-max`}> <span className={classes.btnText}>Contact Now</span><div className={`${classes.btnArrow2} flex items-center justify-center`}><ArrowForwardIcon /></div></Button>
                </div>
            </div>
            <RequestDemoTemplate />
        </>
    );
};
export default AcutionTemplate;
