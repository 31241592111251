import { Button } from '@mui/joy';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import supplyChainFinance from '../../../assets/images/supplyChainFinance.jpg';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import primaryBlueTick from '../../../assets/icons/primaryBlueTick.jpg';
import orangeTick from '../../../assets/icons/orangeTick.jpg';
import blueTick from '../../../assets/icons/blueTick.jpg';
import forBuyer from '../../../assets/images/forBuyer.png';
import forSeller from '../../../assets/images/forSeller.png';
import card1 from '../../../assets/images/card1.svg';
import pricing from '../../../assets/images/pricing.svg';
import SBDigiStore from '../../../assets/images/SBDigiStore.svg';
import invoiceCard from '../../../assets/images/invoiceCard.png';
import cardInvoice from '../../../assets/images/cardInvoice.png';
import cardPurchase from '../../../assets/images/cardPurchase.png';
import dynamicDiscount from '../../../assets/images/dynamicDiscount.png';
import cardrupee from '../../../assets/images/cardrupee.png';
import secondaryBlueTick from '../../../assets/icons/secondaryBlueTick.svg';
import { useLocation } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${supplyChainFinance})`,
        backgroundSize: 'cover',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    bannerSellContainer: {
        "& h1": {
            color: theme.palette.text.warning.warning400,
            fontSize: '56px',
            lineHeight: '66px',
        },
        "& p": {
            fontSize: '56px',
            color: "#fff",
            lineHeight: '66px',
        },
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    primaryText: {
        fontSize: '54px',
        color: '#000',
        lineHeight: '66px',
    },
    decContainer: {
        width: '750px',
        "& p": {
            color: theme.palette.text.neutral.neutral600,
            lineHeight: '26px',
            fontWeight: '400',
        },
    },
    requiredContainer: {
        background: '#F8F8F8',
    },
    heading: {
        fontSize: '54px',
        color: '#000',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    requiredContainerMain: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff',
        width: '400px',
        "& p": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
    },
    serialNumber: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        borderRadius: '0px 6px 6px 0px',
        backgroundColor: '#F8F8F8',
        fontSize: '28px',
        borderLeft: 'none',
    },
    ProcurementSectionHeading: {
        fontSize: '54px',
        lineHeight: '66px',
        color: '#000',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    procurementSubHeading: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: '28px',

    },
    cardTop1: {
        background: '#FD8235',
        color: '#fff',
        borderRadius: '15px 15px 0px 0px',
        margin: '0 auto',
    },
    cardTop2: {
        background: theme.palette.background.complementary.complementary400,
        color: '#fff',
        borderRadius: '15px 15px 0px 0px',
        margin: '0 auto',
    },
    cardTop3: {
        background: theme.palette.background.primary.primary500,
        color: '#fff',
        borderRadius: '15px 15px 0px 0px',
        margin: '0 auto',
    },
    bussinessCard: {
        boxShadow: '2px 2px 15px 0px rgba(0, 0, 0, 0.12)',
        overflow: 'hidden',
        height: '300px',
        width: '406px',
        borderRadius: '16px 16px 0px 0px',
        "& h5": {
            fontSize: '40px',
        },
        "& p": {

        },
    },
    bussinessCard1: {
        borderBottom: '4px solid #FD8235',
    },
    bussinessCard2: {
        borderBottom: `4px solid ${theme.palette.border.complementary.complementary400}`,
    },
    bussinessCard3: {
        borderBottom: `4px solid ${theme.palette.border.primary.primary500}`,
    },
    headingText: {
        fontWeight: '700 !important',
        lineHeight: '65px !important',
    },
    featuresText: {
        "& p": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },

    },
    featuresContainer: {
        "& > p": {
            color: theme.palette.text.neutral.neutral600,
        },
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary700,
        },
        "& > h4": {
            color: theme.palette.text.neutral.neutral800,
            "& span": {
                color: theme.palette.text.primary.primary500,
                fontWeight: '600',
            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px',
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
    sectionHeading: {
        color: "#000",
        fontSize: '54px',
        lineHeight: '66px',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
        "& p": {
            color: theme.palette.text.success.success500,
        },
    },
    featureCardsHeading: {
        color: '#333',
        fontSize: '54px',
    },
    featurecardDescription: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '26px',
    },
    featureCard: {
        borderRadius: '20px',
        overflow: 'hidden',
        width: '33%',
    },
    card1: {
        background: theme.palette.background.warning.warning200,
    },
    card2: {
        background: theme.palette.background.primary.primary100,
    },
    card3: {
        background: theme.palette.background.secondary.secondary100,
    },
    cardHeadingSecondary: {
        color: '#000',
        fontSize: '32px',
        lineHeight: '38px',
    },
    cardBtn: {
        borderRadius: '10px',
        background: '#fff',
        border: `1px solid ${theme.palette.border.primary.primary800}`,
        color: theme.palette.text.primary.primary800,
        padding: '10px 20px',
    },
    primaryContainer: {
        // background: '#F8F8F8',
    },
    profileCard1: {
        background: theme.palette.background.complementary.complementary50,
        border: `1px solid ${theme.palette.border.complementary.complementary400}`,
        overflow: 'hidden',
        "& p": {
            color: theme.palette.text.complementary.complementary800,
            lineHeight: '22px',
        },
        "& h4": {
            color: theme.palette.text.complementary.complementary800,
            lineHeight: '22px',
        },
    },
    profileCardHeader1: {
        background: theme.palette.background.complementary.complementary400,
        fontSize: '22px',
        color: '#fff',
    },
    profileCard2: {
        background: theme.palette.background.warning.warning50,
        border: `1px solid ${theme.palette.border.warning.warning400}`,
        overflow: 'hidden',
        "& p": {
            color: theme.palette.text.warning.warning800,
            lineHeight: '22px',
        },
        "& h4": {
            color: theme.palette.text.warning.warning800,
            lineHeight: '22px',
        },
    },
    profileCardHeader2: {
        background: theme.palette.background.warning.warning400,
        fontSize: '22px',
        color: '#fff',
    },
    profileCard3: {
        background: '#FFEFE6',
        overflow: 'hidden',
        border: '1px solid #FD8235',
        "& p": {
            color: '#652701',
            lineHeight: '22px',
        },
        "& h4": {
            color: '#652701',
            lineHeight: '22px',
        },
    },
    profileCardHeader3: {
        background: '#FD8235',
        fontSize: '22px',
        color: '#fff',

    },
    profileCard4: {
        background: theme.palette.background.primary.primary50,
        border: `1px solid ${theme.palette.border.primary.primary400}`,
        overflow: 'hidden',
        "& p": {
            color: theme.palette.text.primary.primary800,
            lineHeight: '22px',
        },
        "& h4": {
            color: theme.palette.text.primary.primary800,
            lineHeight: '22px',
        },
    },
    profileCardHeader4: {
        background: theme.palette.background.primary.primary400,
        fontSize: '22px',
        color: '#fff',
    },
    profileCard5: {
        background: theme.palette.background.success.success50,
        border: `1px solid ${theme.palette.border.success.success400}`,
        overflow: 'hidden',
        "& p": {
            color: theme.palette.text.success.success800,
            lineHeight: '22px',
        },
        "& h4": {
            color: theme.palette.text.success.success800,
            lineHeight: '22px',
        },
    },
    profileCardHeader5: {
        background: theme.palette.background.success.success400,
        fontSize: '22px',
        color: '#fff',
    },
    solutionCard: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        width: '49.2%',
        '& h4': {
            color: '#000',
            fontSize: "22px",
            lineHeight: '26px',
        },
        '& p': {
            color: '#000',
            lineHeight: '20px',
        },
    },
    buyerCardHeading: {
        color: theme.palette.text.primary.primary500,
        fontSize: '32px',
    },
    financeText: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: '28px',
    },
}));


const SupplyChainFinance: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);

    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className='font-semibold mb-12'>SB InstaFin</h1>
                    <p className='font-semibold'> Unlock Growth with <br />
                        Flexible Financing Solutions</p>
                </div>
            </div>
            <div className={`${classes.intoContainer}`}>
                <div className={`${classes.container} flex items-center gap-[60px] py-14`}>
                    <div className=''>
                        <p className={`${classes.primaryText} font-medium`}>Smart Supply Chain<br />Financing</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-lg mb-[15px]'>At Steelbazaar, we recognise the financial challenges in steel trading, & that’s why we present SB Instafin a cutting-edge supply chain finance service tailored to your needs. With features like Sales Invoice Discounting, Purchase Invoice Financing, & Dynamic Discounting, SB Instafin enhances cash flow, strengthens supplier relationships, & reduces procurement costs. Flexible, collateral-free solutions unlock growth opportunities, align repayments with cash flow, & empower your business to excel in a competitive market.</p>

                    </div>
                </div>
            </div>
            <div className='pt-[110px]'>
            <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Flexible Finance Solutions for All</h2>
            <div className={`${classes.container} flex gap-[60px] items-center`}>
                <div><img src={forBuyer} alt="" /></div>
                <div className='w-[700px]'>
                    <div className={`${classes.featuresContainer} `}>
                        <h3 className={`${classes.buyerCardHeading} font-semibold mb-5`}>For Buyers</h3>
                        <h4 className='mt-[6px] mb-[20px] text-5xl font-semibold'>Save on Overall <br />Steel Procurement Costs</h4>
                    </div>
                    <div className='flex flex-wrap gap-[10px]'>
                        <div className={`${classes.solutionCard} py-5 px-[15px] rounded-lg`}>
                            <h4 className='font-medium mb-3'>Competitive Prices</h4>
                            <p className='font-medium text-base'>Buy steel at marketplace-best rates.</p>
                        </div>
                        <div className={`${classes.solutionCard} py-5 px-[15px] rounded-lg`}>
                        <h4 className='font-medium mb-3'>Flexible Credit Terms</h4>
                            <p className='font-medium text-base'>Choose repayments aligned to business cycles.</p>
                        </div>
                        <div className={`${classes.solutionCard} py-5 px-[15px] rounded-lg`}>
                        <h4 className='font-medium mb-3'>Pre-Approved Credit</h4>
                            <p className='font-medium text-base'>Buy steel at marketplace-best rates.</p>
                        </div>
                        <div className={`${classes.solutionCard} py-5 px-[15px] rounded-lg`}>
                        <h4 className='font-medium mb-3'>Competitive Prices</h4>
                            <p className='font-medium text-base'>Instant financing for seamless procurement.</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        
            <div className={`${classes.container} flex gap-[0px] items-center pt-[10px] pb-[110px]`}>
                <div className='w-[800px]'>
                    <div className={`${classes.featuresContainer} `}>
                        <h3 className={`${classes.buyerCardHeading} font-semibold mb-5`}>For Sellers</h3>
                        <h4 className='mt-[6px] mb-[20px] text-5xl font-semibold'>Reliable Cash Flow<br />for Consistent Business Growth</h4>
                    </div>
                    <div className='flex flex-wrap gap-[10px]'>
                        <div className={`${classes.solutionCard} py-5 px-[15px] rounded-lg`}>
                            <h4 className='font-medium mb-3'>Instant Payments</h4>
                            <p className='font-medium text-base'>Convert invoices into immediate cash.</p>
                        </div>
                        <div className={`${classes.solutionCard} py-5 px-[15px] rounded-lg`}>
                        <h4 className='font-medium mb-3'>Secure Transactions</h4>
                            <p className='font-medium text-base'>Sell to pre-approved buyers for risk-free deals.</p>
                        </div>
                        <div className={`${classes.solutionCard} py-5 px-[15px] rounded-lg`}>
                        <h4 className='font-medium mb-3'>Enhanced Liquidity</h4>
                            <p className='font-medium text-base'>Strengthen cash flow for operations.</p>
                        </div>
                        <div className={`${classes.solutionCard} py-5 px-[15px] rounded-lg`}>
                        <h4 className='font-medium mb-3'>Effortless Financing</h4>
                            <p className='font-medium text-base'>Simplified, fast, and transparent process.</p>
                        </div>
                    </div>
                </div>
                <div><img src={cardrupee} alt="" /></div>
            </div>
            <div className={classes.intoContainer}>
            <div className={`${classes.container} flex flex-col pt-[110px]`} id='sellSmart'>
                <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Empowering Your Steel Supply Chain <br />
                    with Smarter Financing</h2>
                    <span className={`${classes.financeText} font-semibold text-2xl text-center pt-2`}>Funds Never Short</span>
                <div className='flex gap-4 pt-[60px]'>
                    <div className={`${classes.card1} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>Sales Invoice Discounting</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Turn Pending Payments into Working <br /> Capital Instantly</p>
                        </div>
                        <div className='flex justify-between px-6 pb-[29px] items-end'>
                        <a href="" className={`${classes.cardBtn} font-medium text-base`}>Contact Now</a>
                        <img src={card1} alt="" className='w-[204px]' />
                        </div>
                    </div>
                    <div className={`${classes.card2} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>Purchase Invoice Finance</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Maximise your Revenues, Liquidate in<br />shortest time</p>
                        </div>
                        <div className='flex justify-between px-6 pb-[29px] items-end'>
                        <a href="" className={`${classes.cardBtn} font-medium text-base`}>Contact Now</a>
                        <img src={invoiceCard} alt="" className='w-[204px]'/>
                        </div>
                    </div>
                    <div className={`${classes.card3} ${classes.featureCard} flex flex-col justify-between`}>
                        <div className='p-[30px]'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>Dynamic Discounting</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Accelerate Liquidity while Maximizing<br />Savings</p>
                        </div>
                        <div className='flex justify-between px-6 pb-[29px] items-end'>
                        <a href="" className={`${classes.cardBtn} font-medium text-base`}>Contact Now</a>
                        <img src={pricing} alt="" className='w-[204px]'/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} flex gap-[60px] items-center pb-[48px] pt-[110px]`} id='sales-invoice-discounting'>
                <div><img src={cardInvoice} alt="" /></div>
                <div className='w-[700px]'>
                    <div className={`${classes.featuresContainer} `}>
                        <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Sales Invoice<br /><span className={`${classes.headingText} !text-5xl font-bold`}>Discounting</span></h4>
                    </div>
                    <div className={`${classes.featureInfo}`}>
                        <h4 className='text-2xl font-semibold'>Turn Sales Into Cash Instantly</h4>
                        <div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Discount invoices up to 90% value.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Get funds within 24 hours.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>No collateral required.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Transparent and competitive terms.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Strengthen operational cash flow.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} flex gap-[60px] items-center pb-[48px]`} id='purchase-invoice-finance'>
                <div className='w-[700px]'>
                    <div className={`${classes.featuresContainer} `}>
                        <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Purchase Invoice<br /><span className={`${classes.headingText} !text-5xl font-bold`}>Financing</span></h4>
                    </div>
                    <div className={`${classes.featureInfo}`}>
                        <h4 className='text-2xl font-semibold'>Procure with Confidence</h4>
                        <div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Financing up to 100% of invoice value.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Flexible repayment aligned with business cycles.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Pre-approved credit limits for hassle-free transactions.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Reduce reliance on supplier credit.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Strengthen supplier relationships with timely payments.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div><img src={cardPurchase} alt="" /></div>
            </div>
            <div className={`${classes.container} flex gap-[60px] items-center`} id='dynamic-discounting'>
                <div><img src={dynamicDiscount} alt="" /></div>
                <div className='w-[700px]'>
                    <div className={`${classes.featuresContainer} `}>
                        <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Dynamic<br /><span className={`${classes.headingText} !text-5xl font-bold`}>Discounting</span></h4>
                    </div>
                    <div className={`${classes.featureInfo}`}>
                        <h4 className='text-2xl font-semibold'>Optimize Costs Dynamically</h4>
                        <div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Early payments secure discounts up to 5%.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Tailored terms for buyer-seller flexibility.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Improve procurement cost efficiency.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Strengthen seller liquidity with faster funds.</h5>
                                </div>
                            </div>
                            <div className='flex gap-[15px] mt-[15px] items-center'>
                                <img src={secondaryBlueTick} alt="" />
                                <div className={classes.featuresText}>
                                    <h5 className='font-medium mb-1'>Reduce overall financing and trading costs.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className={`${classes.primaryContainer} pt-[110px]`}>
                <div className={`${classes.container}`}>
                    <div className={`${classes.featuresContainer} ${classes.container}`}>
                        <h4 className='mt-[6px] text-5xl font-bold text-center'>SB Instafin Journey From Eligibility to Financing</h4>
                        <div className='flex gap-5 mt-[50px]'>
                            <div className={`${classes.profileCard1} w-1/5 pb-5 rounded-lg`}>
                                <div className={`${classes.profileCardHeader1} mb-5 px-[15px] py-2 font-semibold`}>Step 1</div>
                                <h4 className='font-semibold text-lg px-[15px] mb-[10px]'>Check Eligibility & Credit Assessment</h4>
                                <p className='px-[15px] font-medium text-lg'>Submit business details to confirm eligibility.</p>
                            </div>
                            <div className={`${classes.profileCard2} w-1/5 pb-5 rounded-lg`}>
                                <div className={`${classes.profileCardHeader2} mb-5 px-[15px] py-2 font-semibold`}>Step 2</div>
                                <h4 className='font-semibold text-lg px-[15px] mb-[10px]'>Credit Facility<br />Sanction</h4>
                                <p className='px-[15px] font-medium text-lg'>Receive custom financing offers from NBFC partners.</p>
                            </div>
                            <div className={`${classes.profileCard3} w-1/5 pb-5 rounded-lg`}>
                                <div className={`${classes.profileCardHeader3} mb-5 px-[15px] py-2 font-semibold`}>Step 3</div>
                                <h4 className='font-semibold text-lg px-[15px] mb-[10px]'>Buy/Sell on<br />Steelbazaar</h4>
                                <p className='px-[15px] font-medium text-lg'>Trade seamlessly on the marketplace with confidence.</p>
                            </div>
                            <div className={`${classes.profileCard4} w-1/5 pb-5 rounded-lg`}>
                                <div className={`${classes.profileCardHeader4} mb-5 px-[15px] py-2 font-semibold`}>Step 4</div>
                                <h4 className='font-semibold text-lg px-[15px] mb-[10px]'>Apply for SB Instafin<br />Services</h4>
                                <p className='px-[15px] font-medium text-lg'>Choose suitable financing options and submit invoices.</p>
                            </div>
                            <div className={`${classes.profileCard5} w-1/5 pb-5 rounded-lg`}>
                                <div className={`${classes.profileCardHeader5} mb-5 px-[15px] py-2 font-semibold`}>Step 5</div>
                                <h4 className='font-semibold text-lg px-[15px] mb-[10px]'>Set your Detailed<br />Preferences.</h4>
                                <p className='px-[15px] font-medium text-lg'>Funds are transferred directly to your account or supplier. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} pt-[130px]`}>
                <h2 className={`${classes.ProcurementSectionHeading} font-bold text-center`}>Flexible Finance Solution for All</h2>
                <div className='mt-[50px] flex gap-[30px] pb-[110px]'>
                    <div>
                        <div className={`${classes.cardTop1} py-[10px] w-[325px] text-center font-semibold text-2xl `}>SMEs & Micro</div>
                        <div className={`${classes.bussinessCard} ${classes.bussinessCard1} relative pt-[40px] pb-[10px]`}>
                            <div className='px-[15px] flex flex-col gap-5'>
                                <p className='flex items-center gap-3 '><img src={orangeTick} alt="" />Minimum 3 years of trading experience.</p>
                                <p className='flex items-center gap-3'><img src={orangeTick} alt="" />Minimum Turnover of 5 Crores in the last 3 Years.</p>
                                <p className='flex items-center gap-3'><img src={orangeTick} alt="" />Verified GST registration.</p>
                                <p className='flex items-center gap-3'><img src={orangeTick} alt="" />Good credit history & transaction records.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={`${classes.cardTop2} py-[10px] w-[325px] text-center font-semibold text-2xl `}>Mid Corporate & MSMEs</div>
                        <div className={`${classes.bussinessCard} ${classes.bussinessCard2} relative pt-[40px] pb-[10px]`}>
                            <div className='px-[15px] '>
                                <div className='flex flex-col gap-5'>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" />Established manufacturing setup with verified credentials.</p>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" />Minimum Turnover of 3 Crores in Last 3 Years.</p>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" />Bulk procurement requirements for custom financing solutions.</p>
                                    <p className='flex items-center gap-3'><img src={blueTick} alt="" />Stable financial health with audited records.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={`${classes.cardTop3} py-[10px] w-[325px] text-center font-semibold text-2xl `}>Enterprises</div>
                        <div className={`${classes.bussinessCard} ${classes.bussinessCard3} relative pt-[40px] pb-[10px]`}>
                            <div className='px-[15px] '>
                                <div className='flex flex-col gap-5'>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" />A registered corporate entity with valid documentation.</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" />Minimum Turnover of 25 Crores in the last 3 years.</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" />Consistent financial and trading performance.</p>
                                    <p className='flex items-center gap-3'><img src={primaryBlueTick} alt="" /> Active presence in the steel trading ecosystem.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.requiredContainer} py-[110px]`}>
                <div className={classes.container}>
                    <h2 className={`${classes.heading} font-semibold mb-10 text-center`}>Basic <span>Documents Required</span></h2>
                    <div className='flex flex-wrap gap-[30px] items-center justify-center pt-[30px]'>
                        <div className={`${classes.requiredContainerMain} py-5 pr-[15px] rounded-lg flex gap-[10px] items-center `}>
                            <div className={`${classes.serialNumber} p-[10px] font-semibold flex items-center justify-center`}>01</div>
                            <p className='font-medium'>Valid business registration<br />(GST and PAN mandatory).</p>
                        </div>
                        <div className={`${classes.requiredContainerMain} py-5 pr-[15px] rounded-lg flex gap-[10px] items-center `}>
                            <div className={`${classes.serialNumber} p-[10px] font-semibold flex items-center justify-center`}>02</div>
                            <p className='font-medium'>Demonstrated trading or <br /> manufacturing activity in steel.</p>
                        </div>
                        <div className={`${classes.requiredContainerMain} py-5 pr-[15px] rounded-lg flex gap-[10px] items-center `}>
                            <div className={`${classes.serialNumber} p-[10px] font-semibold flex items-center justify-center`}>03</div>
                            <p className='font-medium'>Minimum 2-year <br /> operational history.</p>
                        </div>
                        <div className={`${classes.requiredContainerMain} py-5 pr-[15px] rounded-lg flex gap-[10px] items-center `}>
                            <div className={`${classes.serialNumber} p-[10px] font-semibold flex items-center justify-center`}>04</div>
                            <p className='font-medium'>Stable financial health with <br /> supporting documents.</p>
                        </div>
                        <div className={`${classes.requiredContainerMain} py-5 pr-[15px] rounded-lg flex gap-[10px] items-center `}>
                            <div className={`${classes.serialNumber} p-[10px] font-semibold flex items-center justify-center`}>05</div>
                            <p className='font-medium'>Positive credit assessment <br /> by NBFCs.</p>
                        </div>
                    </div>
                </div>
            </div>
            <RequestDemoTemplate />
        </>
    );
};

export default SupplyChainFinance;
