import React from 'react';
import { createUseStyles } from 'react-jss';
import manufacturing from '../../../../assets/images/manufacturing.svg';
import market from '../../../../assets/images/market.svg';
import procurment from '../../../../assets/images/procurment.svg';
import finance from '../../../../assets/images/finance.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: "1300px",
        padding: "80px 0px",
        rowGap: "72px"
    },
    heading: {
        fontSize: "48px",
        color: theme.palette.text.neutral.neutral800,
        lineHeight: "58px",
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    cardContainer: {
        gridTemplateColumns: "repeat(4, 1fr)",
        columnGap: "16px"
    },
    card: {
        display: "grid",
        padding: "24px 24px 0px 24px",
        height: "410px",
        borderRadius: '20px',
        '&:nth-child(1)': {
            background: theme.palette.background.warning.warning200,
            color: theme.palette.text.warning.warning900,
        },
        '&:nth-child(2)': {
            background: theme.palette.background.primary.primary100,
            color: theme.palette.text.primary.primary900,
        },
        '&:nth-child(3)': {
            background: theme.palette.background.secondary.secondary100,
            text: theme.palette.text.secondary.secondary900,
        },
        '&:nth-child(4)': {
            background: theme.palette.background.success.success100,
            text: theme.palette.text.success.success900,
        },
    },
    cardTitle: {
        color: '#000',
        fontSize: '28px',
        fontWeight: '400',
        lineHeight: '34px',
    },
    cardDescription: {
        color: theme.palette.text.neutral.neutral800,
        fontSize: '16px',
        lineHeight: '20px',
    },
    image: {

    },
    "@media (max-width: 767px)": {
        container: {
            width: "100%",
            padding: "0px 12px",
            rowGap: "40px"
        },
        heading: {
            fontSize: "54px",
            color: theme.palette.text.neutral.neutral800,
            lineHeight: "66px",
            "& span": {
                color: theme.palette.text.primary.primary500,
            },
        },
        cardContainer: {
            gridTemplateColumns: "repeat(1, 1fr)",
            rowGap: "10px"
        },
        card: {
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 0px 0px 10px",
            height: "auto",
            borderRadius: '12px',
            '&:nth-child(1)': {
                background: theme.palette.background.warning.warning200,
                color: theme.palette.text.warning.warning900,
            },
            '&:nth-child(2)': {
                background: theme.palette.background.primary.primary100,
                color: theme.palette.text.primary.primary900,
            },
            '&:nth-child(3)': {
                background: theme.palette.background.secondary.secondary100,
                text: theme.palette.text.secondary.secondary900,
            },
            '&:nth-child(4)': {
                background: theme.palette.background.success.success100,
                text: theme.palette.text.success.success900,
            },
            "& div":{
                marginTop: "auto",
                marginBottom: "auto"
            }
        },
        cardTitle: {
            fontSize: '18px',
            fontWeight: '500',
            lineHeight: '22px',
        },
        cardDescription: {
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '14px',
        },
        image: {
            width: "50%"
        },
    }
}));

const cardDetails = [
    {
        imgSrc: market,
        title: "B2B Digital Marketplace",
        description: "Multiple Network, Unlimited Trade."
    },
    {
        imgSrc: procurment,
        title: "Procurement Solutions",
        description: "Lowest Costs, Assured Qualities"
    },
    {
        imgSrc: manufacturing,
        title: "Manufacturing Services",
        description: "Competitive Buying and Selling"
    },
    {
        imgSrc: finance,
        title: "Recycling and Sustainability ",
        description: "Access Verified Lead to Drive Sales and Business Growth"
    }
];

const AipoweredTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.container} grid mx-auto`}>
            <div className={`${classes.heading} font-bold text-center`}> <span>AI Powered </span>Digital Platform for Steel Industry</div>
            <div className={`${classes.cardContainer} grid`}>
                {cardDetails.map((card, index) => (
                    <div className={`${classes.card} `} key={index}>
                        <div className=''>
                            <div className={classes.cardTitle}>{card.title}</div>
                            <div className={classes.cardDescription}>{card.description}</div>
                        </div>
                        <img className={`${classes.image} self-end`} src={card.imgSrc} alt="" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AipoweredTemplate;