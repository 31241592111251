import { createUseStyles } from "react-jss";
import { DELIVERY_AND_TRANSPORTATION_SECTION } from "../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        display: "flex",
        justifyContent: "space-between",
        columnGap: "12px",
        rowGap: "0px",
    },
    webContainer: {
        display: "grid"
    },
    imageWrapper: {
        width: "56px",
        height: "40px",
        background: "white",
        position: "absolute",
        top: "-43px",
        zIndex: 2,
    },
    description: {
        color: theme.palette.text.neutral.neutral800,
        borderRadius: "12px",
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        padding: "32px 16px 16px",
    },
    heading: {
        color: theme.palette.text.neutral.neutral900,
        fontWeight: 700
    },
    mobileContainer: {
        display: "none"
    },
    subContainer:{
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    "@media (max-width: 767px)": {
        mainContainer: {
            display: "flex",
            flexDirection: "column",
            rowGap: "20px",
        },
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "grid"
        },
        subContainer:{
            border: `1px solid ${theme.palette.border.neutral.neutral100}`,
         
        },
        description: {
            borderRadius: "0px",
            border: `none`,
            padding: "16px 20px 16px 8px",
            borderLeft: `1px solid ${theme.palette.border.neutral.neutral100}`,

        },
        heading: {
            fontWeight: 600
        },

    },
}));

const DeliveryDetailsTemplate: React.FC = () => {

    const classes = useStyles();

    return (
        <div className={`${classes.mainContainer}`}>
            {DELIVERY_AND_TRANSPORTATION_SECTION.map(({ text, icon, description }) => (
                <>
                    <div key={text} className={`flex-1 ${classes.webContainer}`}>
                        <div className={`${classes.heading} text-base w-full`}>{text}</div>
                        <div className="relative mt-10">
                            <div className={`${classes.imageWrapper} ml-8 mt-4`}>
                                <img src={icon} alt="icon" className={``} />
                            </div>
                            <p className={`${classes.description} text-base font-medium`}>{description}</p>
                        </div>
                    </div>

                    <div key={text} className={`grid w-full gap-y-2 ${classes.mobileContainer}`}>
                        <div className={`${classes.heading} text-base w-full`}>{text}</div>
                        <div className={`${classes.subContainer} flex w-full relative rounded-lg`}>
                            <div className="relative rounded-[100px] w-12 h-12 overflow-hidden">
                                <img
                                    src={icon}
                                    alt="icon"
                                    className="absolute left-1/2 top-[18px] -translate-x-1/2 -translate-y-1/2 scale-[1.2]"
                                />
                            </div>
                            <p className={`${classes.description} text-sm font-semibold`}>{description}</p>
                        </div>
                    </div>
                </>
            ))}
        </div>
    );
}

export default DeliveryDetailsTemplate;