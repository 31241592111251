import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { AUTH_STATES } from '../../../utils/types';

interface FormHeadingTemplateProps {
    section: AUTH_STATES;
    sentEmailType: AUTH_STATES
}

const useStyles = createUseStyles((theme: any) => ({
    container:{
        display: "none"
    },
    headingColor: {
        color: theme.palette.text.primary.primary800,
        fontSize: "22px",
        lineHeight: "26px",
        borderBottom: `1px dashed ${theme.palette.border.neutral.neutral100}`,

    },
    content: {
        color: theme.palette.text.neutral.neutral600,
        fontSize: "14px",
        lineHeight: "16px"
    },
    "@media (max-width: 767px)": {
        container:{
            display: "grid"
        },
    }
}));

const FormHeadingTemplate: React.FC<FormHeadingTemplateProps> = ({ section, sentEmailType }) => {
    const classes = useStyles();
    const [ content, setContent ] = useState({ heading: "", discription: "" });
    useEffect(() => {
        switch (section) {
            case AUTH_STATES.LOGIN_WITH_EMAIL:
                setContent({ heading: "Sign In", discription: "" })
                break;
            case AUTH_STATES.LOGIN_WITH_MOBILE:
                setContent({ heading: "Sign In", discription: "" })
                break;
            case AUTH_STATES.USER_SIGNUP:
                setContent({ heading: "Register Now", discription: "" })
                break;
            case AUTH_STATES.OTP_VERIFICATION:
                setContent({ heading: "OTP Verification", discription: "You need to be verify OTP to complete verification. " })
                break;
            case AUTH_STATES.SET_PASSWORD:
                setContent({ heading: "Set New Password", discription: "Please enter your new password. " })
                break;
            case AUTH_STATES.FORGOT_PASSWORD:
                setContent({ heading: "Forgot Password?", discription: "Please enter your registered email. " })
                break;
            case AUTH_STATES.EMAIL_CONFIRMAITON:
                setContent({ heading: `${sentEmailType===AUTH_STATES.FORGOT_PASSWORD ? "Forgot Password?" : "Welcome! We’re thrilled to have you with us."}`, 
                discription: `${sentEmailType===AUTH_STATES.FORGOT_PASSWORD ? "" : "Sign up is simple, free and fast."}` })
                break;
            default:
            setContent({ heading: "Welcome! We’re thrilled to have you with us.", discription: "Sign up is simple, free and fast. " })
            break;
        }
    }, [section])
    return (
        <div className= {`${classes.container} gap-y-2.5 items-center w-full my-2.5`}>
            <div className={`text-center font-semibold ${classes.headingColor} pb-1`}>{content.heading}</div>
            <div className={`${classes.content} text-base text-center`}>{content.discription}</div>
        </div>
    )
}

export default FormHeadingTemplate