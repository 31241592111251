import React, { useState } from 'react';
import navbarArrowIcon from '../../../../assets/icons/navbarArrowIcon.svg';
import { STATIC_PAGES_ROUTES } from '../../../../utils/constant';
import { Link } from 'react-router-dom';

const AboutUsDropdown: React.FC = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const handleLinkClick = () => {
        setIsDropdownOpen(false);
      };
    
      if (!isDropdownOpen) return null; 

    return (
        <>
        <div className="absolute left-0 z-10 mt-4 bg-white shadow focus:outline-none w-[200px] rounded-[8px]">
        <div className="py-5 px-2.5 flex flex-col"> 
        <Link to={`${STATIC_PAGES_ROUTES.ABOUT_US}`} onClick={handleLinkClick} className='flex gap-x-2.5 p-2.5 border-b border-[#CACCCE]'><img src={navbarArrowIcon} alt='' /> About Steelbazaar</Link>
        <Link to={`${STATIC_PAGES_ROUTES.ABOUT_US}/?component=vision`} className='flex gap-x-2.5 p-2.5'><img src={navbarArrowIcon} alt='' /> Vision and Mission</Link>
        </div>
      </div>
        </>
    );
};

export default AboutUsDropdown;
 