import WhatsAppIcon from "../../../assets/icons/whatsapp.svg";
import { Link } from 'react-router-dom';
import { NUMBER_URL } from "../../../utils/constant";
import { createUseStyles } from "react-jss";

interface WhatsappProps{
    isActive?:boolean
}

const useStyles = createUseStyles((theme: any) => ({
    img: {
        padding: "13px",
        backgroundColor: theme.palette.background.primary.primary100,
    },
    disableLink:{
        pointerEvents:"none"
    },
    "@media (max-width: 767px)": {
        img: {
            padding: "12px",
        }
    },
}));

const Whatsapp: React.FC<WhatsappProps> = ({isActive}) => {
    const classes = useStyles();
    return (
        <Link className={isActive ? "" : classes.disableLink} to={NUMBER_URL.WHATSAPP} target="_blank" rel="noopener noreferrer" style={{ margin: 0 }}>
            <img src={WhatsAppIcon} alt="icon" className={`${classes.img} cursor-pointer rounded-lg`} />
        </Link>
    )
}

export default Whatsapp;