import React from 'react';
import { createUseStyles } from 'react-jss';
import SaleSolutionsTemplate from '../../template/Home/SalesSolutions/SaleSolutions.template';
import CustomisedMaterialTemplate from '../../template/CustomisedMaterial/CustomisedMaterial.template';
import AipoweredTemplate from '../../template/Home/AiPowered/Aipowered.template';
import FinanceCardTemplate from '../../template/Home/FinanceCards/FinanceCard.template';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import HomeBanner from '../../template/Home/HomeBanner/HomeBanner';
import NewsTemplate from '../../template/Home/NewsSection/NewsSection.template';
import ProductSliderTemplate from '../../template/Home/ProductRange/ProductRange.template';
import NewArrivalsTemplate from '../../template/Home/NewArrivals/NewArrivals.template';
import IndustriesSlider from '../../template/Home/IndustriesSlider/IndustriesSlider.template';
import BrandSlider from '../../template/Home/BrandSlider/BrandSlider.template';
import AuctionAndCustomisedTemplate from '../../template/Home/Auction&Customised/AuctionAndCustomised.template';
import ServicesTemplate from '../../template/Home/Services/Services.template';
import RawSemiFinishedTemplate from '../../template/Home/RawSemiFinished/RawSemiFinished.template';
import FinishedTemplate from '../../template/Home/Finished/Finished.template';

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {

    },
    container: {
        display: "grid",
    },
    "@media (max-width: 767px)": {
        mainContainer: {
            rowGap: "40px"
        },
    }
}));

const HomePage: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.mainContainer} grid item-center`}>
            <HomeBanner />
            <RawSemiFinishedTemplate />
            <FinishedTemplate />
            {/* <div className={`${classes.container} max-container mx-auto flex flex-col`}>
                <ProductSliderTemplate />
                <NewArrivalsTemplate />
                <CustomisedMaterialTemplate />
                <SaleSolutionsTemplate /> 
            </div> */}
            <AuctionAndCustomisedTemplate />
            <AipoweredTemplate />
            <FinanceCardTemplate />
            <ServicesTemplate />
            <BrandSlider />
            <NewsTemplate />
            <IndustriesSlider />
            <RequestDemoTemplate />
        </div>
    );
};

export default HomePage;