import React from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import MyAuctionTemplate from '../../template/BuyerDashBoard/MyAuctions.template';
import LatestNewsTemplate from '../../template/BuyerDashBoard/LatestNews.template';
import MyOrdersTemplate from '../../template/BuyerDashBoard/MyOrders.template';
import MyEnquiryTemplate from '../../template/BuyerDashBoard/MyEnquiry.template';
import MyProductsTemplate from '../../template/BuyerDashBoard/MyProducts.template';
import { createUseStyles } from 'react-jss';
import SellerStatusDashboardTemplate from '../../template/SelllerDashboard/SellerStatusDashboard.template';
import ProductStatusTemplate from '../../template/SelllerDashboard/ProductStatus.template';
import TopSellingProductsTemplate from '../../template/SelllerDashboard/TopSellingProducts.template';

const useStyles = createUseStyles((theme: any) => ({
    userName: {
        color: theme.palette.text.neutral.neutral1000,
        fontSize: "18px"
    },
}));

const SellerDashBoardPage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const classes = useStyles();

    return (
        <div className='grid gap-6'>
            {SnackBarComponent}
            <span className={`font-medium ${classes.userName}`}> Dashboard</span>
            <ProductStatusTemplate />
            <SellerStatusDashboardTemplate />
            {/* <MyEnquiryTemplate />
            <MyOrdersTemplate /> */}
            <MyAuctionTemplate />
            <TopSellingProductsTemplate />
            <LatestNewsTemplate />
        </div>
    )
}
export default SellerDashBoardPage;