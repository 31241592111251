import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const ADD_OWN_PRODUCT = {
    CREATE_OWN_PRODUCT: "vendor/:vendorId/create-own-product"
}

interface WarehouseWiseProductDetailsRequestBody {
    minimumOrderQuantity: number;
    warehouseId: number;
    deliveryTimeFrame: string;
}

export interface IVendorOwnProductRequestBodyDTO {
    productId: number|null;
    brand: string|number;
    gradeId: number;
    categoryId: number;
    productStatus: string;
    productUpcStatus:string;
    keyword:string;
    classType:string;
    shape:string;
    attributes: any;
    warehouseWiseProductDetails: WarehouseWiseProductDetailsRequestBody[];
}
export const useAddOwnProductService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const createOwnProduct = (vendorId: number, data:IVendorOwnProductRequestBodyDTO[]) => {
      return request(API_METHOD.POST, replaceUrlParams(ADD_OWN_PRODUCT.CREATE_OWN_PRODUCT, {vendorId}), authenticatedUser, data)
    };
return{
    createOwnProduct
}
};