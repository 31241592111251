import React, { useEffect, useState } from "react";
import { IProductClassification, IProductSpecification } from "../../pages/CatalogueDetail/SecondaryCatalogueDetails.page";
import { createUseStyles } from "react-jss";
import { CART_SECTIONS } from "../../../utils/types";
import CardItem from "./CartItem.template";
import CartPriceDetailsTemplate from "./CartPriceDetails.template";
import NoProduct from "../../molecules/NoProduct/NoProduct";
import Button from "../../atoms/Button/Button";
import phoneCall from "./../../../assets/images/phoneCall.svg";
import CartContactUsPop from "../../molecules/CartContactUs/CartContactUsPop";
import CartPriceDetailsSkeletonTemplate from "./CartPriceDetailsSkeleton.template";
import LeadListSkeletonTemplate from "../Lead/LeadListSkeleton.template";
import { IValueAddedServices } from "../../pages/Cart/CartDetails.page";
import ListView from "../../organisms/ListView/ListView.organism";
import { useNavigate } from "react-router-dom";

export interface IConfiguration {
	name: string;
	uom?: string | null;
	price: number;
	value: string;
}

export interface IContext {
	[key: string]: { [key: string]: IConfiguration };
}

export interface IProductAttribute {
	id: number;
	name: string;
	attributeType: string;
	uom?: string | null;
	fieldType?: string;
	attributeOptions: number | string | string[];
}

interface CartSectionProps {
	setCurrentSectionTo: (section: CART_SECTIONS) => void;
	cartItemDetails: ICartItem[];
	handleDeleteCartItem: (cartId: number) => void;
	handleCartItemUpdate: (cartId: number, key: string, value: number | string) => void;
	configuration: IContext;
	handleRfq: () => void;
	paymentMethod: string;
	setCartItemsTo: (items: ICartItem[] | []) => void;
}

export interface ICartItem {
	id: number;
	catalogueName: string;
	catalogueId: number;
	upcId: number | null;
	warehouseId: number;
	warehouseName: string;
	warehouseAddress: string;
	quantity: number;
	uom: string;
	packagingType: string;
	upcPrice: number;
	attributes: {
		SPECIFICATION: IProductAttribute[];
		CLASSIFICATION: IProductAttribute[];
		GENERALIZATION: IProductAttribute[];
		productSpecification: IProductSpecification[];
		productClassification: IProductClassification[];
	};
	catalogueImages: string;
	cataloguePackagingTypes: string[];
	secondarySkuId: number | null;
	minimumOrderQuantity: number;
	secondaryLotAttributes: any;
	upcCode: string | null;
	valueAddedServices: IValueAddedServices | null;
	catalogueType: string;
	paymentType: string;
	skuCode: string;
}

const useStyles = createUseStyles((theme: any) => ({
	rightContainer: {
		width: "30.92%",
		display: "flex",
	},
	heading: {
		color: theme.palette.text.primary.primary900,
	},
	noOfItem: {
		color: theme.palette.text.neutral.neutral700
	},
	rightChildContainer: {
		border: `1px solid ${theme.palette.border.neutral.neutral100}`,
		boxShadow: "0px 7px 18px 0px rgba(29, 54, 121, 0.12)",
	},
	rightMobileContainer: {
		display: "none"
	},
	phoneButton: {
		padding: "12px",
		backgroundColor: theme.palette.background.primary.primary100,
	},
	"@media (max-width: 767px)": {
		rightContainer: {
			display: "none",
		},
		rightMobileContainer: {
			display: "grid",
			border: `1px solid ${theme.palette.border.neutral.neutral100}`,
		},
		phoneButton: {
			padding: "10px",
		},
	},
}));

const CartItemDetail: React.FC<CartSectionProps> = ({ setCurrentSectionTo, cartItemDetails, handleDeleteCartItem, handleCartItemUpdate, configuration, handleRfq, setCartItemsTo }) => {
    const navigate = useNavigate();
	const [loading, setLoadingTo] = useState<boolean>(true);
	const classes = useStyles();
	const [contactIconStatus, setContactIconStatusTo] = useState<boolean>(false);

	const handleContactIconClick = () => {
		setContactIconStatusTo(!contactIconStatus);
	};

	useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            const iconButtonElement = document.querySelector(".cart-contact-us");
            const popupElement = document.querySelector(".cart-contact-us-popup");

            if (
                iconButtonElement &&
                popupElement &&
                !iconButtonElement.contains(target) &&
                !popupElement.contains(target)
            ) {
                setContactIconStatusTo(false);
            }
        };

        if (contactIconStatus) {
            document.addEventListener("click", handleClickOutside);
        } else {
            document.removeEventListener("click", handleClickOutside);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [contactIconStatus]);

	useEffect(() => {
		if (cartItemDetails) {
			setLoadingTo(false);
		}
	}, []);

	return (
		<>
			<div className="w-full">
				{loading ? (
					<div className="flex gap-x-6 ">
						<div className="w-2/3 grid gap-y-4">
							{[null, null, null].map(() => (
								<LeadListSkeletonTemplate />
							))}
						</div>
						<div className="w-1/3">
							<CartPriceDetailsSkeletonTemplate />
						</div>
					</div>
				) : (cartItemDetails && cartItemDetails.length) > 0 ? (
					<div className="flex gap-x-6">
						<div className={`flex-1 grid gap-y-6 h-fit`}>
							<div className="grid gap-y-1">
								<div className={`${classes.heading} text-2xl font-semibold`}>My Cart</div>
								<div className={`${classes.noOfItem} text-sm font-medium`}>{cartItemDetails.length} {cartItemDetails.length < 2 ? "Item" : "Items"}</div>
							</div>
							<div>
								<ListView
									items={cartItemDetails}
									itemComponent={(item, index) => (
										<CardItem
											index={index}
											data={item}
											handleDeleteCartItem={handleDeleteCartItem}
											handleCartItemUpdate={handleCartItemUpdate}
											configuration={configuration}
											setCartItemsTo={setCartItemsTo}											
											cartItems={cartItemDetails}

										/>
									)}
								/>
							</div>
						</div>
						<div className={classes.rightContainer}>
							<div className={`z-50 rounded-2xl sticky top-[150px] ${classes.rightChildContainer} h-fit`}>
								{cartItemDetails && cartItemDetails.length > 0 && (
									<CartPriceDetailsTemplate
										data={cartItemDetails}
										configuration={configuration}
										handleRfq={handleRfq}
									/>
								)}
								<div className="flex justify-between gap-x-2 mx-4 mb-4">
									<Button
										className='flex-1'
										variant="primaryContained"
										size="large"
										// label="Choose your Payment Terms"
										label="Review"
										onClick={() =>
											// setCurrentSectionTo(CART_SECTIONS.PAYMENT_SECTION)
											setCurrentSectionTo(CART_SECTIONS.CART_REVIEW)
										}
									/>
								<div className={`rounded-xl cart-contact-us cursor-pointer h-11  ${classes.phoneButton}`} onClick={handleContactIconClick}>
									<img src={phoneCall} alt="call" />
								</div>
							</div>
							{contactIconStatus && <CartContactUsPop />}
						</div>
					</div>
					</div>
			) : (
			<NoProduct text="Your Cart is Empty." subText="Looks like you have not added anything to your cart. Go ahead and explore our categories." label="Shop our Products" variant="Cart" onClick={()=>navigate("/")} />
				)}
		</div >

			{ cartItemDetails && cartItemDetails.length > 0 && (
				<div className={` fixed bottom-0 z-50 bg-white !pl-0 ${classes.rightMobileContainer} w-full`}>
					{cartItemDetails && cartItemDetails.length > 0 && (
						<CartPriceDetailsTemplate
							data={cartItemDetails}
							configuration={configuration}
							handleRfq={handleRfq}
						/>
					)}
					<div className="flex justify-between gap-x-2 mx-4 mb-4 bg-white">
						<Button className='flex-1' variant="primaryContained" size="small" label="Review" onClick={() => setCurrentSectionTo(CART_SECTIONS.CART_REVIEW)} />
						<div className={`rounded-lg cart-contact-us cursor-pointer  ${classes.phoneButton}`} onClick={handleContactIconClick}>
							<img className='w-3.5' src={phoneCall} alt="call" />
						</div>
					</div>
					{contactIconStatus && <CartContactUsPop />}
				</div>
			)
}
		</>
	);
};

export default CartItemDetail;