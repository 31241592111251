import { FormikProps } from 'formik';
import { TextField, FormHelperText } from '@mui/material';
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from '../../atoms/Button/Button';
import { HTTP_STATUS, ONBOARDING_STATES } from '../../../utils/types';
import { GstResponse, IOnBoardingPayload } from '../../pages/OnBoarding/OnBoarding.page';
import { useBusinessService } from '../../../services/useBusinessService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';

interface GstInformationTemplateProps {
    formik: FormikProps<IOnBoardingPayload>;
    setFormState: (value: ONBOARDING_STATES) => void;
    gstDetails: GstResponse | null;
};

export interface IGstInformation {
    business: {
        gstin: string,
        name: string,
        legalStatus: string,
        dateOfEstablishment: string,
        userId?: string
    },
    address: {
        line1: string,
        pincode: string,
        city: string,
        state: string,
        country: string
    }
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        rowGap: "40px"
    },
    headingContainer: {
        rowGap: "4px"
    },
    heading: {
        color: theme.palette.text.neutral.neutral800,
        fontSize: "28px",
        lineHeight: "34px"
    },
    description: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "20px"
    },
    formContainer: {
        rowGap: "40px"
    },
    formSubContainer: {
        rowGap: "12px"
    },
    address: {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        columnGap: "12px"
    },
    "@media (max-width: 767px)": {
        container: {
            rowGap: "10px",
            padding: "0px 10px 10px 10px"
        },
        headingContainer: {
            rowGap: "0px",
            paddingBottom: "4px",
            borderBottom: `1px dashed ${theme.palette.border.neutral.neutral300}`
        },
        heading: {
            color: theme.palette.text.neutral.neutral800,
            fontSize: "22px",
            lineHeight: "26px"
        },
        description: {
            color: theme.palette.text.neutral.neutral700,
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16px"
        },
        formContainer: {
            rowGap: "10px"
        },
        formSubContainer: {
            rowGap: "10px"
        },
        address: {
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            columnGap: "12px",
            rowGap: "10px"
        },
    }
}))

const GstInformationTemplate: React.FC<GstInformationTemplateProps> = ({ formik, setFormState }) => {

    const { user, setAuthenticatedUser } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const businessService = useBusinessService();

    const classes = useStyles();

    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        formik.setTouched({ gstin: true, name: true, legalStatus: true, line1: true, postalCode: true, dateOfEstablishment: true, city: true, state: true, country: true }, true);
        const errors = Object.keys(formik.errors).find(key => ['gstin', 'name', 'legalStatus', 'line1', 'postalCode', 'dateOfEstablishment', 'city', 'state', 'country'].includes(key))
        if (errors) return;
        const values = {
            business: { gstin: formik?.values?.gstin, name: formik?.values?.name, legalStatus: formik?.values?.legalStatus, userId: user?.id, dateOfEstablishment: formik?.values?.dateOfEstablishment },
            address: { line1: formik?.values?.line1, pincode: formik?.values?.postalCode, city: formik?.values?.city, state: formik?.values?.state, country: formik?.values?.country }
        }
        setLoading(true)
        let res = user?.businessId == null ? businessService.createBusiness(values) : (user && businessService.updateBusiness(values, user?.businessId))
        res.then(res => {
            if (res?.status === HTTP_STATUS.OK) {
                if (user && user.businessId == null) {
                    setAuthenticatedUser({ ...user, businessId: res?.data?.data?.business?.id });
                }
                setFormState(ONBOARDING_STATES.BUSINESS_DETAILS);
            }
            else showSnackbar('error', "We've encountered a problem. Kindly refresh the page");
        }).catch(error => {
            console.error(error)
            showSnackbar('error', 'Something went wrong')
        }).finally(() => { setLoading(false) });
    }

    return (
        <div className={`${classes.container} grid`}>
            {SnackBarComponent}
            <div className={`grid ${classes.headingContainer}`}>
                <div className={`${classes.heading} font-medium`} >Business Profile</div>
                <div className={`${classes.description} text-base`}>Please verify your business details</div>
            </div>
            <form onSubmit={formik.handleSubmit} className={`grid ${classes.formContainer}`}>
                <div className={`grid ${classes.formSubContainer}`}>
                    <div>
                        <TextFieldV2
                            label="GSTIN"
                            type="text"
                            fullWidth
                            autoComplete="false"
                            error={
                                formik?.touched?.gstin && Boolean(formik?.errors?.gstin)
                            }
                            {...formik.getFieldProps("gstin")}
                            disabled
                        />
                        {formik?.touched?.gstin && formik?.errors?.gstin &&
                            <FormHelperText error className='top-half '>{formik?.errors?.gstin}</FormHelperText>
                        }
                    </div>
                    <div>
                        <TextFieldV2
                            label="Business Name"
                            type="text"
                            fullWidth
                            autoComplete="false"
                            error={
                                formik?.touched?.name && Boolean(formik?.errors?.name)
                            }
                            {...formik.getFieldProps("name")}
                            disabled
                        />
                        {formik?.touched?.name && formik?.errors?.name &&
                            <FormHelperText error className='top-half '>{formik?.errors?.name}</FormHelperText>
                        }
                    </div>
                    <div>
                        <TextFieldV2
                            label="Address"
                            type="text"
                            fullWidth
                            autoComplete="false"
                            error={
                                formik?.touched?.line1 && Boolean(formik?.errors?.line1)
                            }
                            {...formik.getFieldProps("line1")}
                            disabled
                        />
                        {formik?.touched?.line1 && formik?.errors?.line1 &&
                            <FormHelperText error className='top-half '>{formik?.errors?.line1}</FormHelperText>
                        }
                    </div>
                    <div className={`${classes.address}`}>
                        <div>
                            <TextFieldV2
                                label="Postal Code"
                                type="text"
                                fullWidth
                                autoComplete="false"
                                error={
                                    formik?.touched?.postalCode && Boolean(formik?.errors?.postalCode)
                                }
                                {...formik.getFieldProps("postalCode")}
                                disabled
                            />
                            {formik?.touched?.postalCode && formik?.errors?.postalCode &&
                                <FormHelperText error className='top-half '>{formik?.errors?.postalCode}</FormHelperText>
                            }
                        </div>
                        <div>
                            <TextFieldV2
                                label="City"
                                type="text"
                                fullWidth
                                autoComplete="false"
                                error={
                                    formik?.touched?.city && Boolean(formik?.errors?.city)
                                }
                                {...formik.getFieldProps("city")}
                                disabled
                            />
                            {formik?.touched?.city && formik?.errors?.city &&
                                <FormHelperText error className='top-half '>{formik?.errors?.city}</FormHelperText>
                            }
                        </div>
                        <div>
                            <TextFieldV2
                                label="State"
                                type="text"
                                fullWidth
                                autoComplete="false"
                                error={
                                    formik?.touched?.state && Boolean(formik?.errors?.state)
                                }
                                {...formik.getFieldProps("state")}
                                disabled
                            />
                            {formik?.touched?.state && formik?.errors?.state &&
                                <FormHelperText error className='top-half '>{formik?.errors?.state}</FormHelperText>
                            }
                        </div>
                        <div>
                            <TextFieldV2
                                label="Country"
                                type="text"
                                fullWidth
                                autoComplete="false"
                                error={
                                    formik?.touched?.country && Boolean(formik.errors?.country)
                                }
                                {...formik.getFieldProps("country")}
                                disabled
                            />
                            {formik?.touched?.country && formik?.errors?.country &&
                                <FormHelperText error className='top-half '>{formik?.errors?.country}</FormHelperText>
                            }
                        </div>
                    </div>
                    <div>
                        <TextFieldV2
                            label="Business Legal Type"
                            type="text"
                            fullWidth
                            autoComplete="false"
                            error={
                                formik?.touched?.legalStatus && Boolean(formik?.errors?.legalStatus)
                            }
                            {...formik.getFieldProps("legalStatus")}
                            disabled
                        />
                        {formik?.touched?.legalStatus && formik?.errors?.legalStatus &&
                            <FormHelperText error className='top-half '>{formik?.errors?.legalStatus}</FormHelperText>
                        }
                    </div>
                    <div className="">
                        <TextFieldV2
                            label="Date of Establishment"
                            type="date"
                            fullWidth
                            autoComplete="false"
                            focused
                            error={
                                formik.touched?.dateOfEstablishment && Boolean(formik.errors?.dateOfEstablishment)
                            }
                            {...formik.getFieldProps("dateOfEstablishment")}
                            disabled
                        />
                        {formik.touched?.dateOfEstablishment && formik.errors?.dateOfEstablishment &&
                            <FormHelperText error className='top-half '>{formik.errors?.dateOfEstablishment}</FormHelperText>
                        }
                    </div>
                </div>

                <div className='flex justify-end'>
                    <Button size='medium' className='w-[179px]' variant="primaryContained" label="Next" type='button' onClick={handleSubmit} isLoading={loading} />
                </div>
            </form>
        </div>
    )
}

export default GstInformationTemplate