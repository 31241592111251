import { useEffect } from "react";
import CustomRadioGroup from "../../molecules/CustomRadioGroup/CustomRadioGroup";
import { createUseStyles } from "react-jss";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";
import { FormikProps } from "formik";
import { DELIVERY_INFORMATION } from "../../../utils/constant";
import { IAddOwnProductFormik } from "./AddOwnProduct.template";

const useStyles = createUseStyles((theme: any) => ({
   
    title: {
        color: theme.palette.text.primary.primary900,
    },
    radioColor: {
        color: theme.palette.text.primary.primary500,
        width: "18%",
        padding: "16px 20px",
        display: "block",
        " & .MuiFormControlLabel-label": {
            display: "block",
            color: theme.palette.text.neutral.neutral400,
            fontSize: "18px",
            fontWeight: "600",
            marginTop: "12px"
        }
    },
    warningAsterisk: {
        marginTop: "-4px",
        color: theme.palette.text.primary.primary500
    }
}));

interface IDeliveryInformationProps {
    formik: FormikProps<IAddOwnProductFormik>;
    warehouseId: number;
    index: number;
    warehouseIndex:number;
}

const DeliveryInformationOwnProductTemplate: React.FC<IDeliveryInformationProps> = ({ warehouseId, formik, index, warehouseIndex }) => {

    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue(`productDetails.${index}.warehouseWiseProductDetails.${warehouseIndex}.deliveryTimeFrame`, event.target.value);
    };

    const onChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const parsedValue = value === '' ? '' : parseInt(value, 10);
        formik.setFieldValue(`productDetails.${index}.warehouseWiseProductDetails.${warehouseIndex}.${name}`, parsedValue);
    };

    return (
        <div className={`grid gap-y-4`}>
            <div className="grid gap-y-3">
                <div className={`text-base font-medium pt-4 ${classes.title}`}>Select Delivery Information <span className={classes.warningAsterisk}>*</span></div>
                <div>
                    <CustomRadioGroup
                        options={DELIVERY_INFORMATION}
                        value={formik.values.productDetails[index].warehouseWiseProductDetails?.[warehouseIndex]?.deliveryTimeFrame || ""}
                        onChange={handleChange}
                        className={`${classes.radioColor}`} />
                </div>
            </div>
            <div className="grid gap-y-3">
                <div className={`text-base font-medium ${classes.title}`}>Order Details</div>
                <div className="w-1/3">
                    <div className="flex">
                        <TextFieldV2
                            placeholder='Enter Weight'
                            label="Minimum Order Quantity(MT)"
                            name="minimumOrderQuanity"
                            fullWidth
                            type="number"
                            className="p-4"
                            value={formik?.values?.productDetails[index]?.warehouseWiseProductDetails?.[warehouseIndex]?.minimumOrderQuanity}
                            onChange={onChangeField}
                        />
                        <div className={classes.warningAsterisk}>
                            *
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default DeliveryInformationOwnProductTemplate;
