import React, { useMemo } from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { createUseStyles, useTheme } from "react-jss";

type CustomVariant = "primaryContained" | "secondaryContained" | "tertiaryContained" | "primaryText" | "secondaryText" | "tertiaryText";
type CustomSize = "small" | "medium" | "large";

const useStyles = createUseStyles({
    iconButton: {
        color: ({ theme }: any) => theme.palette.button.primaryDark,
    },
    root: {
        minWidth: "auto",
        padding: "0px",
        lineHeight: "1 !important",
        "&:hover": {
            backgroundColor: "none",
        },
    },
    primaryContained: {
        color: 'white',
        borderRadius: "12px",
        backgroundColor: ({ theme }: any) => theme.palette.background.primary.primary500,
        "&:hover": {
            borderRadius: "0px",
            backgroundColor: ({ theme }: any) => theme.palette.background.primary.primary700,
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.text.neutral.neutral400,
            backgroundColor: ({ theme }: any) => theme.palette.background.neutral.neutral50,
        },
        '&:focus': {
            backgroundColor: ({ theme }: any) => theme.palette.button.primaryContained.background.primary700,
        },
        '&:active': {
            backgroundColor: ({ theme }: any) => theme.palette.button.primaryContained.background.primary700,
        },
    },
    secondaryContained: {
        color: ({ theme }: any) => theme.palette.text.primary.primary800,
        backgroundColor: ({ theme }: any) => theme.palette.background.primary.primary100,
        borderRadius: "12px",
        borderColor: ({ theme }: any) => "white",
        border: '1px solid',
        "&:hover": {
            borderRadius: "0px",
            backgroundColor: ({ theme }: any) => theme.palette.background.primary.primary200
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.text.neutral.neutral400,
            backgroundColor: ({ theme }: any) => theme.palette.background.neutral.neutral50,
        },
        '&:focus': {
            backgroundColor: ({ theme }: any) => theme.palette.background.primary.primary300,
        },
        '&:active': {
            backgroundColor: ({ theme }: any) => theme.palette.background.primary.primary200,
            border: ({ theme }: any) => `1px solid ${theme.palette.border.primary.primary400}`
        },
    },

    tertiaryContained: {
        color: ({ theme }: any) => theme.palette.text.primary.primary800,
        borderRadius: "12px",
        borderColor: ({ theme }: any) => theme.palette.border.primary.primary800,
        border: '1px solid',
        backgroundColor: "white",
        "&:hover": {
            borderRadius: "0px",
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.text.neutral.neutral300,
            border: ({ theme }: any) => `1px solid ${theme.palette.border.neutral.neutral300}`
        },
        '&:focus': {
            backgroundColor: ({ theme }: any) => "white",
        },
        '&:active': {
            backgroundColor: ({ theme }: any) => theme.palette.background.primary.primary50,
        },
    },
    primaryText: {
        color: ({ theme }: any) => theme.palette.text.complementary.complementary200,
        borderRadius: "10px",
        border: 'none',
        backgroundColor: "transparent",
        textDecoration: "underline",
        "&:hover": {
            borderRadius: "0px",
            color: ({ theme }: any) => theme.palette.text.complementary.complementary400,
            textDecoration: "underline",
            backgroundColor: "white",
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.text.neutral.neutral300,
        },
        '&:focus': {
            color: ({ theme }: any) => theme.palette.text.complementary.complementary500,
        },
        '&:active': {
            color: ({ theme }: any) => theme.palette.text.complementary.complementary700,
            backgroundColor: ({ theme }: any) => theme.palette.background.complementary.complementary50,
        },
    },
    secondaryText: {
        color: ({ theme }: any) => theme.palette.text.primary.primary500,
        borderRadius: "10px",
        border: 'none',
        backgroundColor: "transparent",
        textDecoration: "underline",
        textUnderlineOffset:"3px",
        "&:hover": {
            borderRadius: "0px",
            color: ({ theme }: any) => theme.palette.text.primary.primary800,
            textDecoration: "underline",
            backgroundColor: "white",
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.text.neutral.neutral300,
        },
        '&:focus': {
            color: ({ theme }: any) => theme.palette.text.primary.primary900,
        },
        '&:active': {
            color: ({ theme }: any) => theme.palette.text.primary.primary800,
            backgroundColor: ({ theme }: any) => theme.palette.background.primary.primary50,
        },
    },
    tertiaryText: {
        color: ({ theme }: any) => theme.palette.text.neutral.neutral500,
        borderRadius: "10px",
        border: 'none',
        backgroundColor: "transparent",
        textDecoration: "underline",
        textUnderlineOffset:"3px",
        "&:hover": {
            color: ({ theme }: any) => theme.palette.text.primary.primary700,
            textDecoration: "underline",
            backgroundColor: "white",
        },
        "&:disabled": {
            color: ({ theme }: any) => theme.palette.text.neutral.neutral300,
        },
        '&:focus': {
            color: ({ theme }: any) => theme.palette.text.neutral.neutral800,
        },
        '&:active': {
            color: ({ theme }: any) => theme.palette.text.primary.primary700,
            backgroundColor: ({ theme }: any) => theme.palette.background.neutral.neutral50,
        },
    },
    label: {
        textTransform: "capitalize",
    },
    small: {
        height: "fit-content",
        fontSize: "14px",
        padding: "8px 16px",
    },
    medium: {
        height: "fit-content",
        fontSize: "16px",
        padding: "10px 20px",
    },
    large: {
        height: "fit-content",
        fontSize: "18px",
        padding: "12px 24px",
    },
});

interface ButtonProps extends Omit<MuiButtonProps, "variant"> {
    variant: CustomVariant;
    label?: string | null;
    isLoading?: boolean;
    iconButton?: React.ReactNode;
    size?: CustomSize
}

const IconButton: React.FC<ButtonProps> = ({
    variant,
    label,
    isLoading,
    iconButton,
    size = "large",
    ...props
}) => {

    const theme = useTheme<Jss.Theme>();
    const styles = useStyles({ theme });

    const buttonView = useMemo(
        () => iconButton ? <span className={styles.iconButton}>{iconButton}</span> : label,
        [iconButton, label, styles]
    )
    return (
        <MuiButton
            {...props}
            className={`${styles[variant]} ${styles[size]} ${props.className ? props.className : ''} ${styles.label} font-medium`}
            disabled={props.disabled || !!isLoading}
            classes={{ root: styles.root }}
        >
            {isLoading ? <CircularProgress size={20} /> : buttonView}
        </MuiButton>
    );
};
export default IconButton;