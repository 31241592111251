import React from "react";
import { Grid } from "@mui/material";
import { createUseStyles } from 'react-jss';
import Button from "../Button/Button";

export interface Cards {
  icon?: string;
  title: string;
  value?: number | string;
  backgroundColor: string;
  Details?: any;
  itemspace: number;
  onclick?: () => void;
  productQuantity?: number;
  productSKU?: number;
  textColor?: string;
}

interface CardListProps {
  cards: Cards[];
}

const useStyles = createUseStyles((theme: any) => ({
  title: {
    color: theme.palette.text.neutral.neutral970
  },
  statusHeader: {
    padding: "12px 16px"
  },
  value: {
    color: theme.palette.text.neutral.neutral980
  },
  stats: {
    padding: "16px 0px"
  },
  count: {
    borderTop: `1px solid ${theme.palette.border.neutral.neutral960}`,
    padding: "16px 0px"
  },
  totalLabel: {
    color: theme.palette.text.neutral.neutral990,
    fontSize: "14px"
  },
  statusCount: {
    color: theme.palette.text.neutral.neutral970,
    fontSize: "18px"
  }
}));

const StatusCard: React.FC<CardListProps> = ({ cards }) => {

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {cards.map((customerCard, index) => (
        <Grid item xs={12} sm={6} md={customerCard?.itemspace} key={index}>
          <div className="w-full rounded-2xl border">
            <div className={`flex rounded-t-2xl items-center justify-between ${classes.statusHeader}`} style={{ backgroundColor: customerCard.backgroundColor, backgroundSize: "cover" }}>
              <div className={`${classes.title} text-base font-medium `}> {customerCard.title}</div>
              <Button className='!rounded-none' variant="tertiaryText" label={"View Details"} size="small" onClick={customerCard?.onclick}/>
            </div>
            <div className={`flex flex-col gap-1 items-center ${classes.stats}`} >
              <div className="flex items-center gap-2"><img src={customerCard.icon} alt="icon" /><span className={classes.totalLabel}>Total</span></div>
              <span className={`${classes.value} text-4xl font-semibold`}> {customerCard.value}</span>
            </div>
            <div className={`flex gap-2 justify-center ${classes.count}`}>
              {customerCard?.Details?.map((detail: any, i: number) => (
                <div key={i} className="flex flex-col text-center">
                  <span className={classes.totalLabel}>{detail.label}</span>
                  <span className={`${classes.statusCount} font-medium underline`}>{detail.count}</span>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
export default StatusCard;