import React from 'react'
import { Order } from '../Order/OrderList.template';
import { createUseStyles } from "react-jss";
import Button from '../../atoms/Button/Button';
import { STATUS } from '../../../utils/constant';

interface OrderItemTemplateProps {
    order: Order;
    onClick?: any;
    title?: string;
};

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    slide: {
        width: '254px',
        borderRadius: "12px",
        "&:hover": {
            boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.08)"
        },
    },
    product: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral960}`
    },
    productTitle: {
        fontSize: "12px"
    },
    quantity: {
        color: "#999"
    },
    price: {
        color: theme.palette.text.neutral.neutral970
    },
    amount: {
        color: "#331400"
    },
    ongoing: {
        color: theme.palette.text.warning.warning600
    },
    delivered: {
        color: "#24A147"
    },
}));

const OrderItemTemplate: React.FC<OrderItemTemplateProps> = ({ order, onClick, title }) => {
    const classes = useStyles();

    const getStatusClass = (status: string | undefined, classes: Record<string, string>) => {
        switch (status) {
          case STATUS.ONGOING:
            return classes.ongoing;
            case STATUS.DELIVERED:
                return classes.delivered;
        }
      };

    return (
        <div className={` ${classes.slide} h-full border p-4 grid`} key={order.id}>
            <div className='flex justify-between items-center pb-4'>
                <div className='flex flex-col'>
                    <span className='font-semibold'>
                        {title} ID {order?.id}
                    </span>
                    <span className={`text-sm ${getStatusClass(order?.orderStatus, classes)}`}>Order {order?.orderStatus}</span>
                </div>
                <Button
                    variant="secondaryContained"
                    label="View"
                    size='small'
                    onClick={onClick}
                />
            </div>
            <div className={`pt-4  ${classes.product}`}>
                <span className={classes.quantity}>Products</span>
                {order?.products?.length > 0 && (
                    <div className='grid gap-5 pt-1'>
                        <div className={`${classes.productTitle} text-ellipsis line-clamp-1 font-semibold`}>
                            <span className='pr-1'>{order.products[0].catalogueName}</span>
                            <span className='underline'>{order.products.length > 1 ? ` +${order.products.length - 1}` : ''}</span>
                        </div>
                        <div className='flex justify-between'>
                            <span className={`text-sm ${classes.quantity} `}>Total Quantity (MT) </span>
                            <span className={`font-semibold ${classes.amount}`}>
                                {order.products.reduce((sum, product) => sum + product.quantity, 0)}
                            </span>
                        </div>
                        <div className={`flex justify-between pt-4 ${classes.product}`}>
                            <span className={` ${classes.quantity} `}>Amount</span>
                            <span className={`font-semibold ${classes.price}`}>{`\u20B9`}{order?.totalPrice}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default OrderItemTemplate