import React, { useState } from 'react'
import { createUseStyles } from 'react-jss';
import { FormHelperText } from '@mui/material';
import * as Yup from "yup";
import { useFormik } from 'formik';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import Button from '../../atoms/Button/Button';
import AuthDialogFooter from '../../molecules/AuthDialogFooter/AuthDialogFooter';
import { AuthenticatedUserType } from '../../../contexts/AuthenticatedUserContext';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useUserService } from '../../../services/useUserService';
import { REGEX } from '../../../utils/constant';
import { AUTH_STATES, HTTP_STATUS } from '../../../utils/types';

interface RegisterEmailTemplateProps {
    setAuthState: (state: AUTH_STATES) => void;
    updateUserDetails: (name: string, value: string) => void;
    setSentEmailType: (state: AUTH_STATES) => void;
    authState: AUTH_STATES;
}

const useStyles = createUseStyles((theme: any) => ({
    formContainer:{
rowGap: "24px"
    },
    sectionText: {
        color: theme.palette.text.primary.primary500,
    },
    background: {
        color: theme.palette.text.primary.primary500,
    },
    dullText: {
        color: theme.palette.text.primary.primary800,
    },
    success: {
        color: theme.palette.text.primary.primary500,
    },
    "@media (max-width: 767px)": {
        formContainer:{
            rowGap: "10px"
                },
    }
}));

const initialValues = {
    email: ''
}

export interface RequestTokenParams {
    email: string
}

const RegisterEmailTemplate: React.FC<RegisterEmailTemplateProps> = ({ setAuthState, updateUserDetails, authState, setSentEmailType }) => {
    const classes = useStyles();
    const userService = useUserService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { user, setAuthenticatedUser } = useAuthenticatedUser();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Please enter your email address')
            .matches(REGEX.EMAIL, 'Invalid email address')
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { setFieldError }) => {
            const params = {
                email: values.email
            }
            setIsLoading(true);
            let res = authState === AUTH_STATES.EMAIL_REGISTRATION ? userService.updateUser(values) : userService.forgetPassword(params)
            res.then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    setSentEmailType(authState === AUTH_STATES.EMAIL_REGISTRATION ? AUTH_STATES.EMAIL_REGISTRATION : AUTH_STATES.FORGOT_PASSWORD)
                    updateUserDetails('email', values.email);
                    if (res.data.data) {
                        const editedUser: any = { ...user };
                        editedUser.email = values.email;
                        setAuthenticatedUser(editedUser as AuthenticatedUserType)
                        setAuthState(AUTH_STATES.EMAIL_CONFIRMAITON);
                    }
                    else {
                        setFieldError('email', "This email address is not registered")
                    }
                } else if (res?.status === HTTP_STATUS.BAD_REQUEST) {
                    if (authState === AUTH_STATES.EMAIL_REGISTRATION) {
                        setFieldError('email', "This email address is already registered")
                    }
                    console.error(authState, res?.data?.statusMessage)
                }
            }).catch(error => {
                console.error("User details form:", error);
                showSnackbar("error", "Something went wrong!")
            }).finally(() => setIsLoading(false));
        },
    });

    const setAuthStateTo = (authState: AUTH_STATES) => () => {
        setAuthState(authState);
    }
    return (
        <div className="grid w-full">
            {SnackBarComponent}
            <div className="grid w-full">
                {authState === AUTH_STATES.EMAIL_REGISTRATION && <div className='text-center mb-6'>
                    <p className={`w-5/6 m-auto ${classes.dullText}`}>Connect your email to set up your password and ensure smooth access to your account.</p>
                </div>}
                <form className={`${classes.formContainer} grid w-full`} onSubmit={formik.handleSubmit}>
                    <div className="flex flex-col">
                        <TextFieldV2
                            label="Email Id"
                            type="text"
                            placeholder='Enter Email'
                            error={
                                formik.touched.email && Boolean(formik.errors.email)
                            }
                            {...formik.getFieldProps("email")}
                            fullWidth
                            autoComplete="false"
                        />
                        {formik.touched.email && formik.errors.email &&
                            <FormHelperText error className='top-half '>{formik.errors.email}</FormHelperText>
                        }
                    </div>
                    <Button variant="primaryContained" label={`${authState === AUTH_STATES.EMAIL_CONFIRMAITON ? "Verify Email" : "Get Link"}`} type="submit" isLoading={isLoading} disabled={isLoading} />
                </form>
            </div>
            {authState === AUTH_STATES.EMAIL_REGISTRATION && <AuthDialogFooter setAuthStateTo={setAuthStateTo} section={AUTH_STATES.LOGIN_WITH_EMAIL} loginType={AUTH_STATES.LOGIN_WITH_MOBILE} isLogin={false} skipForNow={true} />}
        </div>
    )
}

export default RegisterEmailTemplate