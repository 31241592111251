import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const SELLER_URLS = {
    SELLER_CATALOGUE_STATS: "/seller/stats/catalogues/:vendorId",
    SELLER_PRODUCT_STATS: "/seller/stats/products/:vendorId"
}

export const useSellerService = () => {

    const { user } = useAuthenticatedUser();
    const BASE_URL_V1 = process.env.REACT_APP_API_V1_URL;

    const getSellertatsForVendor = (vendorId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(SELLER_URLS.SELLER_CATALOGUE_STATS, { vendorId } ),  user, null, undefined, null, BASE_URL_V1);
    };

    const getProductStatsForSeller = (vendorId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(SELLER_URLS.SELLER_PRODUCT_STATS, { vendorId } ),  user, null, undefined, null, BASE_URL_V1);
    };

    return {
        getSellertatsForVendor,
        getProductStatsForSeller
    };
}