import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import { News, useNewsService } from '../../../services/useNewsService';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    mainHeading: {
        color: theme.palette.text.neutral.neutral970,
        fontSize: "40px"
    },
    comingSoon: {
        border: `1px solid ${theme.palette.border.secondary.secondary100}`,
        background: theme.palette.background.secondary.secondary50,
        color: theme.palette.text.secondary.secondary500,
    },
    slide: {
        width: '383px',
        borderRadius: "24px",
        "&:hover": {
            boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.08)"
        },
    },
    image: {
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    title: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "22px",
    },
    description: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "20px",
    },
    carouselMobileContainer: {
        display: "block"
    },
    newsHeading: {
        fontSize: "24px",
        color: theme.palette.text.primary.primary950,
    },
    newsDescription: {
        color: theme.palette.text.neutral.neutral600,
    },
}));

const LatestNewsTemplate: React.FC = () => {
    const classes = useStyles();
    const newsService = useNewsService();

    const [loading, setLoading] = useState<boolean>(true);
    const [news, setNewsTo] = useState<News[]>([]);

    const loadNews = async () => {
        setLoading(true);
        try {
            const params = {
                limit: 10,
                page: 1,
            };
            const newsResponse = await newsService.getNews(params);
            if (newsResponse?.data?.data?.length) {
                setNewsTo(newsResponse.data.data);
            } else {
                throw new Error("News fetch failed");
            }
        } catch (error) {
            setNewsTo([]);
        }
        setLoading(false);
    };

    useEffect(() => {
        loadNews();
    }, []);

    return (
        <div className={`rounded-2xl ${classes.container}`}>
            <div className={`flex p-4 `}>
                <span className={`font-medium ${classes.mainHeading}`}>Latest News</span>
            </div>
            <div className='p-3'>
                <div className={`overflow-x-auto overflow-hidden w-[1040px]`}>
                    <div className={`flex gap-x-6 w-max`}>
                        {news.map((item: any, index: any) => (
                            <div className={` ${classes.slide} h-full border px-3 py-6 grid`} key={item.id} >
                                <img src={item.banner} alt="" className={`${classes.image} rounded-xl h-[230px] w-full`} />
                                <div className=' pt-6 grid '>
                                    <div className='grid '>
                                        <div className={`${classes.newsHeading} text-2xl font-medium`}>{item.title.substring(0, 28)}...</div>
                                        <div className={`${classes.newsDescription} py-[14px] text-sm`}>{item.content.replace(/<[^>]+>/g, '').substring(0, 90)}...</div>
                                    </div>
                                    <a href={`https://news.steelbazaar.com/news/${item.slug}`} target="_blank" rel="noopener noreferrer" className='w-fit'>
                                        <Button
                                            variant="secondaryText"
                                            label="Read More >>"
                                            size='small' />
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LatestNewsTemplate;