import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import { Product } from "./OrderList.template";
import Button from "../../atoms/Button/Button";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.text.primary.primary900,
    },
    subText: {
        color: theme.palette.text.PrimaryLight,
    },
    mainContainer:{
        paddingBottom: "8px",
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    container:{
        rowGap: "8px",
    },
    subContainer: {
        columnGap: "48px"
    },
    "@media (max-width: 476px)": {
        mainContainer:{
            paddingBottom: "0px",
            borderBottom: `none`
        },
        container:{
            rowGap: "4px",
        },
        subContainer: {
            columnGap: "16px"
        }
      },
}));

interface OrderProductItemTemplateProps {
    product: Product;
    label?: string;
    onClick?: any;
}

const OrderProductItemTemplate: React.FC<OrderProductItemTemplateProps> = ({ product, label, onClick }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const productDetails = useMemo(() => {
        if(product.brand){
            return <span className={`${classes.subText} text-xs font-medium`}>Brand: {product.brand}</span>
        }
        else if(product.grade){
            return <span className={`${classes.subText} text-xs font-medium`}>Grade: {product.grade}</span>
        }
    },[product]);

    function removeDuplicates(valueList: string): string[] {
        const values = valueList.split(",").map(el => el.trim());
        const uniqueNamesMap = new Map(values?.map((value: string) => [value, value]));
        const uniqueNames: string[] = [];
        uniqueNamesMap.forEach(value => uniqueNames.push(value as string));
        return uniqueNames;
    }


    return (
        <div className={`${classes.mainContainer} flex justify-between mt-0 m-auto w-full`}>
            <div className={`${classes.container} grid`}>
                <div className={`${classes.title} text-base font-semibold`}>
                    {product.catalogueName}
                </div>
                <div className={`${classes.subContainer} flex items-center`}>
                   {productDetails}
                    <span className={`${classes.subText} text-xs font-medium flex`}><LocationOnIcon className= {`${classes.title} !text-sm`} />{" "}{removeDuplicates(product.warehouse)}</span>
                </div>
            </div>
          {label && <Button variant="secondaryContained" label={label} onClick={onClick} />}
        </div>
    );
};

export default OrderProductItemTemplate;