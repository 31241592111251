import React, { useEffect, useState } from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useNavigate } from 'react-router-dom';
import ProductStatusCard from '../../atoms/Cards/ProductStatusCard';
import { useSellerService } from '../../../services/useSellerService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { HTTP_STATUS } from '../../../utils/types';
import DisplayImageV2 from '../../molecules/ImageUpload/DisplayImageV2';
import comingSoonLogo from '../../../assets/images/comingSoonLogo.svg';

interface IProductStats {
    productType: string;
    skuCount: number;
    quantity: number
}

const ProductStatusTemplate: React.FC = () => {

    const sellerService = useSellerService();
    const navigate = useNavigate(); 
    const { user } = useAuthenticatedUser();

    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [comingSoon, setComingSoonTo] = useState<boolean>(false);

    const [stats, setStats] = useState<IProductStats[]>([]);

    const handleToggleClick = (name: string, navigate: any) => {
        const sectionMap: { [key: string]: string } = {
            "Standard Prime": "/dashboard/vendor/product",
        };
        const sectionPath = sectionMap[name];
        if (sectionPath) {
            navigate(sectionPath);
        }
        else {
            setComingSoonTo(true);
        }
    };

    const ProductStatus = async () => {
        if(user?.businessId) {
        sellerService.getProductStatsForSeller(user?.businessId)
            .then((statusResponse) => {
                if (statusResponse.status === HTTP_STATUS.OK) {
                    setStats(statusResponse?.data?.data)
                }
                else {
                    setStats(stats);
                }
            })
            .catch((error) => {
                setStats([]);
                showSnackbar('error', 'Seller stats fetch failed')
            });
        }
    }
    useEffect(() => {
        ProductStatus()
    }, [user?.businessId])

    const customStats = [
        {
            title: "Standard Prime",
            backgroundColor: '#FDF9E8',
            itemspace: 3,
            textColor: "#8D750C",
            onclick: () => handleToggleClick("Standard Prime", navigate),
            productQuantity: stats.find((stat) => stat.productType === "STANDARD")?.quantity || 0,
            productSKU: stats.find((stat) => stat.productType === "STANDARD")?.skuCount || 0
        },
        {
            title: "Non Standard Prime",
            itemspace: 3,
            onclick: () => handleToggleClick("Non Standard Prime", navigate),
            backgroundColor: '#EFF7EE',
            textColor: "#376732",
            productQuantity: stats.find((stat) => stat.productType === "NON_STANDARD")?.quantity || 0,
            productSKU: stats.find((stat) => stat.productType === "NON_STANDARD")?.skuCount || 0
        },
        {
            title: "Commercial Scrap",
            backgroundColor: '#FCE9EA',
            textColor: "#85141B",
            onclick: () => handleToggleClick("Commercial Scrap", navigate),     
            itemspace: 3,
            productQuantity: 0,
            productSKU: 0
        },
        {
            title: "Melting Scrap",
            itemspace: 3,
            textColor: "#1E347B",
            onclick: () => handleToggleClick("Melting Scrap", navigate),
            backgroundColor: '#EAEEFA',
            productQuantity: 0,
            productSKU: 0
        },
    ];

    return (
        <div>
            <DisplayImageV2
                path={comingSoonLogo}
                setImageDialogOpenTo={setComingSoonTo}
                imageDialogOpen={comingSoon}
                showCloseIcon={true} />
            <ProductStatusCard cards={customStats} />
        </div>
    )
}

export default ProductStatusTemplate;