import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import vendorCentral from '../../../assets/images/vendorCentral.svg';
import sellerCentral from '../../../assets/images/sellerCentral.svg';

interface IVendorSellerSelectionProps{
    setConfirmDialogState: (open: boolean) => void;
}
const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: "#18181B"
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral800
    },
    bullet: {
        borderColor: theme.palette.background.primary.primary500
    },
}));

const VendorSellerSelectionTemplate: React.FC<IVendorSellerSelectionProps> = ({setConfirmDialogState}) => {
    const classes = useStyles();

    return (
        <div>
            <div className='flex gap-x-8 my-8 items-center'>
                <div>
                    <img src={sellerCentral} className="" />
                </div>
                <div>
                    <div className={`${classes.heading} text-2xl font-bold`}>Connect directly with buyers and grow your steel business!</div>
                    <div className={`${classes.subHeading} text-lg mt-3`}>Sell to domestic and international buyers on a trusted platform.</div>
                    <div className='flex flex-col gap-3 mt-4'>
                        <span className='flex items-center'>
                            <div className={`${classes.bullet} w-3 h-3 border-2 rounded-full`} />
                            <div className={`${classes.subHeading} font-medium ml-2`}>Explore New Markets and Customers</div>
                        </span>
                        <span className='flex items-center'>
                            <div className={`${classes.bullet} w-3 h-3 border-2 rounded-full`} />
                            <div className={`${classes.subHeading} font-medium ml-2`}>Maximize Sales with Lead Generation</div>
                        </span>
                        <span className='flex items-center'>
                            <div className={`${classes.bullet} w-3 h-3 border-2 rounded-full`} />
                            <div className={`${classes.subHeading} font-medium ml-2`}>Direct Primary Manufacturer Connections</div>
                        </span>
                    </div>
                    <span className="">
                        <Button
                            variant="secondaryText"
                            label="Click here for more details"
                            size='medium'
                            disabled
                        //   onClick={() => { navigate('/dashboard/inventory-management/add'); }}
                        />
                    </span>
                    <div className='mt-4' >
                        <Button
                            variant="secondaryContained"
                            label="Become a Seller Central "
                            size='medium'
                          onClick={() => {setConfirmDialogState(true)}}
                        />
                    </div>
                </div>
            </div>
            <div className='flex gap-x-8 items-center'>
                <div>
                    <img src={vendorCentral} className="" />
                </div>
                <div>
                    <div className={`${classes.heading} text-2xl font-bold`}>Your steel products, our platform – a perfect partnership!</div>
                    <div className={`${classes.subHeading} text-lg mt-3`}>Sell to domestic and international buyers on a trusted platform.</div>
                    <div className='flex flex-col gap-3 mt-4'>
                        <span className='flex items-center'>
                            <div className={`${classes.bullet} w-3 h-3 border-2 rounded-full`} />
                            <div className={`${classes.subHeading} font-medium ml-2`}>Explore New Markets and Customers</div>
                        </span>
                        <span className='flex items-center'>
                            <div className={`${classes.bullet} w-3 h-3 border-2 rounded-full`} />
                            <div className={`${classes.subHeading} font-medium ml-2`}>Maximize Sales with Lead Generation</div>
                        </span>
                        <span className='flex items-center'>
                            <div className={`${classes.bullet} w-3 h-3 border-2 rounded-full`} />
                            <div className={`${classes.subHeading} font-medium ml-2`}>Direct Primary Manufacturer Connections</div>
                        </span>
                    </div>
                    <span className="">
                        <Button
                            variant="secondaryText"
                            label="Click here for more details"
                            size='medium'
                            disabled
                        //   onClick={() => { navigate('/dashboard/inventory-management/add'); }}
                        />
                    </span>
                    <div className='mt-4' >
                        <Button
                            variant="secondaryContained"
                            label="Become a Vendor Central "
                            size='medium'
                            disabled
                        //   onClick={() => { navigate('/dashboard/inventory-management/add'); }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VendorSellerSelectionTemplate;
