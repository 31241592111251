import React from 'react';
import { createUseStyles } from 'react-jss';
import SelectionDialogHeaderTemplate from './SelectionDialogHeader.template';
import VendorSellerSelectionTemplate from './VendorSellerSelection.template';
import Dialog from '@mui/material/Dialog';

interface IVendorSellerSelectionDialogProps{
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    setConfirmDialogState: (open: boolean) => void;
    onSubmit: (response: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "1000px !important",
            maxWidth: "1000px !important",
            padding: "32px 40px 44px 40px",
            borderRadius: "24px"
        },
    },
}));

const VendorSellerSelectionDialogTemplate: React.FC<IVendorSellerSelectionDialogProps> = ({dialogOpen, setDialogOpen, onSubmit,  setConfirmDialogState}) => {
    const classes = useStyles();

    const handleDialogClose = (event: React.MouseEvent | {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason !== "escapeKeyDown") {
            closeDialog();
        }
    }

    const closeDialog = () => {
        setDialogOpen(false)
        onSubmit(false);
    }

    return (
        <Dialog fullWidth className={classes.container} PaperProps={{ style: { height: 'auto' } }}
            open={dialogOpen} onClose={handleDialogClose} >
            <div className={classes.container}>
                <SelectionDialogHeaderTemplate />
                <VendorSellerSelectionTemplate setConfirmDialogState={setConfirmDialogState} />
            </div>
        </Dialog>
    );
}

export default VendorSellerSelectionDialogTemplate;
