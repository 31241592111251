import { createUseStyles } from "react-jss";
import { PAYMENT_METHODS_V2, PAYMENT_SELECTION, PAYMENT_TERMS, PAYMENT_TERMS_SECTION, PAYMENT_TERMS_SECTION_V2 } from "../../../utils/constant";
import React, { useEffect, useState } from "react";
import PaymentIcon from '../../../assets/images/payment.svg';
import RadioActive from '../../../assets/images/radioActive.svg';
import { PaymentTermsSectionProps } from "../../../utils/types";


const useStyles = createUseStyles((theme: any) => ({
    mainheading: {
        color: theme.palette.text.neutral.neutral900,
        lineHeight: "20px"
    },
    mainCointainer: {
        display: "flex"
    },
    container: {
        display: "flex",
        padding: "16px",
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        cursor: "pointer",
    },
    paymentWrapper: {
        borderRadius: "4px",
        background: theme.palette.background.success.success50,
        padding: "4px 8px",
    },
    paymentSelection: {
        color: theme.palette.text.success.success500,
    },
    selectedMethod: {
        // border: `2px solid ${theme.palette.v4.border.secondaryLightAccent}`,
    },
    comingSoon: {
        border: `1px solid ${theme.palette.border.secondary.secondary100}`,
        background: theme.palette.background.secondary.secondary50,
        color: theme.palette.text.secondary.secondary500,
    },
    radio: {
        borderRadius: "100px",
        border: `2px solid ${theme.palette.border.primary.primary400}`,
    },
    selectedRadio: {
        backgroundColor:  theme.palette.background.primary.primary300,
        borderRadius: "100px",
        border: `2px solid ${theme.palette.border.primary.primary800}`,
    },
    disableRadio: {
        borderRadius: "100px",
        border: `2px solid ${theme.palette.border.neutral.neutral200}`,
        background: theme.palette.background.neutral.neutral50,
    },
    details: {
        color: theme.palette.text.primary.primary800,
        fontSize: "16px"
    },
    subText: {
        color: theme.palette.text.neutral.neutral600,
        fontSize: "14px"
    },
    or: {
        color: theme.palette.text.neutral.neutral900,
        margin: "auto 12px"
    },
    "@media (max-width: 767px)": {
        mainCointainer: {
            display: "grid"
        },
        or: {
            margin: "12px auto"
        },
        container: {
            padding: "16px",
        },
        subText: {
            fontSize: "14px"
        },
        heading: {
            fontSize: "16px"
        },
    },
}))

interface IPaymentMethod {
    paymentType?: any;
    handlePaymentTypeChange: (value: string) => void;
}

const PaymentDetailsTemplateV2: React.FC<IPaymentMethod> = ({ paymentType, handlePaymentTypeChange }) => {

    const classes = useStyles();

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(PAYMENT_METHODS_V2.CASH);
    const [filteredPaymentTypes, setFilteredPaymentTypesTo] = useState<PaymentTermsSectionProps[]>([]);

    const handlePaymentTermsClick = (value: string) => () => {
        setSelectedPaymentMethod(value);
        handlePaymentTypeChange(value);
    };

    const ComingSoonTemplate = (title: string) => {
        return (
            <div className={`px-2 py-1 ${classes.comingSoon} text-xs font-semibold text-center rounded`}>
                {title}
            </div>)
    }

    const paymentSelection = (isAvailable: boolean, value: any) => {
        if (isAvailable) {
            if (selectedPaymentMethod === value) {
                return <img src={RadioActive} className="w-[18px] h-[18px] my-auto"/>
            } else {
                return <span className={`${classes.radio} w-[18px] h-[18px] my-auto`} />
            }
        } else {
            return <span className={`${classes.disableRadio} w-[18px] h-[18px] my-auto`} />
        }

    }

    useEffect(()=>{
        const paymentMethods = PAYMENT_TERMS_SECTION_V2.filter(term => paymentType?.includes(term.value));
        setFilteredPaymentTypesTo(paymentMethods);
    }, [paymentType])

    useEffect(() => {
        setSelectedPaymentMethod(paymentType?.[0]);
    }, [paymentType])

    return (
        <div className={`grid gap-y-3`}>
            <div className="flex justify-between">
                <div className={`${classes.mainheading} my-auto text-base font-bold`}>{PAYMENT_TERMS.PAYMENT_TERMS}</div>
                {selectedPaymentMethod &&
                    <div className={`${classes.paymentWrapper} flex gap-x-1.5 align-center`}>
                        <img src={PaymentIcon} alt="payment" className="w-8 h-8 my-auto" />
                        <span className={`${classes.paymentSelection} text-xs font-medium my-auto`}>{selectedPaymentMethod === PAYMENT_METHODS_V2.CASH ? PAYMENT_SELECTION.CASH_DOWN_PAYMENT : PAYMENT_SELECTION.CREDIT_PAYMENT}</span>
                    </div>}
            </div>
            <div className={`${classes.mainCointainer} items-start `}>
                {filteredPaymentTypes?.map(
                    ({ value, heading, subHeading, isAvailable }: PaymentTermsSectionProps, index: number) => (
                        <React.Fragment key={value}>
                            <div
                                className={`${classes.container} w-1/2 flex justify-between rounded-xl ${selectedPaymentMethod === value ? classes.selectedMethod : "border"}`}
                                onClick={isAvailable == true ? () => null : () => null}
                            >
                                <div>
                                    <div className="flex justify-between h-8">
                                        {paymentSelection(isAvailable, value)}
                                        <div>{!isAvailable ? ComingSoonTemplate("Coming Soon") : ""}</div>
                                    </div>
                                    <div className={`${classes.details} mb-1`}>{heading}</div>
                                    <p className={`${classes.subText}`}>{subHeading}</p>
                                </div>
                            </div>
                            {index < filteredPaymentTypes.length - 1 ? (
                                <div className={`${classes.or} text-base font-semibold w-6 text-center`}>
                                    {PAYMENT_TERMS.OR}
                                </div>
                            ) : null}
                        </React.Fragment>
                    )
                )}
            </div>
        </div>
    )
}

export default PaymentDetailsTemplateV2;