import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import CloseIcon from '@mui/icons-material/Close';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/joy';
import { CATALOGUE_TYPE_STATES, CLASS_TYPE, HTTP_STATUS } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useCategoryService } from '../../../services/useCategoryService';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        position: 'fixed',
        width: '100vw',
        minHeight: '70vh',
        zIndex: 9999,
        backgroundColor: 'white',
    },
    closeIcon: {
        color: theme.palette.text.primary.primary500,
    },
    primaryContainer: {
        backgroundColor: '#F8F8F8',
        marginBottom: '5px',
    },
    sectionTitle: {
        color: theme.palette.text.neutral.neutral900,
    },
    subSection: {
        backgroundColor: 'white',
    },
    SubfooterContent: {
        transition: 'all 0.5s ease',
        "& ul": {
            "& li": {
                position: 'relative',
                color: theme.palette.text.neutral.neutral700,
                backgroundColor: "white",
                fontSize: '14px',
                marginBottom: "0px",
                fontWeight: '400',
                textDecoration: 'none',
            },
        },
    },
    "@media (max-width: 767px)": {
        container: {
            minHeight: '0',
        },
    }
}));

const CategoryDialogTemplate: React.FC<{ handleDialogClose: (value: boolean) => void }> = ({ handleDialogClose }) => {
    const classes = useStyles();
    const CategoryService = useCategoryService();
    const { showSnackbar } = useSnackbar();
    const [categoriesFilter, setCategoriesFilter] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [openSection, setOpenSection] = useState<number | null>(null);
    const [openSection1, setOpenSection1] = useState<number | null>(null);
    const [openSection2, setOpenSection2] = useState<number | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        getCategoriesFilters();
    }, []);

    const getCategoriesFilters = async () => {
        try {
            const categoriesFiltersResponse = await CategoryService.getCategoriesFilters();
            if (categoriesFiltersResponse.status === HTTP_STATUS.OK && categoriesFiltersResponse.data) {
                const categoriesData = categoriesFiltersResponse.data.data;
                setCategoriesFilter(categoriesData);
            } else {
                showSnackbar('error', 'No categories found');
            }
        } catch (error) {
            showSnackbar('error', 'Failed to fetch categories');
        } finally {
            setLoading(false);
        }
    };

    const handleProductNavigation = (id: string) => () => {
        navigate(`/catalogue/list?categoryId=${id}&classes=${CLASS_TYPE.STANDARD}&catalogueTypes=${CATALOGUE_TYPE_STATES.CURRENT_INVENTORY}`);
    };

    const renderSubSection1 = (subSection: any , index: number) => (
        <Accordion key={index} expanded={openSection1 === index} onChange={() => handleToggle1(index)} >
            <AccordionSummary className={classes.primaryContainer}>
                <div className={`${classes.sectionTitle} font-normal text-sm ml-5 py-2.5`}>{subSection.name}</div>
            </AccordionSummary>
            <AccordionDetails className={`${classes.SubfooterContent} grid gap-y-2`}>
                <ul>
                    {subSection?.items?.map((e: any, idx: number) => (
                        <li key={idx}>
                            {renderSubSection2(e, idx)}
                        </li>
                    ))}
                </ul>
            </AccordionDetails>
        </Accordion>
    );

    const renderSubSection2 = (subSection: any, index: number) => (
        <Accordion key={index} expanded={openSection2 === index} onChange={() => handleToggle2(index)}>
            <AccordionSummary className={classes.primaryContainer}>
                <div className={`${classes.sectionTitle} font-normal text-sm ml-[30px] py-2.5`}>{subSection.name}</div>
            </AccordionSummary>
            <AccordionDetails className={`${classes.SubfooterContent} grid gap-y-2`}>
                <ul className='flex flex-wrap ml-[40px] gap-3'>
                    {subSection?.items?.map((e: any, idx: number) => (
                        <li key={idx}>
                            {e.path ? (
                                <div className="flex flex-col items-center" onClick={handleProductNavigation(e.id)}>
                                    <img
                                        src={e.path}
                                        alt={e.name}
                                        className="w-16 h-16 object-cover rounded-full"
                                    />
                                    <div className="text-center text-xs mt-1">{e.name}</div>
                                </div>
                            ) : (
                                e.name
                            )}
                        </li>
                    ))}
                </ul>
            </AccordionDetails>
        </Accordion>
    );

    const transformCategoriesToSections = (categories: any[]): any[] => {
        return categories?.map(category => ({
            id: category.id,
            name: category?.name,
            path: category?.path || '',
            items: category?.subCategories?.length
                ? transformCategoriesToSections(category.subCategories)
                : null,
        }));
    };

    const menuSections = categoriesFilter.map((category) => ({
        id: category.id,
        name: category.name,
        items: transformCategoriesToSections(category.subCategories || []),
        path: ""
    }));

    const Sections = [
        {
            name: 'Raw / Semi Finished',
            items: menuSections,
            path: "",
        },
        {
            name: 'Finished Products',
            items: [],
            path: "",
        },
    ];

    const handleToggle = (index: number) => {
        setOpenSection(openSection === index ? null : index);
    };

    const handleToggle1 = (index: number) => {
        setOpenSection1(openSection1 === index ? null : index);
    };

    const handleToggle2 = (index: number) => {
        setOpenSection2(openSection2 === index ? null : index);
    };

    return (
        <div className={classes.container}>
            <div className="my-1 flex justify-end" onClick={() => handleDialogClose(false)}>
                <CloseIcon className={classes.closeIcon} />
            </div>
            <div>
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <div>
                        {Sections.map((section, sectionIndex) => (
                            <Accordion key={sectionIndex} expanded={openSection === sectionIndex} onChange={() => handleToggle(sectionIndex)}>
                                <AccordionSummary className={classes.primaryContainer}>
                                    <div className={`${classes.sectionTitle} font-normal text-sm px-2.5 py-2.5`}>{section.name}</div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul>
                                        {section.items.map((item, idx) => (
                                            <div key={idx}>
                                                {item.items && renderSubSection1(item, idx)}
                                            </div>
                                        ))}
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CategoryDialogTemplate;