import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import {
    FormControl,
    TextField,
} from '@mui/material';
import Button from '../../atoms/Button/Button';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';

interface TagInputProps {
    onTagChange: (tags: string[]) => void;
    displayLabel?: string;
    tags: string[];
    disabled?: boolean;
    errorMessage?: string | null;
}

const TagInputGroup: React.FC<TagInputProps> = ({
    onTagChange,
    displayLabel = '',
    disabled = false,
    tags,
    errorMessage,
}) => {

    const [tagInput, setTagInput] = useState<string>('');

    const onTagInput = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addNewTag();
        } else {
            setTagInput(e.currentTarget.value);
        }
    };

    const addNewTag = () => {
        const newTags = tagInput.split(',').map((tagInput) => tagInput.trim()).filter(Boolean);
        const uniqueNewTags = newTags.filter((tag, index) => {
            return newTags.indexOf(tag) === index && !tags.includes(tag);
        });
        if (uniqueNewTags.length > 0) {
            onTagChange([...tags, ...newTags]);
            setTagInput('');
        }
    }

    const onTagDelete = (deleteIndex: number) => () => {
        const filteredTags = tags.filter((_, index) => index !== deleteIndex);
        onTagChange(filteredTags)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addNewTag();
        }
    };

    const handleDelete = (index: number) => {
        if (!disabled) {
            return onTagDelete(index)
        }
    }

    return (
        <div className='grid gap-y-1'>
            <div className='flex flex-row w-full items-center'>
                <FormControl sx={{ width: '95%' }}>
                    <TextFieldV2
                        label={displayLabel}
                        placeholder='Add Keywords'
                        value={tagInput}
                        onChange={onTagInput}
                        onClick={addNewTag}
                        disabled={disabled}
                        error={!!errorMessage}
                        helperText={errorMessage ? errorMessage : null}
                        onKeyDown={handleKeyDown}
                    />
                </FormControl>
                <div className="mt-auto flex ml-3">
                    <Button
                        variant="primaryContained"
                        label="ADD"
                        onClick={addNewTag}
                    />
                </div>
            </div>
            <Box>
                {tags.map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag}
                        variant="outlined"
                        style={{ margin: '4px' }}
                        onDelete={handleDelete(index)}
                    />
                ))}
            </Box>
        </div>
    );
};

export default TagInputGroup;