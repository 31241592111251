import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import partnershipBanner from '.././../../assets/images/partnersipBanner.png';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import boostReach from '../../../assets/images/boostReach.png';
import partnersipBanner from '../../../assets/images/partnersipBanner.png';
import collaboration from '../../../assets/images/collaboration.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/joy';
import DigitalSecondaryBanner from '../../../assets/images/digitalSecondaryBanner.png';
import buyerProtection from '../../../assets/images/buyerProtection.png';
import financialExpertise from '../../../assets/images/financialExpertise.png';
import steelTransportation from '../../../assets/images/steelTransportation.png';
import { useLocation } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${partnershipBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    bannerSellContainer: {
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: theme.palette.text.primary.primary50,
        "& span": {
            color: theme.palette.text.warning.warning400,
        },
    },
    bannerDescription: {
        color: theme.palette.text.primary.primary50,
        lineHeight: '28px',
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    primaryText: {
        fontSize: '40px',
        color: '#000',
        lineHeight: '48px',
    },
    decContainer: {
        width: '750px',
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontWeight: '400',
        },
    },
    featuresContainer: {
        "& > p": {
            color: theme.palette.text.warning.warning300,
            background: theme.palette.background.primary.primary500,
            padding: '10px 16px',
            width: 'max-content',
        },
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary700,
        },
        "& h4": {
            color: theme.palette.text.neutral.neutral800,
            "& span": {
                color: theme.palette.text.primary.primary500,
                fontWeight: '600',
            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    headingText: {
        fontSize: '48px !important',
        "& span": {
            color: `${theme.palette.text.primary.primary500} !important`,
        },
    },
    oppSubHeading: {
        color: theme.palette.text.neutral.neutral900,
    },
    sectionHeading: {
        color: '#000',
        fontSize: '54px',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    collabCard: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff',
        width: '420px',
        "& h5": {
            fontSize: '22px',
        },
        "& p": {
            lineHeight: '20px',
        },
    },
    transformCard: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        width: '24%',
        "& h5": {
            fontSize: '22px',
        },
        "& p": {
            lineHeight: '20px',
            color: theme.palette.text.neutral.neutral800,
        },
    },
    sectionSubHeading: {
        color: theme.palette.text.neutral.neutral600,
    },
    secondaryBanner: {
        width: '100%',
        height: '480px',
        backgroundImage: `url(${DigitalSecondaryBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    secondaryContainer: {
        '& h2': {
            fontSize: '40px',
            color: theme.palette.text.warning.warning400,
            lineHeight: '48px',
        },
        "& p": {
            color: theme.palette.text.primary.primary50,
            lineHeight: '28px',
        },
    },
    btn3: {
        background: '#fff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btnText: {
        color: theme.palette.text.primary.primary500,
        fontSize: '20px',
    },
    secondaryBannerHeading: {
        color: '#fff',
    },
    btn: {
        color: "#fff",
        background: theme.palette.background.primary.primary500,
        padding: '10px 20px',
        width: '166px',
        marginTop: '16px',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '20px',
    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '100%',
        marginLeft: '10px',
    },
}));

const Partnership: React.FC = () => {

    const classes = useStyles();
    const location = useLocation();
    
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);
    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className={`${classes.heading} font-semibold mb-[28px]`}>Transform Your <br />Business with <br /><span>Steelbazaar Partnerships</span></h1>
                    <p className={`${classes.bannerDescription} text-2xl font-medium`}>Partner with India’s Leading Digital Steel Marketplace to <br />Redefine Trade Efficiency and Growth.</p>
                </div>
            </div>
            <div className={`${classes.intoContainer}`}>
                <div className={`${classes.container} flex items-center justify-between gap-[60px] py-14`}>
                    <div className=''>
                        <p className={`${classes.primaryText} font-semibold`}>Unlock Endless <br /> Opportunities<br />in the Steel Ecosystem</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-lg mb-4'>Steelbazaar, the leading digital marketplace for the steel industry, invites Large Steel Retailers, Stockists, Fintechs, NBFCs, and Logistics Providers to join hands in transforming the steel trade. By leveraging our cutting-edge technology, AI-driven optimization tools, and extensive network of buyers and sellers, partners gain unparalleled access to new markets, streamlined operations, and exponential growth.</p>
                    </div>
                </div>
            </div>
         
            <div className={`${classes.container} py-[90px]`}>
            <div className={`${classes.sectionHeading} font-semibold leading-[66px] mb-[46px] text-center`}>Partnership <span>Opportunities</span></div>
                <div className='flex gap-[80px] items-center justify-between pb-[90px] pt-[46px]'>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-semibold mb-4'>For Steel Retailers and Stockists</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Boost Your Reach,
                                <br /><span className={`${classes.headingText} text-5xl font-bold`}>Maximize Your Sales</span></h4>
                        </div>
                        <div className={`${classes.oppSubHeading} font-medium text-2xl`}>As a Steelbazaar Retailer or Stockist Partner, your business gains:</div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Expanded Customer Base</h5>
                                        <p className='text-lg'>Access a vast network of verified buyers across India.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Enhanced Visibility</h5>
                                        <p className='text-lg'>Showcase your inventory on Steelbazaar's digital storefronts.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Real-Time Market Insights</h5>
                                        <p className='text-lg'>Leverage data-driven tools for better pricing strategies.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Effortless Transactions</h5>
                                        <p className='text-lg'>Simplified catalog management and digital order processing.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Sustainability Integration</h5>
                                        <p className='text-lg'>Participate in SB Green initiatives to promote eco-friendly practices.</p>
                                    </div>
                                </div>
                            <Button type='submit' className={`${classes.btn} text-base leading-5 font-bold`} >Contact Us <div className={classes.btnArrow}><ArrowForwardIcon /></div></Button>

                            </div>
                        </div>
                    </div>
                    <div><img src={boostReach} alt="" /></div>
                </div>

                <div className='flex gap-[80px] items-center justify-between pt-[46px] pb-[90px]' id='finance-partners'>
                    <div><img src={financialExpertise} alt="" /></div>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-semibold mb-4'>For Finance Partners (Fintechs, NBFCs)</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Fuel the Steel Trade with <br /> Your <span className={`${classes.headingText} text-5xl font-bold`}>Financial Expertise</span></h4>
                        </div>
                        <div className={`${classes.oppSubHeading} font-medium text-2xl`}>Join Steelbazaar to support buyers and sellers with tailored financing solutions:</div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Diverse Lending Opportunities</h5>
                                        <p className='text-lg'>Provide trade credit, purchase order financing, and bill discounting to registered users.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>AI-Based Credit Risk Assessmen</h5>
                                        <p className='text-lg'>Leverage our data-driven AI tools for accurate borrower evaluation.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Expanded Market Reach</h5>
                                        <p className='text-lg'>Offer auction financing, bulk trade financing, and export-import finance solutions.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Integrated Platform</h5>
                                        <p className='text-lg'>Seamless integration with Steelbazaar’s systems for easy financing approvals.</p>
                                    </div>
                                </div>
                                <Button type='submit' className={`${classes.btn} text-base leading-5 font-bold`} >Contact Us <div className={classes.btnArrow}><ArrowForwardIcon /></div></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-[80px] items-center justify-between pt-[46px]' id='logistic-partners'>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <p className='text-2xl font-semibold mb-4'>For Logistics Partners</p>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Drive the Future of
                                <br /><span className={`${classes.headingText} text-5xl font-bold`}>Steel Transportation</span></h4>
                        </div>
                        <div className={`${classes.oppSubHeading} font-medium text-2xl`}>Become Steelbazaar's Logistics Partner to streamline material transportation across India:</div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Broadened Client Network</h5>
                                        <p className='text-lg'>Serve verified buyers and sellers through Steelbazaar.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Optimized Freight Management</h5>
                                        <p className='text-lg'>Use AI-powered route optimization for cost-effective deliveries.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Timely and Reliable Services</h5>
                                        <p className='text-lg'>Integrated logistics scheduling ensures efficiency.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>End-to-End Visibility</h5>
                                        <p className='text-lg'>Real-time tracking of consignments builds customer trust.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <h5 className='font-medium mb-1'>Increased Revenue Opportunities</h5>
                                        <p className='text-lg'>Benefit from consistent demand for transportation services.</p>
                                    </div>
                                </div>
                                <Button type='submit' className={`${classes.btn} text-base leading-5 font-bold`} >Contact Us <div className={classes.btnArrow}><ArrowForwardIcon /></div></Button>
                            </div>
                        </div>
                    </div>
                    <div><img src={steelTransportation} alt="" /></div>
                </div>
            </div>

            <div className={`${classes.intoContainer} py-[90px]`} id='channel-trade-partner'>
                <div className={`${classes.container}`}>
                    <div className={`${classes.sectionHeading} font-semibold leading-[66px] mb-10 text-center`}>Why Choose Us for <span>Strategic Collaboration?</span></div>
                    <div className='flex'>
                        <img src={collaboration} alt="" />
                        <div className='p-5'>
                            <h4 className='font-semibold text-[32px] leading-[38px] mb-5'>Benefits of Partnering with Steelbazaar</h4>
                            <div className='flex flex-wrap gap-4'>
                                <div className={`${classes.collabCard} py-5 px-[15px] rounded-lg`}>
                                    <h5 className='mb-3 font-medium'>Technology-Driven Growth</h5>
                                    <p className='text-base'>Steelbazaar’s digital tools and AI-driven insights enhance operational efficiency.</p>
                                </div>
                                <div className={`${classes.collabCard} py-5 px-[15px] rounded-lg`}>
                                    <h5 className='mb-3 font-medium'>Access to Verified Users</h5>
                                    <p className='text-base'>Partner with a trusted ecosystem of registered buyers and sellers.</p>
                                </div>
                                <div className={`${classes.collabCard} py-5 px-[15px] rounded-lg`}>
                                    <h5 className='mb-3 font-medium'>Data-Driven Decisions</h5>
                                    <p className='text-base'>Gain actionable insights into market trends, customer behavior, and trade patterns.</p>
                                </div>
                                <div className={`${classes.collabCard} py-5 px-[15px] rounded-lg`}>
                                    <h5 className='mb-3 font-medium'>Sustainable Business Practices</h5>
                                    <p className='text-base'>Contribute to eco-friendly initiatives like SB Green and circular economy efforts.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} py-[90px]`}>
                <div className={`${classes.sectionHeading} font-semibold leading-[66px] mb-1 text-center`}>How Technology Brings  <span>Transformation ?</span></div>
                <div className={`${classes.sectionSubHeading} font-medium text-2xl leading-[28px] text-center mb-10`}>Redefine Business Efficiency Through Collaboration</div>
                <div className='flex gap-4'>
                    <div className={`${classes.transformCard} py-5 px-[15px] rounded-lg`}>
                        <h5 className='mb-3 font-semibold'>AI-Powered Optimization</h5>
                        <p className='text-base'>Match supply with demand efficiently and predict market trends.</p>
                    </div>
                    <div className={`${classes.transformCard} py-5 px-[15px] rounded-lg`}>
                        <h5 className='mb-3 font-semibold'>IoT Integration</h5>
                        <p className='text-base'>Enable real-time tracking of consignments and inventory.</p>
                    </div>
                    <div className={`${classes.transformCard} py-5 px-[15px] rounded-lg`}>
                        <h5 className='mb-3 font-semibold'>Smart Analytics</h5>
                        <p className='text-base'>Access valuable data for strategic decision-making.</p>
                    </div>
                    <div className={`${classes.transformCard} py-5 px-[15px] rounded-lg`}>
                        <h5 className='mb-3 font-semibold'>Digital Tools</h5>
                        <p className='text-base'>Simplify processes like invoicing, order tracking, and inventory management.</p>
                    </div>
                </div>
            </div>
            <div className={`${classes.secondaryBanner}`}>
                <div className={`${classes.container} ${classes.secondaryContainer} flex flex-col justify-center h-full`}>
                    <h2 className='font-semibold mb-[10px]'>Ready to Transform Your Business?</h2>
                    <div className={`${classes.secondaryBannerHeading} text-[32px] font-semibold mb-[10px]`}>Contact Steelbazaar Today</div>
                    <p className='font-semibold mb-[30px] text-2xl'>Let’s collaborate to build a more efficient, transparent, and sustainable steel industry. <br /> Connect with our team to explore partnership opportunities tailored to your business <br /> needs.</p>
                    <Button className={`${classes.btn3} font-bold text-base flex items-center gap-[10px] px-5 py-[10px] w-max`}> <span className={classes.btnText}>Contact Now</span><div className={`${classes.btnArrow2} flex items-center justify-center`}><ArrowForwardIcon /></div></Button>
                </div>
            </div>
        </>
    );
};
export default Partnership;
