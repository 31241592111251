import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import buyerProtection from '../../../assets/images/buyerProtection.png';
import truckTime from '../../../assets/images/truckTime.png';
import issueRporting from '../../../assets/images/issueRporting.png';
import digiServices from '../../../assets/images/digiServices.png';
import trustAssurance from '../../../assets/images/trustAssurance.png';
import roudTickIcon1 from '../../../assets/icons/roudTickIcon1.svg';
import roudTickIcon2 from '../../../assets/icons/roudTickIcon2.svg';
import BannerComp from '../../../assets/images/BannerComp.png';
import { useLocation } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '1297px',
        margin: '0 auto',
    },
    bannerContainer: {
        width: '100%',
        height: '650px',
        backgroundImage: `url(${BannerComp})`,
        backgroundSize: 'cover',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    bannerSellContainer: {
        "& h1": {
            fontSize: '56px',
            lineHeight: '66px',
            color: theme.palette.text.warning.warning400,
        },
        "& p": {
            color: '#fff',
            lineHeight: '66px',
            fontSize: '56px',
        },
        "& h3": {
            color: '#fff',
        },
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    text: {
        fontSize: '40px',
        color: "#000",
        lineHeight: '48px',
    },
    decContainer: {
        width: '780px',
        "& p": {
            color: "#000",
            lineHeight: '26px',
        },
    },
    headingText: {
        fontSize: '48px !important',
        "& span": {
            color: `${theme.palette.text.primary.primary500} !important`,
        },
    },
    featuresContainer: {
        "& > h5": {
            color: '#000',
        },
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary700,
        },
        "& h4": {
            color: theme.palette.text.neutral.neutral800,
            "& span": {
                color: theme.palette.text.primary.primary500,
                fontWeight: '600',
            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
    featuresText: {
        width: '651px',
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontSize: '22px',
        },
    },
    sectionHeading: {
        color: '#000',
        fontSize: '54px',
        lineHeight: '66px',
        "& span": {
            fontSize: '54px',
            color: theme.palette.text.primary.primary500,
            lineHeight: '66px',
        },
    },
    sellerContainer: {
        background: theme.palette.background.primary.primary50,
        position: "relative",
    },
    buyerContainer: {
        background: theme.palette.background.primary.primary500,
    },
    sellerHeading: {
        fontSize: '40px',
        lineHeight: '48px',
        color: theme.palette.text.neutral.neutral800,
        borderBottom: `1px solid ${theme.palette.border.primary.primary500}`,
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary500,
        },
    },
    sellerSubHeading: {
        color: '#000',
        lineHeight: '34px',
    },
    buyerSubHeading: {
        color: '#fff',
        lineHeight: '34px',
    },
    benifitContent: {
        "& h5": {
            fontSize: '28px',
            lineHeight: '34px',
            color: '#000',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '22px',
            lineHeight: '26px',
        },
    },
    buyerHeading: {
        color: '#fff',
        fontSize: '40px',
        lineHeight: '48px',
        borderBottom: '1px solid #fff',
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.warning.warning500,
        },
    },
    benifitContentBuyer: {
        "& h5": {
            fontSize: '28px',
            lineHeight: '34px',
            color: '#fff',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral50,
            fontSize: '22px',
            lineHeight: '26px',
        },
    },
    benifitExtra: {
        position: 'absolute',
        top: '121px',
        right: '-25px',
    },
    extra2: {
        background: theme.palette.background.primary.primary200,
        width: '24px',
        height: '24px',
    },
    extra1: {
        background: theme.palette.background.primary.primary500,
        width: '24px',
        height: '24px',
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600,
        textAlign: 'center',
    },
}));

const TrustAssurance: React.FC = () => {

    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);

    
    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className={`${classes.container} ${classes.bannerSellContainer}`}>
                    <h1 className='font-semibold mb-7'>Steelbazaar Trust<br />Assurance</h1>
                    <p className='font-semibold'>Secure, Reliable, and <br /> Transparent Trading</p>
                </div>
            </div>
            <div className={`${classes.intoContainer}`}>
                <div className={`${classes.container} flex items-center justify-between gap-10 py-14`}>
                    <div className=''>
                        <p className={`${classes.text} font-semibold mb-2`}>Secure, Reliable, and <br />Transparent Trading</p>
                    </div>
                    <div className={classes.decContainer}>
                        <p className='text-lg mb-4'>At Steelbazaar, trust is the foundation of every transaction. Our Trust Assurance Program <br />ensures safe payments, quality products, on-time deliveries, and seamless dispute resolution<br />for buyers and sellers alike.</p>
                    </div>
                </div>
            </div>
            <div className={`${classes.intoContainer}`}>

            </div>
            <div className={`${classes.container} py-[110px]`}>
                <h2 className={`${classes.sectionHeading}  font-semibold text-center`}><span className='font-semibold'>Trust Assurance </span>for Buyers</h2>
                <h3 className={`${classes.subHeading} font-medium text-2xl mt-1 mb-10`}>Buy with Confidence on Steelbazaar</h3>

                <div className='flex gap-[60px] items-center justify-between pt-[46px] pb-[90px] pb-[90px]'>
                    <div className='w-[700px]' id='buyer-protection'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Payment Security with<br /><span className={`${classes.headingText} text-5xl font-bold`}>Buyer Protection</span></h4>
                            <h5 className='text-[28px] font-semibold leading-[34px]'>Secure Payments Every Step of the Way</h5>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Funds are held in escrow until the buyer confirms satisfaction with the product.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Steelbazaar safeguards every transaction from unauthorized <br /> access or fraud.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Refunds are initiated for unfulfilled or disputed orders based on predefined policies.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={buyerProtection} alt="" /></div>
                </div>
                <div className='flex gap-[60px] items-center justify-between pt-[46px] pb-[90px] pb-[90px]' id='quality-assurance'>
                    <div><img src={trustAssurance} alt="" /></div>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Quality <span className={`${classes.headingText} text-5xl font-bold`}>Assurance</span></h4>
                            <h5 className='text-[28px] font-semibold leading-[34px]'>Guaranteed Quality Products</h5>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>All products adhere to agreed specifications, including <br />
                                            material grades and certifications.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Regular seller audits maintain strict quality benchmarks.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Buyers receive mill test certificates and documentation for verification.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-[60px] items-center justify-between pt-[46px] pb-[90px] pb-[90px]' id='ontime-delivery'>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>On-Time <span className={`${classes.headingText} text-5xl font-bold`}>Deliveries</span></h4>
                            <h5 className='text-[28px] font-semibold leading-[34px]'>Timely Deliveries You Can Trust</h5>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Sellers commit to guaranteed delivery timelines for <br />
                                            every order.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Real-time tracking enables buyers to monitor <br />
                                            shipment progress.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Proactive resolution for delayed shipments ensures <br />
                                            minimal disruptions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={truckTime} alt="" /></div>
                </div>
            </div>
            <div id='verified-buyers'>
                <div className={`${classes.intoContainer} pt-[90px]`}>
                    <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Trust Assurance <span>for Sellers</span></h2>
                    <h3 className={`${classes.subHeading} font-medium text-2xl mt-1`}>Grow Your Business with Steelbazaar</h3>
                    <div className='flex mt-10'>
                        <div className={`${classes.sellerContainer} w-1/2 py-10 px-[80px]`}>
                            <div className={`${classes.sellerHeading} font-semibold pb-2`}><span>Verified </span>Buyers</div>
                            <div className='flex flex-col gap-4 mt-4'>
                                <h4 className={`${classes.sellerSubHeading} font-semibold text-[28px]`}>Connect with Reliable Buyers</h4>
                                <div className='flex items-start gap-3'>
                                    <img src={roudTickIcon1} alt="" />
                                    <div className={`${classes.benifitContent}`}>
                                        <p>Buyers undergo a detailed verification process to <br /> ensure credibility.</p>
                                    </div>
                                </div>
                                <div className='flex items-start gap-3'>
                                    <img src={roudTickIcon1} alt="" />
                                    <div className={`${classes.benifitContent}`}>
                                        <p>Verified buyer status boosts seller confidence and <br /> transaction security.</p>
                                    </div>
                                </div>
                                <div className='flex items-center gap-3'>
                                    <img src={roudTickIcon1} alt="" />
                                    <div className={`${classes.benifitContent}`}>
                                        <p>Access to pre-vetted leads from genuine buyers.</p>
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.benifitExtra}`}>
                                <div className='flex flex-col gap-[120px]'>
                                    <div className='flex'>
                                        <div className={`${classes.extra1}`}>
                                        </div>
                                        <div className={`${classes.extra2}`}>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className={`${classes.extra1}`}>
                                        </div>
                                        <div className={`${classes.extra2}`}>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={`${classes.buyerContainer} w-1/2 py-10 px-[80px]`}>
                            <div className={`${classes.buyerHeading} font-semibold pb-2`}>Timely <span>Payments</span></div>
                            <div className='flex flex-col gap-4 mt-4'>
                                <h4 className={`${classes.buyerSubHeading} font-semibold text-[28px]`}>Payments When You Need Them</h4>
                                <div className='flex items-start gap-3'>
                                    <img src={roudTickIcon2} alt="" />
                                    <div className={`${classes.benifitContentBuyer}`}>
                                        <p>Escrow services release payments promptly upon <br />order fulfillment.</p>
                                    </div>
                                </div>
                                <div className='flex items-start gap-3'>
                                    <img src={roudTickIcon2} alt="" />
                                    <div className={`${classes.benifitContentBuyer}`}>
                                        <p>Transparent payment schedules improve cash flow <br />management.</p>
                                    </div>
                                </div>
                                <div className='flex items-start gap-3'>
                                    <img src={roudTickIcon2} alt="" />
                                    <div className={`${classes.benifitContentBuyer}`}>
                                        <p>Dispute-free transactions ensure hassle-free <br />payment settlements.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${classes.container} py-[110px]`} id='mediation-services'>
                <h2 className={`${classes.sectionHeading}  font-semibold text-center`}><span className='font-semibold'>Dispute </span>Resolution</h2>
                <h3 className={`${classes.subHeading} font-medium text-2xl mt-1`}>Fair and Efficient Resolution Processes</h3>
                <div className='flex gap-[60px] items-center justify-between pt-[46px] pb-[46px] pb-[90px]'>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer}`} id='issue-reporting'>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Issue <span className={`${classes.headingText} text-5xl font-bold`}>Reporting</span></h4>
                            <h5 className='text-[28px] font-semibold leading-[34px]'>Report Concerns Easily</h5>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Buyers and sellers can raise disputes online for product, <br /> payment, or delivery issues.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Automated ticketing tracks the resolution process.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Dedicated support teams respond to disputes within <br />
                                            24-48 hours.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><img src={issueRporting} alt="" /></div>
                </div>
                <div className='flex gap-[60px] items-center justify-between pt-[46px] pb-[90px] pb-[46px]'>
                    <div><img src={digiServices} alt="" /></div>
                    <div className='w-[700px]'>
                        <div className={`${classes.featuresContainer} `}>
                            <h4 className='mt-[6px] mb-[15px] text-5xl font-bold'>Mediation <span className={`${classes.headingText} text-5xl font-bold`}>Services</span></h4>
                            <h5 className='text-[28px] font-semibold leading-[34px]'>Resolving Disputes Fairly</h5>
                        </div>
                        <div className={`${classes.featureInfo}`}>
                            <div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Expert mediators provide impartial resolution for<br /> buyer-seller conflicts.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-center'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Dispute outcomes are based on documented terms <br />and agreements.</p>
                                    </div>
                                </div>
                                <div className='flex gap-[15px] mt-[15px] items-start'>
                                    <img src={roudTickIcon} alt="" />
                                    <div className={classes.featuresText}>
                                        <p className='text-lg font-medium'>Focused on preserving trust and long-term <br />
                                            partnerships.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default TrustAssurance;
