import React, { useCallback, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import noproduct from '../../../assets/icons/myProductsEmptyState.svg'
import { ICatalogueAttributes, ICatalogueRequestBody, useCatalogueService } from '../../../services/useCatalogueService';
import { CLASS_STATES, IPagination } from '../../../utils/types';
import { useDebounce } from '../../../utils/helper';
import { DEBOUNCE_TIME } from '../../../utils/constant';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { Catalogue } from '../CatalogueList/CatalogueList.template';
import Button from '../../atoms/Button/Button';
import CatalogueGridItem from '../CatalogueList/CatalogueGridItem.template';
import { useSnackbar } from '../../../hooks/useSnackBar';
import DisplayImageV2 from '../../molecules/ImageUpload/DisplayImageV2';
import comingSoonLogo from '../../../assets/images/comingSoonLogo.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    header: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
        padding: "16px 24px"
    },
    mainHeading: {
        color: theme.palette.text.neutral.neutral970
    },
    catalogueGrid: {
        display: "grid",
        gridGap: "16px",
        gridTemplateColumns: "repeat(5, 1fr)",
    },
    description: {
        color: theme.palette.text.neutral.neutral600
    },
    title: {
        color: theme.palette.text.neutral.neutral800
    },
    subContainer: {
        padding: '16px 24px 24px 24px'
    }
}));

const TopSellingProductsTemplate: React.FC = () => {

    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const catalogueService = useCatalogueService();
    const [pagination, setPaginationTo] = useState<IPagination>({
        size: 12,
        totalRecords: 0,
        page: 0,
    });
    const [catalogues, setCataloguesTo] = useState<Catalogue[]>([]);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [comingSoon, setComingSoonTo] = useState<boolean>(false);

    const refreshCatalogues = useCallback(
        useDebounce(async () => {
            try {
                let payload: ICatalogueRequestBody = {
                    standardIds: null,
                    brandIds: null,
                    gradeIds: null,
                    shapes: null,
                    manufacturerIds: null,
                    categoryId: null
                };
                const specificationsPayload: { [key: string]: ICatalogueAttributes } = {};
    
                payload = { ...payload, attributes: { ...specificationsPayload } };
                const cataloguesResponse = await catalogueService.getVendorCatalogues(
                    user?.businessId,
                    {
                        productType: CLASS_STATES.STANDARD,
                        page: pagination.page,
                        size: pagination.size,
                    },
                    payload
                );
                if (cataloguesResponse?.data?.data?.catalogueDetailsResponseDTOS?.content) {
                    const { content, totalElements } = cataloguesResponse.data.data.catalogueDetailsResponseDTOS;
    
                    const updatedCatalogues = content.map((catalogue: any) => ({
                        ...catalogue,
                        minimumPrice: catalogue.cataloguePrice || 0,
                        maximumPrice: catalogue.cataloguePrice || 0,
                        stockAvaliable: true,
                        uom: "MT",
                        moq: "-"
                    }));
    
                    setCataloguesTo(updatedCatalogues);
                    setPaginationTo((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalRecords: totalElements,
                    }));
                } else {
                    showSnackbar('error', 'Catalogue fetch failed');
                }
            } catch (error) {
                setCataloguesTo([]);
            }
        }, DEBOUNCE_TIME.CATALOGUE_FILTERS),
        [pagination.page,pagination.size]
    );

    useEffect(() => {
        refreshCatalogues();
    }, [pagination.page, pagination.size]);

    return (
        <div className={`rounded-2xl ${classes.container}`}>
            <DisplayImageV2
                path={comingSoonLogo}
                setImageDialogOpenTo={setComingSoonTo}
                imageDialogOpen={comingSoon}
                showCloseIcon={true} />
            <div className={`flex justify-between ${classes.header}`}>
                <span className={`font-medium ${classes.mainHeading}`}>Top Selling Products</span>
                {catalogues.length > 0 && (
                    <Button size='small' variant="secondaryText" label="View Details" onClick={() => setComingSoonTo(true)} />
                )}
            </div>
            <div className={classes.subContainer}>
                {catalogues.length > 0 ? (
                    <div className="overflow-x-auto overflow-hidden w-[1040px]">
                        <div className="flex gap-x-6 w-max">
                            {catalogues.map((item: any, index) => (
                                <div key={item?.id || index} className='w-[240px]'>
                                    <CatalogueGridItem data={item} label="wishlist" />
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col items-center m-0 ">
                        <img src={noproduct} alt="popup-icon" />
                        <span className={`text-lg text-center font-semibold ${classes.title}`}>
                            No products to show
                        </span>
                    </div>
                )}
            </div>
        </div>)
}
export default TopSellingProductsTemplate;