import { createUseStyles } from 'react-jss';
import SuccessPopupIcon from '../../../assets/images/successPopupIcon.svg';
import FailurePopupIcon from '../../../assets/images/failurePopupIcon.svg';
import deletePopupIcon from '../../../assets/images/deletePopupIcon.svg';
import AddPopupIcon from '../../../assets/images/addPopupIcon.svg';
import InfoPopupIcon from '../../../assets/images/InfoPopupIcon.svg';
import closeIcon from "../../../assets/icons/closeRoundIcon.svg";
import Button from '../../atoms/Button/Button';

interface ButtonProps {
    text: string,
    variant: CustomVariant,
    size: CustomSize,
    onClick: () => void,
}

type Variant = "Success" | "Failure" | "Add" | "Delete" | "Info";

interface SuccessFailurePopupProps {
    width?: string,
    height?: string,
    heading: string | React.ReactNode,
    setShowPopup: (value: boolean) => void,
    button1?: ButtonProps,
    button2?: ButtonProps,
    button3?: ButtonProps,
    description?: string | React.ReactNode,
    variant?: Variant,
}

type CustomVariant = "primaryContained" | "secondaryContained" | "tertiaryContained" | "primaryText" | "tertiaryText";
type CustomSize = "small" | "medium" | "large";

const useStyles = createUseStyles((theme: any) => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `${theme.palette.background.primary.primary900}40`,
        zIndex: 99,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    primaryContainer: {
        width: "479px",
        padding: "24px",
        boxShadow: `0px 4px 16px 0px rgba(139, 139, 139, 0.08)`,
        zIndex: 1,
        border: `1 px solid ${theme.palette.border.neutral.neutral100}`
    },
    container: {
        rowGap: "24px"
    },
    description: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "18px",
        // width:"357px"
    },
    btn: {
        columnGap: "16px",
    },
    heading: {
        color: theme.palette.text.primary.primary800,
        fontSize: "40px",
        lineHeight: "48px"
    },
    "@media (max-width: 767px)": {
        primaryContainer: {
            width: "280px",
            padding: "24px 12px",
        },
        container: {
            rowGap: "10px",
            marginTop: "-48px"
        },
        description: {
            fontSize: "16px",
            lineHeight: "20px"
        },
        btn: {
            columnGap: "12px",
        },
        heading: {
            fontSize: "28px",
            lineHeight: "34px",
            fontWeight: 500
        },
    },
}))

const SuccessFailurePopup: React.FC<SuccessFailurePopupProps> = ({
    width,
    height,
    heading,
    setShowPopup,
    button1,
    button2,
    button3,
    description,
    variant
}) => {

    const classes = useStyles();

    const divStyle = {
        height,
        width,
    };

    const handleClose = () => {
        setShowPopup(false);
    }

    const getIconByVariant = () => {
        switch (variant) {
            case "Success":
                return SuccessPopupIcon;
            case "Failure":
                return FailurePopupIcon;
            case "Delete":
                return deletePopupIcon;
            case "Add":
                return AddPopupIcon;
            case "Info":
                return InfoPopupIcon;
            default:
                return null;
        }
    };

    return (
        <div className={classes.overlay}>
            <div className={`${classes.primaryContainer} grid gap-y-1 rounded-2xl overflow-hidden relative bg-white`} style={divStyle}>
                <div className={`cursor-pointer flex justify-end`} onClick={handleClose} >
                    <img src={closeIcon} alt="closeIcon" />
                </div>
                <div className={`${classes.container} flex flex-col items-center`}>
                    {getIconByVariant() && (
                        <img src={getIconByVariant() || ''} alt="popup-icon" />
                    )}

                    <div className='grid gap-y-4 text-center'>
                        <div className={`${classes.heading} font-medium`}>{heading}</div>
                        <p className={`${classes.description} px-4 text-center font-normal`}>{description}</p>
                    </div>

                    <div className={`${classes.btn} flex justify-center`}>
                        {button1 && <Button
                            className=''
                            variant={button1.variant}
                            label={button1.text}
                            size={button1.size}
                            onClick={button1.onClick}
                        />}
                        {button2 && <Button
                            className=''
                            variant={button2.variant}
                            label={button2.text}
                            size={button2.size}
                            onClick={button2.onClick}
                        />}
                    </div>
                    {button3 && <div className={`flex w-4/5 justify-center`}>
                        <Button
                            className=''
                            variant={button3.variant}
                            label={button3.text}
                            size={button3.size}
                            onClick={button3.onClick}
                        />
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default SuccessFailurePopup;