import React, { useState } from 'react'
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import comingsoon from '../../../assets/icons/myAuctions.png'
import comingSoonLogo from '../../../assets/images/comingSoonLogo.svg';
import DisplayImageV2 from '../../molecules/ImageUpload/DisplayImageV2';

const useStyles = createUseStyles((theme: any) => ({
  container: {
    border: `1px solid ${theme.palette.border.neutral.neutral100}`,
  },
  header: {
    borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    padding: "16px 24px"
  },
  mainHeading: {
    color: theme.palette.text.neutral.neutral970
  },
  subContainer: {
    padding: '16px 24px 24px 24px'
}
}));

const MyAuctionTemplate: React.FC = () => {

  const classes = useStyles();
  const [comingSoon, setComingSoonTo] = useState<boolean>(false);

  return (
    <div className={`rounded-2xl ${classes.container}`}>
      <DisplayImageV2
        path={comingSoonLogo}
        setImageDialogOpenTo={setComingSoonTo}
        imageDialogOpen={comingSoon}
        showCloseIcon={true} />
      <div className={`flex justify-between ${classes.header}`}>
        <span className={`font-medium ${classes.mainHeading}`}>My Auctions</span>
        <Button size='small' variant="secondaryText" label={"View Details"} onClick={() => setComingSoonTo(true)}/>
      </div>
      <div className={classes.subContainer}>
        <img className="w-full" src={comingsoon} alt="Coming Soon" />
      </div>
    </div>
  )
}
export default MyAuctionTemplate