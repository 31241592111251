import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from '../../atoms/Button/Button';
import { CATEGORY_TYPE, HTTP_STATUS, ONBOARDING_STATES, option } from '../../../utils/types';
import { IOnBoardingPayload } from '../../pages/OnBoarding/OnBoarding.page';
import { useBusinessService } from '../../../services/useBusinessService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useNavigate } from 'react-router-dom';
import { FormikProps } from 'formik';
import { CUSTOMER_ROUTES } from '../../../utils/constant';
import { ICategory } from '../Rfq/ProductRequirementForm.template';
import { useAdminMasterService } from '../../../services/useAdminMasterService';
import AutoCompleteInputV2, { AutoCompleteOption } from '../../atoms/AutoCompleteInput/AutoCompleteInput';
import FilterChip from '../../atoms/FilterChip/FilterChip';
import { IMultiSelectOption } from '../../atoms/FilterChipV2/FilterChipV2';
import { FormHelperText } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

interface ServiceRequirementsTemplateProps {
    formik: FormikProps<IOnBoardingPayload>;
    setFormState: (value: ONBOARDING_STATES) => void;
};

export interface IServiceRequirements {
    business: {
        serviceRequirements: string
    }
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        rowGap: "40px"
    },
    headingContainer: {
        rowGap: "4px"
    },
    heading: {
        color: theme.palette.text.neutral.neutral800,
        fontSize: "28px",
        lineHeight: "34px"
    },
    description: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "20px"
    },
    formContainer: {
        rowGap: "40px"
    },
    formSubContainer: {
        rowGap: "12px"
    },
    selected: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        background: theme.palette.background.primary.primary50,
        padding: "10px 16px"
    },
    label: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px"
    },
    unSelected: {
        color: theme.palette.text.primary.primary800,
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        "&:hover": {
            background: theme.palette.background.primary.primary50,
        },
        padding: "10px 16px"
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
    skipButton: {
        width: "290px"
    },
    nextButton: {
        width: "179px"
    },
    "@media (max-width: 767px)": {
        container: {
            rowGap: "10px",
            padding: "0px 10px 10px 10px"
        },
        headingContainer: {
            rowGap: "0px",
            paddingBottom: "4px",
            borderBottom: `1px dashed ${theme.palette.border.neutral.neutral300}`
        },
        heading: {
            color: theme.palette.text.neutral.neutral800,
            fontSize: "22px",
            lineHeight: "26px"
        },
        description: {
            color: theme.palette.text.neutral.neutral700,
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16px"
        },
        formContainer: {
            rowGap: "10px"
        },
        formSubContainer: {
            rowGap: "10px"
        },
        unSelected: {
            padding: "4px 8px"
        },
        selected: {
            padding: "4px 8px"
        },
        skipButton: {
            width: "auto",
        },
        nextButton: {
            width: "auto",
        },
    }
}))

const ProductCategoryTemplate: React.FC<ServiceRequirementsTemplateProps> = ({ formik, setFormState }) => {
    const classes = useStyles();
    const businessService = useBusinessService();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const navigate = useNavigate();
    const adminService = useAdminMasterService();
    const [superCategoryData, setSuperCategoryData] = useState<ICategory[] | null>(null);
    const [mainCategoryData, setMainCategoryData] = useState<ICategory[] | null>(null);
    const [categories, setCategoriesTo] = useState<option[]>([]);
    const [productCategory, setProductCategory] = useState<IMultiSelectOption[]>([]);
    const [error, setError] = useState(false);

    const updateSelectedCategory = (key: string) => (option: AutoCompleteOption | null) => {
        formik.setFieldValue(key, option);
        if (key === "superCategory") {
            formik.setFieldValue("mainCategory", { label: "", id: null });
            formik.setFieldValue("productCategory", { label: "", id: null });
            setProductCategory([]);
            setCategoriesTo([]);
        }
        if (key === "mainCategory") {
            formik.setFieldValue("productCategory", { label: "", id: null });
            setProductCategory([]);
            setCategoriesTo([]);
        }
    }

    const handleSubmit = () => {
        const selectedCategories = formik.values.categories;
        if (selectedCategories.length === 0) {
            setError(true);
            return;
        }
        formik.setSubmitting(true);
        user && businessService.updateBusiness({ business: { categories: selectedCategories.join(",") } }, user.businessId)
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {

                    navigate(CUSTOMER_ROUTES.ONBOARDING_WELCOME);
                }
                else showSnackbar('error', 'Something went wrong');
            }).catch(error => {
                console.error(error)
                showSnackbar('error', 'Something went wrong')
            }).finally(() => { formik.setSubmitting(false); });
    }

    const onBack = () => {
        setFormState(ONBOARDING_STATES.REQUIREMENTS)
    }

    const handleSkip = () => {
        navigate('/');
    }

    const loadSuperCategory = (inputValue?: string) => {
        adminService.getAllCategories({ level: CATEGORY_TYPE.SUPER_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setSuperCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadMainCategory = (inputValue?: string) => {
        if (formik?.values?.superCategory?.id) {
            adminService.getAllCategories({ level: CATEGORY_TYPE.MAIN_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik?.values?.superCategory?.id ?? '', name: inputValue ?? '', status: 'ACTIVE' })
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK)
                        setMainCategoryData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Category: ", error);
                })
        }
    }

    const loadProductCategory = () => {
        if (formik?.values?.mainCategory?.id) {
            setCategoriesTo([]);
            adminService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik?.values?.mainCategory?.id ?? '', name: '', status: 'ACTIVE' })
                .then(res => {
                    if (res.data) {
                        let productCategoryFilterValues: option[] = [];
                        res?.data?.data?.content?.forEach((productCategory: any) => {
                            productCategoryFilterValues.push({ value: productCategory.id, label: productCategory.name });
                        });
                        setCategoriesTo(productCategoryFilterValues);
                    }
                })
                .catch((error) => {
                    console.error("Error Fetching Category: ", error);
                })
        }
    }

    const handleSelectionChangeCategory = (selected: IMultiSelectOption[]) => {
        const formattedSelection = selected.map((option) => ({
            id: option.value,
            label: option.label,
        }));
        setError(false)
        setProductCategory(selected);
        formik.setFieldValue('productCategory', formattedSelection);
        formik.setFieldValue('categories', formattedSelection.map((option) => option.id));
    };

    const superCategoryOptions = useMemo(() => {
        return superCategoryData?.map((superCategory: ICategory) => ({ label: superCategory.name, id: superCategory.id })) ?? []
    }, [superCategoryData])

    const mainCategoryOptions = useMemo(() => {
        return mainCategoryData?.map((mainCategory: ICategory) => ({ label: mainCategory.name, id: mainCategory.id })) ?? []
    }, [mainCategoryData])

    useEffect(() => {
        formik.setFieldValue('categories', []);
        setCategoriesTo([]);
    }, [formik?.values?.mainCategory?.id]);

    useEffect(() => {
        const formattedSelection = formik?.values?.productCategory?.map((option) => ({
            id: option.id,
            label: option.label,
            value: option.id,
        }));
        setProductCategory(formattedSelection as IMultiSelectOption[]);
        setCategoriesTo([]);
    }, []);

    useEffect(() => {
        loadProductCategory();
    }, [formik?.values?.mainCategory?.id]);

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    return (
        <div className={`${classes.container} grid`}>
            {SnackBarComponent}
            <div className={`grid ${classes.headingContainer}`}>
                <div className={`${classes.heading} font-medium`} >Business Profile</div>
                <div className={`${classes.description} text-base`}>Please verify your business details</div>
            </div>
            <div className={`grid ${classes.formContainer}`}>
            <div className={`grid ${classes.formSubContainer}`}>
               
                        <div className='w-full grid'>
                            <AutoCompleteInputV2
                                placeholder='Select Here'
                                options={superCategoryOptions}
                                label="Super Category"
                                onChange={updateSelectedCategory('superCategory')}
                                onSearch={loadSuperCategory}
                                value={formik.values.superCategory}
                                error={!!formik.errors.superCategory?.id && formik.touched.superCategory?.id}
                            />
                            {formik.errors.superCategory?.id && formik.touched.superCategory && (
                                <div className={`${classes.errorMessage}`}>
                                    <small>{formik?.errors?.superCategory?.id}</small>
                                </div>
                            )}
                        </div>
                        <div className='w-full grid'>
                            <AutoCompleteInputV2
                                placeholder='Select Here'
                                options={mainCategoryOptions}
                                label="Main Category"
                                onChange={updateSelectedCategory('mainCategory')}
                                onSearch={loadMainCategory}
                                value={formik.values.mainCategory}
                                error={!!formik.errors.mainCategory?.id && formik.touched.mainCategory?.id}
                                isDisabled={!formik?.values?.superCategory?.id}
                            />
                            {formik.errors.mainCategory?.id && formik.touched.mainCategory && (
                                <div className={`${classes.errorMessage}`}>
                                    <small>{formik?.errors?.mainCategory?.id}</small>
                                </div>
                            )}
                        </div>
                        <div className='w-full grid'>
                            <FilterChip
                                isSearchable={false}
                                options={categories}
                                label="Product Category"
                                value={productCategory}
                                onchange={handleSelectionChangeCategory}
                                placeholder="Select Here"
                            />
                            {error &&
                                <FormHelperText error className='top-half '>Product Category is required</FormHelperText>
                            }
                    </div>
                </div>
            </div>

            <div className='flex justify-between'>
                <Button size={isMobile ? "small" : "medium"} variant='tertiaryContained' label={"Back"} className='' onClick={onBack} />
                <div className='flex justify-end gap-x-3'>
                    <Button size={isMobile ? "small" : "medium"} variant='tertiaryContained' label={"Skip for now"} className={classes.skipButton} onClick={handleSkip} />
                    <Button size={isMobile ? "small" : "medium"} variant="primaryContained" label="Submit" className={classes.nextButton} onClick={handleSubmit} isLoading={formik.isSubmitting} />
                </div>
            </div>
        </div>)
}

export default ProductCategoryTemplate