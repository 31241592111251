import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";
import { IManufacturerFilterRequest } from "./useAdminMasterService";

export const ADMIN_MASTER_V2_URLS = {
    GET_ALL_ATTRIBUTES: "/attributes",
    GET_ALL_BRANDS: "/customer/brands",
    GET_ALL_USER: "/users",
    GET_ALL_SECONDARY_INVENTORY: "/secondary-products",
    GET_PRODUCT_UPC: "/product-upcs",
    SEARCH_UPC: "/search/upc",
    GET_PRODUCT_UPC_BRAND_MAPPING: "/brand-upc/:id",
    SEARCH_UPC_BY_VENDOR_ID: "/search/vendor/:vendorId/upc",
    GET_ROOT_AND_VENDOR_BRANDS: "/customer/root-brands/:vendorId",
    GET_ROOT_AND_VENDOR_MANUFACTURERS: "/customer/root-manufacturers/:vendorId",
}

interface IAttributeFilterRequest {
    status?: string;
    parentId?: number;
    attributeType?: string;
    name?: string;
    page?: number;
    size?: number;
    sort?: string;
}

export interface IBrandSearchRequest {
    name?: string;
    page: number;
    size: number;
    sort?: string;
    productCategoryId?: number | null;
}

interface IUserSearchParams {
    page: number;
    size: number;
    sort?: string;
    userType?: string;
    search?: string;
}


interface ICategoryFilterRequest {
    name?: string;
    standardIds?: number[];
    gradeIds?: number[];
    brandIds?: number[];
    shapes?: string[];
    manufacturerIds?: number[];
    classes?: string[];
    categoryId?: number;
}

interface ISecondaryInventoryFilterRequest {
    warehouse?: string;
    skuCode?: string;
    categoryId?: number;
    page: number;
    size: number;
    sort?: string;
}

export interface IProductUPCRequest {
    classType?: string;
    status?: string;
    upcCode?: string;
    page?: number;
    size?: number;
    sort?: string;
    categoryId?: number;
}

export interface IProductUpcFilterRequest {
    upcId?: number;
    upcCode?: string;
    superCategory?: string;
    mainCategory?: string;
    productCategory?: string;
    manufacturer?: string;
    brand?: string;
    standard?: string;
    grade?: string;
    shape?: string;
    classType?: string;
    upcIdentifier?: string;
    status?: string
}

export const useAdminMasterServiceV2 = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();
    const v1BaseUrl = process.env.REACT_APP_API_V1_ADMIN_URL;

    const getAllAttributes = (params: IAttributeFilterRequest) => {
        return request(API_METHOD.GET, ADMIN_MASTER_V2_URLS.GET_ALL_ATTRIBUTES, authenticatedUser, null, { params }, null);
    };

    const getAllBrandsByName = (params: IBrandSearchRequest) => {
        return request(API_METHOD.GET, ADMIN_MASTER_V2_URLS.GET_ALL_BRANDS, authenticatedUser, null, { params }, null);
    };

    const getAllUser = (params: IUserSearchParams) => {
        return request(API_METHOD.GET, ADMIN_MASTER_V2_URLS.GET_ALL_USER, authenticatedUser, null, { params }, null);
    };

    const getAllSecondaryInventories = (data: ICategoryFilterRequest, params: ISecondaryInventoryFilterRequest) => {
        return request(API_METHOD.POST, ADMIN_MASTER_V2_URLS.GET_ALL_SECONDARY_INVENTORY, authenticatedUser, data, { params }, null, v1BaseUrl);
    };

    const getProductUPCs = (params: IProductUPCRequest) => {
        return request(API_METHOD.GET, ADMIN_MASTER_V2_URLS.GET_PRODUCT_UPC, authenticatedUser, null, { params }, null, v1BaseUrl);
    };

    const searchUpc = (data: IProductUpcFilterRequest, params: any) => {
        return request(API_METHOD.POST, ADMIN_MASTER_V2_URLS.SEARCH_UPC, authenticatedUser, data, { params }, null);
    };

    const getProductUpcBrandMapping = (id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(ADMIN_MASTER_V2_URLS.GET_PRODUCT_UPC_BRAND_MAPPING, { id }), authenticatedUser, null, null, null);
    }

    const searchUpcByVendorId = (vendorId: number, data: IProductUpcFilterRequest, params: any) => {
        return request(API_METHOD.POST, replaceUrlParams(ADMIN_MASTER_V2_URLS.SEARCH_UPC_BY_VENDOR_ID, { vendorId }), authenticatedUser, data, { params }, null);
    };

    const getRootAndVendorBrands = (vendorId: number, params: IBrandSearchRequest) => {
        return request(API_METHOD.GET, replaceUrlParams( ADMIN_MASTER_V2_URLS.GET_ROOT_AND_VENDOR_BRANDS, { vendorId } ), authenticatedUser, null, { params }, null);
    };

    const getRootAndVendorManufacturers = (vendorId: number, params: IManufacturerFilterRequest) => {
        return request(API_METHOD.GET, replaceUrlParams( ADMIN_MASTER_V2_URLS.GET_ROOT_AND_VENDOR_MANUFACTURERS, { vendorId } ), authenticatedUser, null, { params }, null);
    };

    return {
        getAllAttributes,
        getAllBrandsByName,
        getAllUser,
        getAllSecondaryInventories,
        getProductUPCs,
        searchUpc,
        getProductUpcBrandMapping,
        searchUpcByVendorId,
        getRootAndVendorBrands,
        getRootAndVendorManufacturers
    };
}