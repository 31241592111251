import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const BUYER_URLS = {
    BUYER_STATS: "/buyer/stats",
    BUYER_ENQUIRY: "/buyer/enquiry",
    GET_BUYER_NOTIFICATIONS: "/buyer/notification",
    UPDATE_BUYER_NOTIFICATIONS: "/buyer/update/notification-details"
}

export interface IEnquiryRequestBody {
	enquiryStatus: string;
}

export interface INotificationDetailsRequestBodyDTO {
    notificationIds: number[];
}

export const useBuyerService = () => {

    const { user } = useAuthenticatedUser();
    const BASE_URL_V1 = process.env.REACT_APP_API_V1_URL;

    const getBuyerStats = () => {
        return request(API_METHOD.GET, BUYER_URLS.BUYER_STATS,  user, null, undefined, null, BASE_URL_V1);
    };

    const getEnquiryDetailsByStatus = (params: IEnquiryRequestBody) => {
        return request(API_METHOD.GET, BUYER_URLS.BUYER_ENQUIRY,  user, null, { params }, null, BASE_URL_V1);
    };

    const getBuyerNotifications = () => {
        return request(API_METHOD.GET, BUYER_URLS.GET_BUYER_NOTIFICATIONS,  user, null, undefined, null, BASE_URL_V1);
    };

    const updateNotificationDetails = (data: INotificationDetailsRequestBodyDTO) => {
        return request(API_METHOD.PUT, BUYER_URLS.UPDATE_BUYER_NOTIFICATIONS,  user, data, null, null, BASE_URL_V1);
    };
    return {
        getBuyerStats,
        getEnquiryDetailsByStatus,
        getBuyerNotifications,
        updateNotificationDetails
    };
}