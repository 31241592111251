import { FormikProps } from 'formik';
import { TextField, FormHelperText, MenuItem } from '@mui/material';
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from '../../atoms/Button/Button';
import { HTTP_STATUS, ONBOARDING_STATES } from '../../../utils/types';
import { GstResponse, IOnBoardingPayload } from '../../pages/OnBoarding/OnBoarding.page';
import { useBusinessService } from '../../../services/useBusinessService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

interface BusinessDetailsTemplateProps {
    formik: FormikProps<IOnBoardingPayload>;
    setFormState: (value: ONBOARDING_STATES) => void;
    gstDetails: GstResponse | null;
};

export interface IBusinessDetails {
    business: {
        dateOfEstablishment: string,
        websiteUrl: string,
        lastAnnualTurnover: string,
        totalEmployees: string
    }
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        rowGap: "40px"
    },
    headingContainer: {
        rowGap: "4px"
    },
    heading: {
        color: theme.palette.text.neutral.neutral800,
        fontSize: "28px",
        lineHeight: "34px"
    },
    description: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "20px"
    },
    formContainer: {
        rowGap: "40px"
    },
    formSubContainer: {
        rowGap: "12px"
    },
    skipButton: {
        width: "290px"
    },
    nextButton:{
        width: "179px"
    },
    "@media (max-width: 767px)": {
        container: {
            rowGap: "10px",
            padding: "0px 10px 10px 10px"
        },
        headingContainer: {
            rowGap: "0px",
            paddingBottom: "4px",
            borderBottom: `1px dashed ${theme.palette.border.neutral.neutral300}`
        },
        heading: {
            color: theme.palette.text.neutral.neutral800,
            fontSize: "22px",
            lineHeight: "26px"
        },
        description: {
            color: theme.palette.text.neutral.neutral700,
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16px"
        },
        formContainer: {
            rowGap: "10px"
        },
        formSubContainer: {
            rowGap: "10px"
        },
        skipButton: {
            width: "auto",
        },
        nextButton:{
            width: "auto",
        },
        
    }
}))

const annualTurnoverOptions = [
    'Less than 40 Lakhs',
    '40 L - 1 CR',
    '1-5 CR',
    '5-10 CR',
    '10-20 CR',
    '20-50 CR',
    '50-100 CR',
    '100+ CR',
];

const BusinessDetailsTemplate: React.FC<BusinessDetailsTemplateProps> = ({ formik, setFormState }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const businessService = useBusinessService();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [loading, setLoading] = useState(false)
    const handleSubmit = async () => {
        formik.setTouched({ dateOfEstablishment: true, lastAnnualTurnover: true, totalEmployees: true }, true)
        const errors = Object.keys(formik.errors).find(key => ['dateOfEstablishment', 'lastAnnualTurnover', 'totalEmployees'].includes(key))
        if (errors) return;
        const { dateOfEstablishment, websiteUrl, lastAnnualTurnover, totalEmployees } = formik.values;
        setLoading(true)
        user && businessService.updateBusiness({ business: { dateOfEstablishment, websiteUrl, lastAnnualTurnover, totalEmployees } }, user.businessId)
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setFormState(ONBOARDING_STATES.ENTITY_TYPE)
                else showSnackbar('error', 'Something went wrong');
            }).catch(error => {
                console.error(error)
                showSnackbar('error', 'Something went wrong')
            }).finally(() => { setLoading(true) });
    }

    const onBack = () => {
        setFormState(ONBOARDING_STATES.GST_INFORMATION)
    }

    const handleSkip = () => {
        navigate('/');
    }

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    return (
        <div className={`${classes.container} grid`}>
            {SnackBarComponent}
            <div className={`grid ${classes.headingContainer}`}>
                <div className={`${classes.heading} font-medium`} >Business Profile</div>
                <div className={`${classes.description} text-base`}>Please verify your business details</div>
            </div>
            <form onSubmit={formik.handleSubmit} className={`grid ${classes.formContainer}`}>
            <div className={`grid ${classes.formSubContainer}`}>
                    <div className="">
                        <TextFieldV2
                            label="Business Website (if Any)"
                            type="text"
                            fullWidth
                            autoComplete="false"
                            error={
                                formik.touched?.websiteUrl && Boolean(formik.errors?.websiteUrl)
                            }
                            {...formik.getFieldProps("websiteUrl")}

                        />
                        {formik.touched?.websiteUrl && formik.errors?.websiteUrl &&
                            <FormHelperText error className='top-half '>{formik.errors?.websiteUrl}</FormHelperText>
                        }
                    </div>
                    <div className="">
                        <TextFieldV2
                            label="Last Annual Turnover (Cr)"
                            type="text"
                            fullWidth
                            select
                            autoComplete="false"
                            error={
                                formik.touched?.lastAnnualTurnover && Boolean(formik.errors?.lastAnnualTurnover)
                            }
                            {...formik.getFieldProps("lastAnnualTurnover")}
                        >
                            {annualTurnoverOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextFieldV2>
                        {formik.touched?.lastAnnualTurnover && formik.errors?.lastAnnualTurnover &&
                            <FormHelperText error className='top-half '>{formik.errors?.lastAnnualTurnover}</FormHelperText>
                        }
                    </div>
                    <div className="">
                        <TextFieldV2
                            label="Number Of Employees"
                            type="number"
                            fullWidth
                            autoComplete="false"
                            error={
                                formik.touched?.totalEmployees && Boolean(formik.errors?.totalEmployees)
                            }
                            {...formik.getFieldProps("totalEmployees")}
                        />
                        {formik.touched?.totalEmployees && formik.errors?.totalEmployees &&
                            <FormHelperText error className='top-half '>{formik.errors?.totalEmployees}</FormHelperText>
                        }
                    </div>
                </div>
                <div className='flex justify-between'>
                    <Button size={isMobile ? "small" : "medium"} variant='tertiaryContained' label={"Back"} className='' onClick={onBack} />
                    <div className='flex justify-end gap-x-3'>
                        <Button size={isMobile ? "small" : "medium"} variant='tertiaryContained' label={"Skip for now"} className={classes.skipButton} onClick={handleSkip} />
                        <Button size={isMobile ? "small" : "medium"} variant="primaryContained" label="Next" type="submit" className={classes.nextButton} onClick={handleSubmit} isLoading={loading} />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default BusinessDetailsTemplate