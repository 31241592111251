import React, { ReactNode } from 'react'
import { createUseStyles } from 'react-jss';
import steelBazaarLogo from "../../../assets/images/steelBazaarLogo.svg"
import tin from "../../../assets/images/onBoardingTin.jpg"
import { ONBOARDING_STATES } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import closeIcon from "../../../assets/icons/closeRoundIcon.svg";


interface OnBoardingInformationTemplateProps {
    children: ReactNode;
    formState: ONBOARDING_STATES;
    setFormState: (value: ONBOARDING_STATES) => void;
};

const useStyles = createUseStyles((theme: any) => ({
    mobileContainer: {
        display: "none"
    },
    webContainer: {
        display: "flex"
    },
    leftContainer: {
        width: "61.88%",
        padding: "24px 70px 0px 70px"
    },
    rightContainer: {
        backgroundImage: `url(${tin})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: "42.22%",
        height: '100vh'
    },
    headingContainer: {
        padding: "267px 35px 0px 35px"
    },
    complete: {
        color: theme.palette.text.neutral.neutral900,
        fontSize: "28px",
        lineHeight: "34px",
        padding: "4px 10px",
        backgroundColor: theme.palette.background.neutral.neutral50
    },
    onboarding: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: "58px"
    },
    description: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: "28px",
    },
    sectionText: {
        color: theme.palette.text.primaryDark
    },
    card: {
        background: theme.palette.background.neutral.neutral100,
        height: "538px",
        borderRadius: "32px"
    },
    dullText: {
        color: theme.palette.text.secondaryLightAccent
    },
    bar: {
        height: "10px"
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`
    },
    "@media (max-width: 767px)": {
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "block",
            "& .MuiDialog-paper": {
                width: "757px !important",
                maxWidth: "757px !important",
                borderRadius: "16px",
                margin: "5px"
            }
        },
        bar: {
            height: "4px"
        },
    }
}))

const OnBoardingInformationTemplate: React.FC<OnBoardingInformationTemplateProps> = ({ children, formState, setFormState }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const getStateIcon = (state: ONBOARDING_STATES, subState?: ONBOARDING_STATES) => {
        let checkStatus = (state === formState || subState === formState)
            ? 'active'
            : (state < formState)
                ? 'completed'
                : 'inactive';

        let backgroundColor;
        switch (checkStatus) {
            case 'completed':
                backgroundColor = '#849AE1';
                break;
            case 'active':
                backgroundColor = '#2743A0';
                break;
            case 'inactive':
                backgroundColor = '#EAEEFA';
                break;
            default:
                backgroundColor = '#EAEEFA';
        }

        return (
            <div className={`${classes.bar} w-full rounded`} style={{ backgroundColor: backgroundColor }} />
        );
    }

    const handleClose = () => {
        navigate("/");
    }

    return (
        <div>
            <div className={`${classes.webContainer} w-full h-full`}>
                <div className={`grid gap-y-10 w-full h-fit ${classes.leftContainer}`} >
                    <img src={steelBazaarLogo} alt="SteelBazaar" />
                    <div className='grid grid-cols-6 gap-x-3 w-full mt-4'>
                        <div>{getStateIcon(ONBOARDING_STATES.GST_INFORMATION)}</div>
                        <div>{getStateIcon(ONBOARDING_STATES.BUSINESS_DETAILS)}</div>
                        <div>{getStateIcon(ONBOARDING_STATES.ENTITY_TYPE)}</div>
                        <div>{getStateIcon(ONBOARDING_STATES.REQUIREMENTS)}</div>
                        <div>{getStateIcon(ONBOARDING_STATES.PRODUCTS)}</div>
                    </div>
                    {children}
                </div>

                <div className={`h-full ${classes.rightContainer}`}>
                    <div className={`${classes.headingContainer} flex flex-col`}>
                        <span className={`${classes.complete} font-normal w-fit`}>Complete</span>
                        <span className={`${classes.onboarding} text-5xl mt-1`}>Onboarding</span>
                        <p className={`${classes.description} text-lg`}>Your Ultimate Destination for Steel Raw Material Excellence</p>
                    </div>
                </div>
            </div>


            <Dialog fullWidth className={classes.mobileContainer} open={true} onClose={handleClose}>
                <div className={`grid w-full py-2.5`} >
                    <div className={`cursor-pointer flex justify-end pr-2.5 z-10`} onClick={handleClose} >
                        <img className='w-[33px] h-[33px]' src={closeIcon} alt="closeIcon" />
                    </div>
                    <div className='grid -mt-[33px] '>
                        <img className='py-2.5 mx-auto w-[171px]' src={steelBazaarLogo} alt="SteelBazaar" />
                        <div className={`${classes.borderTop} grid grid-cols-5 gap-x-3 w-full pt-5 pb-3 px-2.5`}>
                            <div>{getStateIcon(ONBOARDING_STATES.GST_INFORMATION)}</div>
                            <div>{getStateIcon(ONBOARDING_STATES.BUSINESS_DETAILS)}</div>
                            <div>{getStateIcon(ONBOARDING_STATES.ENTITY_TYPE)}</div>
                            <div>{getStateIcon(ONBOARDING_STATES.REQUIREMENTS)}</div>
                            <div>{getStateIcon(ONBOARDING_STATES.PRODUCTS)}</div>
                        </div>
                        {children}
                    </div>
                </div>
            </Dialog >
        </div>
    )
}

export default OnBoardingInformationTemplate