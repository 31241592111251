import React, { useEffect, useState } from 'react'
import StatusCard from '../../atoms/Cards/StatusCard';
import { useBuyerService } from '../../../services/useBuyerService';
import { HTTP_STATUS } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';
import auctions from '../../../assets/icons/auction.svg';
import order from '../../../assets/icons/order.svg'
import enquiry from '../../../assets/icons/enquiry.svg'
import { useNavigate } from 'react-router-dom';
import DisplayImageV2 from '../../molecules/ImageUpload/DisplayImageV2';
import comingSoonLogo from '../../../assets/images/comingSoonLogo.svg';

const BuyerStatusDashboardTemplate: React.FC = () => {

  const buyerService = useBuyerService();
  const navigate = useNavigate(); 

  const { showSnackbar, SnackBarComponent } = useSnackbar();
  const [comingSoon, setComingSoonTo] = useState<boolean>(false);

  const [stats, setStats] = useState({
    orderStatsResponseDTO: {
      ongoing: 0,
      dispatched: 0,
      delivered: 0,
      cancelled: 0,
      confirmed: 0,
      totalCount: 0,
    },
    enquiryStatsResponseDTO: {
      ongoing: 0,
      dispatched: 0,
      cancelled: 0,
      delivered: 0,
      confirmed: 0,
      totalCount: 0
    },
  });

  const handleToggleClick = (name: string, navigate: any) => {
    const sectionMap: { [key: string]: string } = {
        "My Enquiries": "/dashboard/lead",
        "My Orders": "/dashboard/order",
    };
    const sectionPath = sectionMap[name];
    if (sectionPath) {
        navigate(sectionPath);
    }
    else {
      setComingSoonTo(true);
    }
};

  const BuyerStatus = () => {
    buyerService.getBuyerStats()
      .then((statusResponse) => {
        if (statusResponse.status === HTTP_STATUS.OK) {
          setStats({
            orderStatsResponseDTO: statusResponse?.data?.data?.orderStatsResponseDTO,
            enquiryStatsResponseDTO: statusResponse?.data?.data?.enquiryStatsResponseDTO
          })
        }
        else {
          setStats(stats);
        }
      })
      .catch((error) => {
        setStats(stats);
        showSnackbar('error', 'Buyer stats fetch failed')
      });
  }
  useEffect(() => {
    BuyerStatus()
  }, [])
  const customStats = [
    {
      icon: enquiry,
      title: "My Enquiries",
      value: stats?.enquiryStatsResponseDTO?.totalCount ?? 0,
      backgroundColor: '#0A12290D',
      itemspace: 4,
      Details: [
        { label: "Ongoing", count: stats?.enquiryStatsResponseDTO?.ongoing || 0 },
        { label: "Confirmed", count: stats?.enquiryStatsResponseDTO?.confirmed || 0 },
        { label: "Cancelled", count: stats?.enquiryStatsResponseDTO?.delivered || 0 },
      ],
      onclick: () => handleToggleClick("My Enquiries", navigate),
    },
    {
      icon: order,
      title: "My Orders",
      value: stats?.orderStatsResponseDTO?.totalCount ?? 0,
      backgroundColor: '#FEF3CD',
      itemspace: 4,
      Details: [
        { label: "Ongoing", count: stats?.orderStatsResponseDTO?.ongoing || 0 },
        { label: "Dispatched", count: stats?.orderStatsResponseDTO?.dispatched || 0 },
        { label: "Delivered", count: stats?.orderStatsResponseDTO?.delivered || 0 },
      ],
      onclick: () => handleToggleClick("My Orders", navigate),
    },
    {
      icon: auctions,
      title: "My Auctions",
      value: 0,
      backgroundColor: '#FFEFE6',
      itemspace: 4,
      Details: [
        { label: "Upcoming", count: 0 || 0 },
        { label: "Auctions", count: 0 || 0 },
        { label: "Auctions", count: 0 || 0 },
      ],
      onclick: () => handleToggleClick("My Auctions", navigate),
    },
  ];

  return (
    <div>
      <DisplayImageV2
        path={comingSoonLogo}
        setImageDialogOpenTo={setComingSoonTo}
        imageDialogOpen={comingSoon}
        showCloseIcon={true} />
      <StatusCard cards={customStats} />
    </div>
  )
}

export default BuyerStatusDashboardTemplate;