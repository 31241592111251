import React, { useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { CATEGORY_TYPE, HTTP_STATUS } from "../../../utils/types";
import { ATTRIBUTES, CLASSES, PRODUCT_SECTION, SHAPE_TYPES } from "../../../utils/constant";
import { AttributeSpecification, useCategoryService } from "../../../services/useCategoryService";
import { ICatalogueAttributes, IMaterialFilterData } from "./GenericSerachDialog.template";
import { useFormik } from "formik";
import FilterChip from "../../atoms/FilterChip/FilterChip";
import { IMultiSelectOption } from "../../atoms/MultiSelectInput/MultiSelectInput2";
import Button from "../../atoms/Button/Button";
import { IBrand, ICategory, IManufacturer, IManufacturerFilterRequest, Standard, useAdminMasterService } from "../../../services/useAdminMasterService";
import { IBrandSearchRequest, useAdminMasterServiceV2 } from "../../../services/useAdminMasterServiceV2";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";

interface MaterialFilterTemplateProps {
    materialFilters: IMaterialFilterData;
    sectionType: string;
    setFilterData: (materialFilters: IMaterialFilterData) => void;
    onFilterSubmit: () => void;
    handleClose: () => void;
    handleFilterClear: () => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid #EBEFFA`
    },
    filterContainer: {
        borderTop: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.text.primary.primary900,
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary800
    },
    inActiveProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    input: {
        "& .MuiInputBase-root": {
            borderRadius: "8px !important",
        },
    },
}));


const MaterialFilterTemplate: React.FC<MaterialFilterTemplateProps> = ({ materialFilters, sectionType, setFilterData, onFilterSubmit, handleClose, handleFilterClear }) => {
    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const adminMasterService = useAdminMasterService();
    const adminMasterServiceV2 = useAdminMasterServiceV2();
    const [superCategoryData, setSuperCategoryData] = useState<ICategory[] | null>([]);
    const [mainCategoryData, setMainCategoryData] = useState<ICategory[] | null>([]);
    const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);
    const [manufacturerData, setManufacturerData] = useState<IManufacturer[] | null>([]);
    const [brandData, setBrandData] = useState<IBrand[] | null>([]);
    const [standardData, setStandardData] = useState<Standard[] | null>([]);
    const [gradeData, setGradeData] = useState<any[] | null>([]);
    const [attributeValues, setAttributeValues] = useState<AttributeSpecification[]>([]);

    const materialFilterData = {
        superCategoryArray: materialFilters.superCategory ?? [],
        superCategoryValue: {
            label: "",
            value: ""
        },
        mainCategoryArray: materialFilters.mainCategory ?? [],
        mainCategoryValue: {
            label: "",
            value: ""
        },
        productCategoryArray: materialFilters.productCategory ?? [],
        productCategoryValue: {
            label: "",
            value: ""
        },
        manufacturerArray: materialFilters.manufacturer ?? [],
        manufacturerValue: {
            label: "",
            value: ""
        },
        brandArray: materialFilters.brand ?? [],
        brandValue: {
            label: "",
            value: ""
        },
        standardArray: materialFilters.standard ?? [],
        standardValue: {
            label: "",
            value: ""
        },
        gradeArray: materialFilters.grade ?? [],
        gradeValue: {
            label: "",
            value: ""
        },
        shapeArray: materialFilters.shape ?? [],
        shapeValue: {
            label: "",
            value: ""
        },
        classesArray: (sectionType === PRODUCT_SECTION.BRAND_UPC || sectionType === PRODUCT_SECTION.MASTER_UPC) ? [{
            label: "Standard",
            value: "STANDARD"
        }] : [{
            label: "Secondary",
            value: "SECONDARY"
        }],
        classesValue: (sectionType === PRODUCT_SECTION.BRAND_UPC || sectionType === PRODUCT_SECTION.MASTER_UPC) ? {
            label: "Standard",
            value: "STANDARD"
        } : {
            label: "Secondary",
            value: "SECONDARY"
        },
        lengthArray: materialFilters?.attributes?.['Length']?.values?.map((option: any) => (
            {
                'label': option,
                'value': option
            }
        )) ?? [],
        lengthValue: {
            label: "",
            value: ""
        },
        thicknessArray: materialFilters?.attributes?.['Thickness']?.values?.map((option: any) => (
            {
                'label': option,
                'value': option
            }
        )) ?? [],
        thicknessValue: {
            label: "",
            value: ""
        },
        widthArray: materialFilters?.attributes?.['Width']?.values?.map((option: any) => (
            {
                'label': option,
                'value': option
            }
        )) ?? [],
        widthValue: {
            label: "",
            value: ""
        },
    }
    const formik = useFormik<any>({
        initialValues: materialFilterData,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

            const attributes: { [key: string]: ICatalogueAttributes; } = {};
            if (formik.values.lengthArray?.length > 0) {
                attributes.Length = {
                    minValue: null,
                    maxValue: null,
                    values: formik.values.lengthArray.map((length: any) => length.label)
                };
            }

            if (formik.values.widthArray?.length > 0) {
                attributes.Width = {
                    minValue: null,
                    maxValue: null,
                    values: formik.values.widthArray.map((width: any) => width.label)
                };
            }

            if (formik.values.thicknessArray?.length > 0) {
                attributes.Thickness = {
                    minValue: null,
                    maxValue: null,
                    values: formik.values.thicknessArray.map((thickness: any) => thickness.label)
                };
            }
            setFilterData({
                ...materialFilters,
                superCategory: formik.values?.superCategoryArray?.length > 0 ? formik.values.superCategoryArray : [],
                mainCategory: formik.values?.mainCategoryArray?.length > 0 ? formik.values.mainCategoryArray : [],
                productCategory: formik.values?.productCategoryArray?.length > 0 ? formik.values.productCategoryArray : [],
                standard: formik.values?.standardArray?.length > 0 ? formik.values.standardArray : [],
                grade: formik.values?.gradeArray?.length > 0 ? formik.values.gradeArray : [],
                brand: formik.values?.brandArray?.length > 0 ? formik.values.brandArray : [],
                shape: formik.values?.shapeArray?.length > 0 ? formik.values.shapeArray : [],
                classes: formik.values?.classesArray?.length > 0 ? formik.values.classesArray : [],
                manufacturer: formik.values?.manufacturerArray?.length > 0 ? formik.values.manufacturerArray : [],
                attributes: Object.keys(attributes).length === 0 ? null : attributes
            })
        }
    });

    useEffect(() => {
        if (Object.keys(materialFilters).length === 0) {
            formik.resetForm();
            formik.setValues(materialFilterData);
        }
    }, [materialFilters]);

    const loadProductCategory = (inputValue?: string) => {
        adminMasterService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setProductCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadSuperCategory = (inputValue?: string) => {
        adminMasterService.getAllCategories({ level: CATEGORY_TYPE.SUPER_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setSuperCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadMainCategory = (inputValue?: string) => {
        adminMasterService.getAllCategories({ level: CATEGORY_TYPE.MAIN_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setMainCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadManufacturers = (inputValue?: string) => {
        if (user?.businessId) {
            const params: IManufacturerFilterRequest = {page: 0, size: 10, sort: 'createdAt,desc', search: inputValue ?? '' };
            adminMasterServiceV2.getRootAndVendorManufacturers(user?.businessId, params)
                .then(res => {
                    if (res.status === HTTP_STATUS.OK)
                        setManufacturerData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Category: ", error);
                })
        }
    }

    const loadBrands = (inputValue?: string) => {
        if (user?.businessId) {
            const params: IBrandSearchRequest = { page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '' };
            adminMasterServiceV2.getRootAndVendorBrands(user?.businessId, params)
                .then(res => {
                    if (res.status === HTTP_STATUS.OK)
                        setBrandData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Category: ", error);
                })
        }
    }

    const loadStandards = (inputValue?: string) => {
        adminMasterService.getAllStandards({ page: 0, size: 10, sort: 'createdAt,desc', search: inputValue ?? '' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setStandardData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    const loadGrades = (inputValue?: string) => {
        adminMasterService.getAllQualityGrades({ type: "GRADE", page: 0, size: 10, sort: 'createdAt,desc', search: inputValue ?? '' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setGradeData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Category: ", error);
            })
    }

    useEffect(() => {
        loadMainCategory();
        loadSuperCategory();
        loadProductCategory();
        loadManufacturers();
        loadBrands();
        loadStandards();
        loadGrades();
    }, []);

    const createOptions = (data: any[] | null, keyName: string = "name") => {
        return data?.map((item: any) => ({ label: item[keyName], value: item.id })) ?? [];
    };

    const productCategoryOptions = useMemo(() => createOptions(productCategoryData), [productCategoryData]);

    const superCategoryOptions = useMemo(() => createOptions(superCategoryData), [superCategoryData]);

    const mainCategoryOptions = useMemo(() => createOptions(mainCategoryData), [mainCategoryData]);

    const manufacturerOptions = useMemo(() => createOptions(manufacturerData), [manufacturerData]);

    const brandOptions = useMemo(() => createOptions(brandData), [brandData]);

    const standardOptions = useMemo(() => createOptions(standardData), [standardData]);

    const gradeOptions = useMemo(() => createOptions(gradeData), [gradeData]);

    const loadAttributes = async (attributeType: any) => {
        try {
            const params = {
                attributeType: attributeType
            }
            const response = await adminMasterServiceV2.getAllAttributes(params);
            if (response.status === HTTP_STATUS.OK && response?.data?.data?.content) {
                const attributeValuesArray = response.data.data.content;
                setAttributeValues(attributeValuesArray)
                return attributeValuesArray;
            } else {
                return [];
            }
        } catch (error) {
            console.error("Error fetching attribute values: ", error);
            return [];
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            loadAttributes(ATTRIBUTES.SPECIFICATION)
        }
        fetchData();
    }, []);


    const handleSubmit = (event?: React.FormEvent) => {
        event?.preventDefault();
        formik.handleSubmit();
    };

    const updateSelected = (key: string) => (option: IMultiSelectOption[] | null) => {
        if (option) {
            formik.setFieldValue(`${key}Array`, option);
            handleSubmit();
        }
    };

    const handleClearClick = (key: string) => {
        formik.setFieldValue(`${key}Array`, []);
        handleSubmit();
    };


    return (
        <div className={`${classes.container} p-4 grid gap-y-5 rounded-lg m-4`}>
            <div>
                <div className="flex justify-between">
                    <div className={`${classes.filterHeading} text-lg font-medium my-auto`}>Search By UPC Details</div>
                </div>

                <div className="grid gap-y-8 pt-2">
                    <div className="grid gap-y-6">
                        <div className="grid grid-cols-3 gap-x-6 gap-y-6">
                            <div className="grid gap-y-2 h-fit">
                                <FilterChip
                                    options={superCategoryOptions}
                                    label="Super Category"
                                    value={formik.values.superCategoryArray}
                                    onchange={updateSelected('superCategory')}
                                    placeholder="Super Category"
                                    ButtonComponent={Button}
                                    buttonLabel="Clear All"
                                    buttonOnClick={() => handleClearClick('superCategory')}
                                    textFieldPlaceholder='Search...'
                                    setSearchTerm={loadSuperCategory}
                                    optionToShow={1}
                                />
                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FilterChip
                                    options={mainCategoryOptions}
                                    label="Main Category"
                                    value={formik.values.mainCategoryArray}
                                    onchange={updateSelected('mainCategory')}
                                    placeholder="Main Category"
                                    ButtonComponent={Button}
                                    buttonLabel="Clear All"
                                    buttonOnClick={() => handleClearClick('mainCategory')}
                                    textFieldPlaceholder='Search...'
                                    setSearchTerm={loadMainCategory}
                                    optionToShow={1}
                                />

                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FilterChip
                                    options={productCategoryOptions}
                                    label="Product Category"
                                    value={formik.values.productCategoryArray}
                                    onchange={updateSelected('productCategory')}
                                    placeholder="Product Category"
                                    ButtonComponent={Button}
                                    buttonLabel="Clear All"
                                    buttonOnClick={() => handleClearClick('productCategory')}
                                    textFieldPlaceholder='Search...'
                                    setSearchTerm={loadProductCategory}
                                    optionToShow={1}
                                />

                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-x-6 gap-y-6">
                            <div className="grid gap-y-2 h-fit">
                                <FilterChip
                                    options={manufacturerOptions}
                                    label="Manufacturers"
                                    value={formik.values.manufacturerArray}
                                    onchange={updateSelected('manufacturer')}
                                    placeholder="Manufacturers"
                                    ButtonComponent={Button}
                                    buttonLabel="Clear All"
                                    buttonOnClick={() => handleClearClick('manufacturer')}
                                    textFieldPlaceholder='Search...'
                                    setSearchTerm={loadManufacturers}
                                    optionToShow={1}
                                />

                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FilterChip
                                    options={brandOptions}
                                    label="Brands"
                                    value={formik.values.brandArray}
                                    onchange={updateSelected('brand')}
                                    placeholder="Brands"
                                    ButtonComponent={Button}
                                    buttonLabel="Clear All"
                                    buttonOnClick={() => handleClearClick('brand')}
                                    textFieldPlaceholder='Search...'
                                    setSearchTerm={loadBrands}
                                    optionToShow={1}
                                    disabled={sectionType === PRODUCT_SECTION.SECONDARY}
                                />

                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FilterChip
                                    options={standardOptions}
                                    label="Standards"
                                    value={formik.values.standardArray}
                                    onchange={updateSelected('standard')}
                                    placeholder="Standards"
                                    ButtonComponent={Button}
                                    buttonLabel="Clear All"
                                    buttonOnClick={() => handleClearClick('standard')}
                                    textFieldPlaceholder='Search...'
                                    setSearchTerm={loadStandards}
                                    optionToShow={1}
                                    disabled={sectionType === PRODUCT_SECTION.SECONDARY}
                                />

                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FilterChip
                                    options={gradeOptions}
                                    label="Grades"
                                    value={formik.values.gradeArray}
                                    onchange={updateSelected('grade')}
                                    placeholder="Grades"
                                    ButtonComponent={Button}
                                    buttonLabel="Clear All"
                                    buttonOnClick={() => handleClearClick('grade')}
                                    textFieldPlaceholder='Search...'
                                    setSearchTerm={loadGrades}
                                    optionToShow={1}
                                />

                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FilterChip
                                    options={SHAPE_TYPES.map(shape => ({ label: shape.name, value: shape.id }))}
                                    label="Shapes"
                                    value={formik.values.shapeArray}
                                    onchange={updateSelected('shape')}
                                    placeholder="Select Shapes"
                                    ButtonComponent={Button}
                                    buttonLabel="Clear All"
                                    buttonOnClick={() => handleClearClick('shape')}
                                    textFieldPlaceholder='Search...'
                                    setSearchTerm={() => { }}
                                    optionToShow={1}
                                    isSearchable={false}
                                />

                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FilterChip
                                    options={CLASSES.map(classes => ({ label: classes.label, value: classes.id }))}
                                    label="Class"
                                    value={formik.values.classesArray}
                                    onchange={() => { }}
                                    placeholder="Select Class"
                                    ButtonComponent={Button}
                                    buttonLabel="Clear All"
                                    buttonOnClick={() => handleClearClick('classes')}
                                    textFieldPlaceholder='Search...'
                                    setSearchTerm={() => { }}
                                    optionToShow={1}
                                    disabled={true}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-3 gap-x-6 gap-y-6">
                            <div className="grid gap-y-2 h-fit">
                                <FilterChip
                                    options={(Array.isArray(attributeValues) && attributeValues?.length > 0) ? JSON.parse(attributeValues?.find(item => item?.name?.toLowerCase().includes("length") ?? "")?.attributeOptions?.toString() ?? "")
                                        .sort((a: any, b: any) => {
                                            const aStr = a.toString();
                                            const bStr = b.toString();
                                            if (!isNaN(aStr) && !isNaN(bStr)) {
                                                return parseFloat(aStr) - parseFloat(bStr);
                                            }
                                            return aStr.localeCompare(bStr);
                                        })
                                        .map((option: any, index: any) => (
                                            {
                                                'label': option,
                                                'value': option
                                            }
                                        )) : []}
                                    label="Length"
                                    value={formik.values.lengthArray}
                                    onchange={updateSelected('length')}
                                    placeholder="Length"
                                    ButtonComponent={Button}
                                    buttonLabel="Clear All"
                                    buttonOnClick={() => handleClearClick('length')}
                                    textFieldPlaceholder='Search...'
                                    setSearchTerm={() => { }}
                                    isSearchable={false}
                                    maxHeight="30%"
                                />

                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FilterChip
                                    options={(Array.isArray(attributeValues) && attributeValues.length > 0) ? JSON.parse(attributeValues?.find(item => item?.name?.toLowerCase().includes("thickness") ?? "")?.attributeOptions.toString() ?? "")
                                        .map((option: any) => parseFloat(option))
                                        .sort((a: number, b: number) => a - b)
                                        .map((option: any, index: any) => (
                                            {
                                                'label': option,
                                                'value': option
                                            }
                                        )) : []}
                                    label="Thickness"
                                    value={formik.values.thicknessArray}
                                    onchange={updateSelected('thickness')}
                                    placeholder="Thickness"
                                    ButtonComponent={Button}
                                    buttonLabel="Clear All"
                                    buttonOnClick={() => handleClearClick('thickness')}
                                    textFieldPlaceholder='Search...'
                                    searchTerm={formik.values.thicknessValue.value}
                                    setSearchTerm={() => { }}
                                    isSearchable={false}
                                    maxHeight="30%"
                                />


                            </div>
                            <div className="grid gap-y-2 h-fit">
                                <FilterChip
                                    options={(Array.isArray(attributeValues) && attributeValues.length > 0) ? JSON.parse(attributeValues?.find(item => item?.name?.toLowerCase().includes("width") ?? "")?.attributeOptions.toString() ?? "")
                                        .map((option: any) => parseFloat(option))
                                        .sort((a: number, b: number) => a - b)
                                        .map((option: any, index: any) => (
                                            {
                                                'label': option,
                                                'value': option
                                            }
                                        )) : []}
                                    label="Width"
                                    value={formik.values.widthArray}
                                    onchange={updateSelected('width')}
                                    placeholder="Width"
                                    ButtonComponent={Button}
                                    buttonLabel="Clear All"
                                    buttonOnClick={() => handleClearClick('width')}
                                    textFieldPlaceholder='Search...'
                                    searchTerm={formik.values.widthValue.value}
                                    setSearchTerm={() => { }}
                                    isSearchable={false}
                                    maxHeight="30%"
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default MaterialFilterTemplate;

