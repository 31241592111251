import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { Link } from 'react-router-dom';
import { STATIC_PAGES_ROUTES } from '../../../../utils/constant';
import navbarArrowIcon from '../../../../assets/icons/navbarArrowIcon.svg';
import NavbarServices1 from '../../../../assets/images/NavbarServices1.jpg';
import NavbarServices2 from '../../../../assets/images/NavbarServices2.jpg';
import NavbarServices3 from '../../../../assets/images/NavbarServices3.jpg';
import NavbarServices4 from '../../../../assets/images/NavbarServices4.jpg';
import NavbarServices5 from '../../../../assets/images/NavbarServices5.jpg';
import NavbarServices6 from '../../../../assets/images/NavbarServices6.jpg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: "white",
        position: 'absolute',
        width: '100%',
        top: '125px',
        left: '0',
        zIndex: '99',
        boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.2)`,
    },
    primaryContainer: {
        width: '1297px',
        margin: '0 auto',
    },
    dropdownContent: {
        "& h3": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& h4": {
            lineHeight: '22px',
            color: '#000',
            borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`
        },
        "& p": {
            color: '#000',
            position: 'relative',
            paddingLeft: '24px',
            "&::before": {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                left: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '8px',
                height: '12px',
                backgroundImage: `url(${navbarArrowIcon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
        },
    },
    dropdownImgContainer: {
        '& img': {
            width: '100%',
            height: 'auto',
        },
    },
    maxText: {
        color: theme.palette.text.primary.primary500,
    },
    greenText: {
        color: theme.palette.text.success.success500,
    },
}));

const ServiceDropDown: React.FC = () => {
    const classes = useStyles();

    const [hoveredImage, setHoveredImage] = useState<string>(NavbarServices1);
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);

    const imagesMap: Record<string, string> = {
        'NavbarServices1': NavbarServices1,
        'NavbarServices2': NavbarServices2,
        'NavbarServices3': NavbarServices3,
        'NavbarServices4': NavbarServices4,
        'NavbarServices5': NavbarServices5,
        'NavbarServices6': NavbarServices6,
    };
    const handleLinkClick = () => {
        setIsDropdownOpen(false);
      };
    
      if (!isDropdownOpen) return null; 

    return (
        <div className={`${classes.container} py-10`}>
            <div className={`${classes.primaryContainer} flex justify-between items-center`}>
                <div className='flex w-full gap-[15px]'>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Procurement Solutions</h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavbarServices6'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.PROCUREMENT}`} onClick={handleLinkClick}>SB Procure</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavbarServices4'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.PROCUREMENT}/?component=sbmax`} onClick={handleLinkClick}>SB <span className={`${classes.maxText} font-bold`}>MaXX</span> for Enterprises</Link>
                                </p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>iProcure for MSMEs</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>iProcure for SMEs</p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Manufacturing Solutions</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>SB Makers</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'><Link to={`${STATIC_PAGES_ROUTES.SUPPLYCHAIN_FINANCE}/?component=sbmax`} onClick={handleLinkClick}>Supply Chain Finance</Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavbarServices4'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.SUPPLYCHAIN_FINANCE}/?component=sbmax`} onClick={handleLinkClick}>SB InstaFin</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavbarServices4'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.SUPPLYCHAIN_FINANCE}/?component=sales-invoice-discounting`} onClick={handleLinkClick}>Sales Invoice Discounting</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavbarServices4'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.SUPPLYCHAIN_FINANCE}/?component=purchase-invoice-finance`} onClick={handleLinkClick}>Purchase Invoice Finance</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['NavbarServices4'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.SUPPLYCHAIN_FINANCE}/?component=dynamic-discounting`} onClick={handleLinkClick}>Dynamic Discounting</Link>
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Sustainability</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>SB <span className={`${classes.greenText} font-bold`}>Green</span></p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Recycling </p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Re-Processing</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Insights & Analytics</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>SB SteelInteli</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>SB News</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>SB Market Insights</p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Blogs/Case Studies</p>

                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>Digital Marketing</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>SB DigiBrand</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.dropdownImgContainer} rounded-lg w-1/4 `}>
                        {hoveredImage && <img src={hoveredImage} alt="Hovered Image" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceDropDown;
