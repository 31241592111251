import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export const PRODUCT_URLS = {
    SEARCH_PRODUCT: "/search/products",
    SEARCH_PRODUCT_BY_VENDOR_ID: "/search/vendor/:id/products",
};
export interface IProductSearchParams {
    page: number;
    size: number;
    sort?: string;
    sectionType?: string;
    skuCode?: string;
    classType?: string;
}

export interface ICatalogueAttributes {
    minValue: string | null;
    maxValue: string | null;
    values: string[] | number[] | null;
}

export interface IProductFilterRequest {
    productCode?: number[] | string[] | null;
    superCategory?: number[] | null,
    mainCategory?: number[] | null,
    productCategory?: number[] | null,
    manufacturer?: number[] | null,
    brand?: number[] | null,
    standard?: number[] | null,
    grade?: number[] | null,
    shape?: number[] | null,
    classType?: string[] | null,
    customer?: number[] | null,
    status?: string | null,
    attributes?: { [key: string]: ICatalogueAttributes; } | null,
    productIds?: number[] | null,
    isRootVendorProducts?: boolean
}

export const useGenericSearchService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const searchProduct = (params: IProductSearchParams, data: IProductFilterRequest) => {
        return request(API_METHOD.POST, PRODUCT_URLS.SEARCH_PRODUCT, authenticatedUser, data, { params }, null);
    };

    const searchProductByvendorId = (id: number, params: IProductSearchParams, data: IProductFilterRequest) => {
        return request(API_METHOD.POST, replaceUrlParams(PRODUCT_URLS.SEARCH_PRODUCT_BY_VENDOR_ID, { id }), authenticatedUser, data, { params }, null);
    };

    return {
        searchProduct,
        searchProductByvendorId
    };
};
