import React from 'react'
import { useSnackbar } from '../../../hooks/useSnackBar';
import BuyerStatusDashboardTemplate from '../../template/BuyerDashBoard/BuyerStatusDashboard.template';
import CompareBrandsTemplate from '../../template/BuyerDashBoard/CompareBrands.template';
import MyAuctionTemplate from '../../template/BuyerDashBoard/MyAuctions.template';
import LatestNewsTemplate from '../../template/BuyerDashBoard/LatestNews.template';
import MyOrdersTemplate from '../../template/BuyerDashBoard/MyOrders.template';
import MyEnquiryTemplate from '../../template/BuyerDashBoard/MyEnquiry.template';
import MyProductsTemplate from '../../template/BuyerDashBoard/MyProducts.template';
import DashboardHeaderTemplate from '../../template/BuyerDashBoard/DashboardHeader.template';

const BuyerDashBoardPage: React.FC = () => {
  const { showSnackbar, SnackBarComponent } = useSnackbar();

  return (
    <div className='grid gap-6'>
      {SnackBarComponent}
      <DashboardHeaderTemplate />
      <BuyerStatusDashboardTemplate />
      <CompareBrandsTemplate />
      <MyEnquiryTemplate />
      <MyOrdersTemplate />
      <MyProductsTemplate />
      <MyAuctionTemplate />
      <LatestNewsTemplate />
    </div>
  )
}
export default BuyerDashBoardPage;