import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';

const useStyles = createUseStyles((theme: any) => ({
    body: {
        background: "linear-gradient(94deg, #2B479E 0.26%, #1F3474 64.09%, #162450 84.54%, #0F1938 100%)",
        padding: "32px 45px 32px 28px"
    },
    heading: {
        color: "#FFF",
    }
}));

const SelectionDialogHeaderTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.body} rounded-xl`} >
            <div className={`${classes.heading} text-2xl `}>Start your Supplier Journey with steelbazaar, Choose between <span className='font-semibold text-3xl underline'>Seller Central</span> or <span className='font-semibold text-3xl underline'> Vendor Central</span></div>
            <div className={`${classes.heading} text-base font-semibold mt-6`}>Still wants more clarification?</div>
            <div className='mt-3'>
                <Button
                    variant="tertiaryContained"
                    label="Contact Us"
                    size='medium'
                    disabled
                //   onClick={() => { navigate('/dashboard/inventory-management/add'); }}
                />
            </div>
        </div>
    );
}

export default SelectionDialogHeaderTemplate;
