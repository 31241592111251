import React, { useContext, useEffect, useState } from 'react'
import { CART_SECTIONS, HTTP_STATUS } from '../../../utils/types';
import CartTemplate from '../../template/Cart/Cart.template';
import { CartContext } from '../../../contexts/CartContext';
import { ICartRequestBody, useCartService } from '../../../services/useCartService';
import { useLeadService } from '../../../services/useLeadService';
import { useConfigurationService } from '../../../services/useConfigurationService';
import { CART_LIFE_CYCLE, CUSTOMER_ROUTES, PAYMENT_METHODS } from '../../../utils/constant';
import { IProductClassification, IProductSpecification } from '../CatalogueDetail/SecondaryCatalogueDetails.page';
import CartRfqStatus from '../../template/Cart/CartRfqStatus.template';
import { IShearing } from '../../template/Customization/ShearingCustomizationPopup';
import { ISlitting } from '../../template/Customization/SlittingCustomizationPopup';
import { createUseStyles } from 'react-jss';
import Breadcrumbs from '../../atoms/BreadCrumbs/BreadCrumbs';
import { makeRoute } from '../../../utils/helper';

export interface IConfiguration {
    name: string;
    uom?: string | null;
    price: number;
    value: string;
}

export interface IContext {
    [key: string]: { [key: string]: IConfiguration }
}

export interface IProductAttribute {
    id: number;
    name: string;
    attributeType: string;
    uom?: string | null;
    fieldType?: string;
    attributeOptions: number | string | string[];
}

export interface IValueAddedServices {
    shearing?: IShearing[];
    slitting?: ISlitting[];
}

export interface ICartItem {
    id: number;
    catalogueName: string;
    catalogueId: number;
    upcId: number | null;
    warehouseId: number;
    warehouseName: string;
    warehouseAddress: string;
    quantity: number;
    uom: string;
    packagingType: string;
    upcPrice: number;
    attributes: {
        SPECIFICATION: IProductAttribute[];
        CLASSIFICATION: IProductAttribute[];
        GENERALIZATION: IProductAttribute[];
        productSpecification: IProductSpecification[];
        productClassification: IProductClassification[];
    };
    catalogueImages: string;
    cataloguePackagingTypes: string[];
    secondarySkuId: number | null;
    minimumOrderQuantity: number;
    secondaryLotAttributes: any
    upcCode: string | null;
    valueAddedServices: IValueAddedServices | null;
    catalogueType: string;
    paymentType: string;
    skuCode: string;
}

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid",
        padding: "0px 70px 108px 70px"
    },
    "@media (max-width: 767px)": {
        webContainer: {
            padding: "0px 16px"
        },
    }
}));

const CartDetails: React.FC = () => {
    const classes = useStyles();
    const cartContextData = useContext(CartContext);
    const cartService = useCartService();
    const leadService = useLeadService();
    const configurationService = useConfigurationService();
    const [cartItems, setCartItemsTo] = useState<ICartItem[] | []>([]);
    const [configuration, setConfigurationTo] = useState<IContext>({});
    const [rfqStatus, setRfqStatusTo] = useState<boolean | null>(null);
    const [paymentType, selectedPaymentTypeTo] = useState<string>(PAYMENT_METHODS.CASH_PAYMENT);
    const [isLoading, setIsLoadingTo] = useState(true);
    const [currentSection, setCurrentSectionTo] = useState<CART_SECTIONS>(CART_SECTIONS.CART_DETAILS);

    const fetchCart = async () => {
        try {
            setIsLoadingTo(true);
            const cartResponse = await cartService.getCart();
            if (cartResponse?.data?.data?.length) {
                setCartItemsTo(cartResponse.data.data);
                if (cartResponse.data.data?.length >= 1) {
                    selectedPaymentTypeTo(cartResponse.data.data[0]?.paymentType || PAYMENT_METHODS.CASH_PAYMENT)
                }
                setIsLoadingTo(false);
            } else {
                throw new Error("Cart Details fetch failed");
            }
        } catch (error) {
            setCartItemsTo([]);
            setIsLoadingTo(false);
        }
    };

    const fetchConfiguration = async () => {
        try {
            const contextsAsString = "PACKAGING,WAREHOUSE_CHARGES,HANDLING_CHARGES";
            const configurationResponse = await configurationService.getConfiguration(
                contextsAsString
            );
            if (configurationResponse?.data?.data?.length) {
                const configurationData = configurationResponse.data.data;
                const contextData: IContext = {};
                configurationData.forEach((item: any) => {
                    const { context, data } = item;
                    const contextValues: { [key: string]: IConfiguration } = {};
                    data.forEach((contextValue: IConfiguration) => {
                        contextValues[contextValue?.value] = contextValue;
                    });
                    contextData[context] = contextValues;
                });
                setConfigurationTo(contextData);
            } else {
                throw new Error("Configuration fetch failed");
            }
        } catch (error) {
            setConfigurationTo({});
        }
    };

    const UpdateCartItem = async (cartId: number, cartItem: ICartItem) => {
        try {
            const payload: ICartRequestBody = {
                catalogueName: cartItem.catalogueName,
                upcId: cartItem.upcId,
                warehouseId: cartItem.warehouseId,
                quantity: !isNaN(cartItem.quantity) ? cartItem.quantity : 0,
                uom: cartItem.uom,
                packagingType: cartItem.packagingType,
                secondarySkuId: cartItem.secondarySkuId,
                paymentType: cartItem.paymentType
            };
            const cartResponse = await cartService.updateCartItem(cartId, payload);
            if (cartResponse?.status === HTTP_STATUS.OK) {
                return true;
            } else {
                throw new Error("Cart Details fetch failed");
            }
        } catch (error) {
            return false;
        }
    };

    const handleCartItemUpdate = async (cartId: number, key: string, value: string | number) => {
        const cartItemIndex = cartItems.findIndex((cartItem: ICartItem) => cartItem.id === cartId);
        if (cartItemIndex !== -1) {
            const updatedCartItem = { ...cartItems[cartItemIndex], [key]: value };
            try {
                const updateStatus = await UpdateCartItem(cartId, updatedCartItem);
                if (updateStatus) {
                    cartItems[cartItemIndex] = updatedCartItem;
                    setCartItemsTo([...cartItems]);
                }
            } catch (error) {
                return;
            }
        }
    };

    const deleteCartItem = async (cartId: number) => {
        try {
            const cartResponse = await cartService.deleteCartItem(cartId);
            if (cartResponse?.status === HTTP_STATUS.OK) {
                await cartContextData.syncCart(CART_LIFE_CYCLE.REMOVE_PRODUCT);
                return true;
            } else {
                throw new Error("Cart Item delete failed");
            }
        } catch (error) {
            return false;
        }
    };

    const handleDeleteCartItem = async (cartId: number) => {
        try {
            setIsLoadingTo(true);
            const deleteStatus = await deleteCartItem(cartId);
            if (deleteStatus) {
                await fetchCart();
                setIsLoadingTo(false);
            }
        } catch (error) {
            console.error("Error deleting cart item:", error);
        }
    };

    const handleRfqStatusUpdate = (status: boolean | null) => {
        setRfqStatusTo(status);
    };

    const handleRfq = async () => {
        try {
            const rfqResponse = await leadService.requestForQuotation(paymentType);
            if (rfqResponse?.status === HTTP_STATUS.OK) {
                setCartItemsTo([]);
                setRfqStatusTo(true);
                await cartContextData.syncCart(CART_LIFE_CYCLE.REQUEST_QUOTATION);
                setCurrentSectionTo(CART_SECTIONS.CART_DETAILS);
                return;
            }
            setRfqStatusTo(false);
            return;
        } catch (error) {
            setRfqStatusTo(false);
            return;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchConfiguration();
                await fetchCart();
                setIsLoadingTo(false);
            } catch (error) {
                throw new Error("Fetching Details fetch failed");
            }
        };
        fetchData();
    }, []);

    const handleSelectPaymentMode = (term: string) => {
        selectedPaymentTypeTo(term);
    };

    return (
        <div className={`${classes.webContainer} gap-y-6 max-container m-auto`}>
            <Breadcrumbs
                crumbs={[{
                    link: makeRoute(CUSTOMER_ROUTES.CART, { query: { classes: "", catalogueTypes: "" } }),
                    label: "Cart Page",
                }]}
            />
            {!isLoading &&
                cartItems && configuration && (
                    <CartTemplate
                        cartItemDetails={cartItems}
                        handleDeleteCartItem={handleDeleteCartItem}
                        handleCartItemUpdate={handleCartItemUpdate}
                        configuration={configuration}
                        handleRfq={handleRfq}
                        onSelectPaymentTerm={handleSelectPaymentMode}
                        currentSection={currentSection}
                        setCurrentSectionTo={setCurrentSectionTo}
                        paymentMethod={paymentType}
                        setCartItemsTo = {setCartItemsTo}
                    />

                )}
            {rfqStatus !== null && <CartRfqStatus rfqStatus={rfqStatus} onClose={handleRfqStatusUpdate} />}
        </div>
    );
}

export default CartDetails;