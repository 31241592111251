import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import { STATUS } from '../../../utils/constant';
import { useOrderService } from '../../../services/useOrderService';
import { Order, Product } from '../Order/OrderList.template';
import { IPagination } from '../../../utils/types';
import OrderItemTemplate from './OrderItem.template';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    header: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
        padding: "16px 24px"
    },
    mainHeading: {
        color: theme.palette.text.neutral.neutral970
    },
    tabTitle: {
        color: theme.palette.text.neutral.neutral500,
    },
    activeTabTitle: {
        color: theme.palette.text.primary.primary400,
        borderBottom: `2px solid ${theme.palette.text.primary.primary400}`
    },
    subContainer: {
        padding: '16px 24px 24px 24px'
    }
}));

const MyOrdersTemplate: React.FC = () => {

    const classes = useStyles();
    const orderService = useOrderService();
    const navigate = useNavigate(); 
    
    const [orders, setOrders] = useState<Order[]>([]);
    const [activeTabIndex, setActiveTabIndexTo] = useState<number>(0);
    const [lastDate, setLastDateTo] = useState<string | null>(null);
    const [catalogueName, setCatalogueNameTo] = useState("");

    const [pagination, setPaginationTo] = useState<IPagination>({
        size: 10,
        totalRecords: 0,
        page: 0
    });
    const [loading, setLoading] = useState<boolean>(true);

    const orderStatusTabs = [
        {
            label: "All Orders",
            status: ""
        },
        {
            label: "Delivered Order",
            status: STATUS.COMPLETED
        },
        {
            label: "Pending Order",
            status: STATUS.ONGOING
        }
    ];

    const refreshOrders = async () => {
        try {
            setLoading(true);
            const params = {
                page: pagination.page,
                size: pagination.size,
                sort: "createdAt,desc",
                status: orderStatusTabs[activeTabIndex].status,
                date: lastDate,
                catalogueName: catalogueName
            };
            const ordersResponse = await orderService.getAllOrders(params);
            if (ordersResponse?.data?.data?.content?.length) {
                const { content, totalElements } =
                    ordersResponse.data.data;
                setOrders(content);
                setPaginationTo((prevPagination: IPagination) => ({
                    ...prevPagination,
                    totalRecords: totalElements
                }));
                setLoading(false);
            } else {
                throw new Error("Categories fetch failed");
            }
        } catch (error) {
            setOrders([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshOrders();
    }, [pagination.page, pagination.size, activeTabIndex, lastDate, catalogueName]);


    const handleTabChange = (currentTabIndex: number) => () => {
        setActiveTabIndexTo(currentTabIndex);
    }

    return (
        <div className={`rounded-2xl ${classes.container}`}>
            <div className={`flex justify-between ${classes.header}`}>
                <span className={`font-medium ${classes.mainHeading}`}>My Orders</span>
                <Button size='small' variant="secondaryText" label={"View Details"}  onClick={() => navigate("/dashboard/order")}/>
            </div>
            <div className={`${classes.subContainer} flex flex-col`}>
                <div className="flex gap-x-5 border-b-2 w-fit">
                    {orderStatusTabs.map((orderStatus, index) => (
                        <span
                            key={orderStatus.status}
                            onClick={handleTabChange(index)}
                            className={`cursor-pointer ${activeTabIndex === index ? `${classes.activeTabTitle} pb-3` : `${classes.tabTitle}`}`}
                        >
                            {orderStatus.label}
                        </span>
                    ))}
                </div>
                <div className='overflow-x-auto overflow-hidden w-[1040px] pt-4'>
                    <div className='flex gap-x-6 w-max'>
                        {
                            orders.map((order: Order) => (
                                <OrderItemTemplate
                                    key={order.id}
                                    order={order}
                                    title={'Order'}
                                />
                            ))
                        }
                    </div>
                </div>

            </div>
        </div>)
}
export default MyOrdersTemplate;